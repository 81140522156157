import React from 'react';

import { Stack } from '@chakra-ui/react';
import { FormizStep } from '@formiz/core';

import { FieldRadioGroup } from '@/components';
import {
  INVOICE_REQUEST_BENEFICIARIES,
  INVOICE_REQUEST_STEPS,
} from '@/constants/invoiceRequest';
import { useUserContext } from '@/context/user-context';
import { RadioItem } from '@/pages/BookingRequest/_partials/RadioItem';

import { InvoiceRequestHeading } from '../_partials/InvoiceRequestHeading';

export const InvoiceRequestSelectBeneficiary = () => {
  const { user: currentUser } = useUserContext();
  const { firstName, lastName } = currentUser || {};
  return (
    <FormizStep
      name={INVOICE_REQUEST_STEPS.FURTHER_INFORMATION.name}
      order={INVOICE_REQUEST_STEPS.FURTHER_INFORMATION.order}
    >
      <InvoiceRequestHeading
        title="Informations complémentaires"
        helper="Veuillez renseigner les informations ci-dessous afin de vous proposer un devis adapté."
      />
      <FieldRadioGroup
        id="invoiceRequest-beneficiary"
        name="beneficiary"
        required="Vous devez choisir le bénéficiaire de la demande de devis"
        helper="Pour qui est le rendez-vous ?"
        mt={4}
      >
        <Stack>
          <RadioItem
            id={INVOICE_REQUEST_BENEFICIARIES.YOU}
            name={`${lastName} ${firstName}`}
            description="Le rendez-vous est pour vous"
            isForYou
          />
          <RadioItem
            id={INVOICE_REQUEST_BENEFICIARIES.OTHER_PERSON}
            name="Une autre personne"
            description="Le rendez-vous est pour quelqu’un d’autre"
          />
        </Stack>
      </FieldRadioGroup>
    </FormizStep>
  );
};
