import { isMinLength, isNumber, isPattern } from '@formiz/validations';
import dayjs from 'dayjs';

export const isStartTimeBeforeEndTime = ({ startTime, endTime }) => {
  if (!startTime || !endTime) {
    return true;
  }

  const start = dayjs(startTime, 'HH:mm');
  const end = dayjs(endTime, 'HH:mm');

  return start.isBefore(end);
};

export const isTimeBeforeNow = ({ date, time }) => {
  const now = dayjs();
  const dateFormatted = dayjs(date).startOf('day');
  const timeFormatted = dayjs(time, 'HH:mm');
  const datetimeFormatted = dateFormatted
    .add(timeFormatted.hour(), 'hour')
    .add(timeFormatted.minute(), 'minute');

  return now.isBefore(datetimeFormatted);
};

// remove . / + - and spaces from value
export const formatInternationalPhoneNumber = (value) =>
  value?.replace(/(?![0-9])[.\-\s]/g, '');

export const isValidFrenchPhoneNumber = () => (value) => {
  if (!value) {
    return true;
  }
  const formattedValue = formatInternationalPhoneNumber(value);
  return (
    isMinLength(10)(formattedValue) &&
    isNumber()(formattedValue) &&
    ((formattedValue ?? '')[0] === '0' || (formattedValue ?? '')[0] === '+')
  );
};

export const SIRET_PATTERN_VALIDATION = '^[0-9]{14}$';
export const HAS_DIGIT = /\d/;
export const HAS_LOWCASE_CHARACTER = /[a-z]/;
export const HAS_UPPERCASE_CHARACTER = /[A-Z]/;

export const isValidFrenchPostalCode = () => (value) => {
  return isPattern('^[0-9]{5}$')(value);
};

export const isValidFileType = (type) => (value) =>
  !value || value?.type?.indexOf(type) > -1;
