import React from 'react';

import { Box, Heading, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const VerificationMailHeading = ({ title, helper, ...otherProps }) => (
  <Box {...otherProps}>
    <Heading as="h2" size="lg" pt={2}>
      {title}
    </Heading>
    <Text as="h4" size="sm" mt={1}>
      {helper}
    </Text>
  </Box>
);

VerificationMailHeading.propTypes = {
  title: PropTypes.node,
  helper: PropTypes.node,
};

VerificationMailHeading.defaultProps = {
  title: '',
  helper: '',
};
