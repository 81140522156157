import React from 'react';

import { Button, Spacer, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FaPlus } from 'react-icons/fa';

export const FileUploadButtonPlaceHolder = ({
  label,
  selectedFileCount,
  maxFileCount,
  ...props
}) => {
  return (
    <Button
      my="6"
      w="full"
      h="auto"
      d="flex"
      justifyContent="start"
      p="4"
      backgroundColor="gray.50"
      border="1px dashed"
      borderColor="gray.200"
      borderRadius="md"
      transition="0.2s"
      disabled={selectedFileCount === maxFileCount}
      {...props}
    >
      <Button
        as="span"
        leftIcon={<FaPlus />}
        size="sm"
        variant="outline"
        backgroundColor="gray.100"
        border="none"
      >
        {label}
      </Button>
      <Spacer />
      <Text>
        {selectedFileCount} / {maxFileCount}
      </Text>
    </Button>
  );
};

FileUploadButtonPlaceHolder.propTypes = {
  label: PropTypes.string,
  selectedFileCount: PropTypes.number,
  maxFileCount: PropTypes.number,
};

FileUploadButtonPlaceHolder.defaultProps = {
  label: 'Ajouter un Fichier',
  selectedFileCount: 0,
  maxFileCount: 3,
};
