import axios from 'axios';
import { useQueryCache } from 'react-query';

import { useUserContext } from '@/context/user-context';
import { API_URL, USER_TOKEN_STORAGE_KEY } from '@/services/config';

const ignored403ErrorPages = [
  '/',
  '/profile',
  '/login',
  '/password',
  '/booking-request',
  '/invoice-request',
  '/register',
  '/help',
];

axios.interceptors.request.use(
  (config = {}) => {
    const token = localStorage.getItem(USER_TOKEN_STORAGE_KEY);
    const authHeaders = token
      ? { Authorization: `Bearer ${JSON.parse(token)}` }
      : {};

    return {
      baseURL: API_URL,
      ...config,
      headers: {
        ...authHeaders,
        ...(config.headers || {}),
      },
    };
  },
  (error) => Promise.reject(error)
);
let didMount = false;

export const useNotValidateAccountDitection = ({
  setIsModalValidateAccount,
}) => {
  const { updateToken, updateUser } = useUserContext();
  const queryCache = useQueryCache();

  if (didMount === false) {
    didMount = true;
    axios.interceptors.response.use(
      (response) => response?.data,
      (error) => {
        const { status } = error?.response || {};
        if (status === 401 && error?.config?.url !== '/change_password') {
          updateToken(null);
          updateUser(null);
          queryCache.clear();
        }

        if (
          status === 403 &&
          !ignored403ErrorPages.includes(window?.location?.pathname)
        ) {
          setIsModalValidateAccount(true);
        }

        return Promise.reject(error);
      }
    );
  }
};
