import React, { useEffect } from 'react';

import { Button, Flex, useTheme } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { HeaderMenu, Link } from '@/components';

export const MobileMenu = ({ onCloseMobileMenu }) => {
  const theme = useTheme();
  const location = useLocation();
  const isHomePage = location?.pathname === '/';
  const activeHeaderColor = isHomePage ? 'brand' : 'brandSecondary';

  useEffect(() => {
    document.getElementById('tries').focus();
  }, []);

  const handleOnblur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      onCloseMobileMenu();
    }
  };

  return (
    <HeaderMenu>
      <Flex
        flexDirection="column"
        pb={4}
        boxShadow="md"
        px={10}
        w="100%"
        justifyContent="flex-end"
        alignItems="center"
        bg="white"
        fontFamily={theme.fonts.heading}
        tabIndex="0"
        id="tries"
        onBlur={handleOnblur}
        outline="none"
      >
        {/* <Button
          as={Link}
          variant="link"
          to="/help"
          w="100%"
          py={3}
          colorScheme={activeHeaderColor}
          _hover={{
            textDecoration: 'none',
          }}
          _active={{
            boxShadow: 'none',
          }}
          mr={0}
        >
          Besoin d&apos;aide ?
        </Button> */}
        <Button
          as={Link}
          to="/login"
          w="100%"
          _hover={{
            textDecoration: 'none',
          }}
          colorScheme={activeHeaderColor}
          mt={4}
        >
          Se connecter
        </Button>
      </Flex>
    </HeaderMenu>
  );
};

MobileMenu.propTypes = {
  onCloseMobileMenu: PropTypes.func,
};

MobileMenu.defaultProps = {
  onCloseMobileMenu: null,
};
