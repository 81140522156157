import React, { useRef, useState } from 'react';

import { Button, Center, Stack, VStack } from '@chakra-ui/react';
import Axios from 'axios';
import PropTypes from 'prop-types';
import { useInfiniteQuery } from 'react-query';

import { EmptyState } from '@/pages/_partials/EmptyState';

import { AppointmentCard } from './AppointmentCard';

export const AppointmentsList = ({
  isHistory,
  emptyStateMessage,
  emptyStateHelper,
}) => {
  const [isLastPage, setIsLastPage] = useState(false);
  const refPageCount = useRef(1);

  const fetchAppointments = (key, _, pageId = 0) =>
    Axios.get('/appointment', {
      params: {
        isHistory,
        pageId,
      },
    });

  const getFetchMore = (items) => {
    if (items?.length === 0) {
      setIsLastPage(true);
      return false;
    }
    setIsLastPage(items?.length > 0);
    return items?.length > 0;
  };

  const {
    data,
    isFetching,
    canFetchMore,
    isLoading,
    fetchMore,
  } = useInfiniteQuery(['appointments', isHistory], fetchAppointments, {
    getFetchMore,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const canFetchMoreData = () =>
    typeof canFetchMore === 'undefined' || !!canFetchMore;

  const fetchMoreAppointmentsPages = async () => {
    if (canFetchMoreData() && !isFetching) {
      await fetchMore(refPageCount.current);
      refPageCount.current += 1;
    }
  };

  const appointments = data?.flat();

  return (
    <>
      {!appointments?.length && !isLoading && (
        <EmptyState title={emptyStateMessage} helper={emptyStateHelper} />
      )}
      {!!appointments?.length && !isLoading && (
        <VStack flexGrow="1" p="5" px="2" spacing={2}>
          {(appointments || []).map((appointment) => (
            <AppointmentCard key={appointment.id} appointment={appointment} />
          ))}
        </VStack>
      )}
      {!isLastPage && (
        <Center>
          <Stack direction="row" spacing={4}>
            <Button
              isLoading={isFetching}
              loadingText="Chargement..."
              colorScheme="teal"
              variant="outline"
              onClick={fetchMoreAppointmentsPages}
              disabled={isLastPage || isFetching}
            >
              Chargez plus de rendez-vous
            </Button>
          </Stack>
        </Center>
      )}
    </>
  );
};

AppointmentsList.propTypes = {
  emptyStateMessage: PropTypes.string,
  emptyStateHelper: PropTypes.string,
  isHistory: PropTypes.number,
};

AppointmentsList.defaultProps = {
  emptyStateMessage: '',
  emptyStateHelper: '',
  isHistory: 0,
};
