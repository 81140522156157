import React from 'react';

import { Box, Button, Center, Flex, IconButton } from '@chakra-ui/react';
import { PropTypes } from 'prop-types';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import {
  Footer,
  Header,
  HeaderRightAction,
  HeaderTitle,
  Icon,
} from '@/components';
import { Page } from '@/layout';

import { InvoiceRequestHeading } from './InvoiceRequestHeading';

export const InvoiceRequestSent = ({ bookingId, isActivationNeeded }) => {
  const helperOfInvoiceRequestHeadingForNonActiveAccount =
    'Merci de consulter votre messagerie pour valider votre compte et continuer votre prise de rendez-vous';
  const helperOfInvoiceRequestHeading =
    'Elle sera traitée sous 48 heures environ. Retrouvez toutes les informations dans la liste de vos demandes.';
  return (
    <Page d="flex" flexGrow={1} bg="gray.100">
      <Header position="fixed" top="0" left="0" right="0">
        <HeaderTitle>Demander un devis</HeaderTitle>
        <Box position="relative" w="100%" maxWidth="45em" h="100%">
          <HeaderRightAction right={0}>
            <IconButton
              as={Link}
              to="/"
              colorScheme="gray"
              color="gray.400"
              variant="ghost"
              icon={<FaTimes />}
            />
          </HeaderRightAction>
        </Box>
      </Header>

      <Center flexDirection="column" justifyContent="flex-start" flex={1}>
        <Icon
          icon={FaCheckCircle}
          color="brandSecondary.400"
          fontSize="5em"
          mt={10}
        />
        <InvoiceRequestHeading
          title="Demande envoyée"
          helper={
            isActivationNeeded
              ? helperOfInvoiceRequestHeadingForNonActiveAccount
              : helperOfInvoiceRequestHeading
          }
          textAlign="center"
          mt={6}
        />
      </Center>

      <Footer h={16}>
        <Flex
          justifyContent="flex-end"
          flexGrow={1}
          marginX="auto"
          width={{ base: '100%', md: 'auto' }}
        >
          <Button
            as={Link}
            to={`/bookings/${bookingId}`}
            colorScheme="brandSecondary"
          >
            Voir ma demande
          </Button>
        </Flex>
      </Footer>
    </Page>
  );
};
InvoiceRequestSent.propTypes = {
  bookingId: PropTypes.string,
  isActivationNeeded: PropTypes.bool,
};

InvoiceRequestSent.defaultProps = {
  bookingId: '',
  isActivationNeeded: false,
};
