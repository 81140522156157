import React from 'react';

import { Box, Center, Heading, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const LandingTitle = ({
  number,
  title,
  image,
  circleTopPosition,
  titleTopPosition,
  ...otherProps
}) => (
  <Box position="relative" justifySelf="center" {...otherProps}>
    <Box as="img" src={image} alt="" />
    <Box
      position="absolute"
      display="inline-block"
      width="40px"
      top="0"
      left="0"
    >
      <Center position="relative" top={circleTopPosition || '72px'}>
        <Text
          zIndex={1}
          fontFamily="Roboto Slab"
          fontWeight="700"
          color="white"
        >
          {number}
        </Text>
        <Box as="img" position="absolute" src="/images/circle.svg" alt="" />
      </Center>
    </Box>
    <Heading
      position="absolute"
      top={titleTopPosition || 16}
      left="14"
      as="h3"
      size="sm"
    >
      {title}
    </Heading>
  </Box>
);

LandingTitle.propTypes = {
  number: PropTypes.node,
  circleTopPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  titleTopPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.node,
  image: PropTypes.string,
};
LandingTitle.defaultProps = {
  number: 1,
  circleTopPosition: null,
  titleTopPosition: null,
  title: '',
  image: '',
};
