import { colors } from '@/theme/foundations/colors';

/* eslint-disable consistent-return */
/* eslint-disable default-case */

export const Badge = {
  variants: {
    solid: ({ colorScheme }) => {
      switch (colorScheme) {
        case 'brand':
          return {
            bg: `${colorScheme}.700`,
          };
        case 'brandSecondary':
          return {
            bg: `${colorScheme}.600`,
          };
      }
    },
    subtle: ({ colorScheme }) => {
      switch (colorScheme) {
        case 'brand':
          return {
            color: `${colorScheme}.800`,
          };
        case 'brandSecondary':
          return {
            color: `${colorScheme}.700`,
          };
      }
    },
    outline: ({ colorScheme }) => {
      switch (colorScheme) {
        case 'brand':
          return {
            color: `${colorScheme}.800`,
            boxShadow: `inset 0 0 0 1px ${colors.brand[800]}`,
          };
        case 'brandSecondary':
          return {
            color: `${colorScheme}.700`,
            boxShadow: `inset 0 0 0 1px ${colors.brandSecondary[700]}`,
          };
      }
    },
  },
};
