import React from 'react';

import { Flex } from '@chakra-ui/react';

export const Footer = (props) => (
  <Flex
    bg="white"
    py="3"
    px="10"
    position="fixed"
    left="0"
    bottom="0"
    right="0"
    boxShadow="reversed-md"
    width="100%"
    zIndex={1}
    {...props}
  />
);
