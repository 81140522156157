import React from 'react';

import { Box } from '@chakra-ui/react';

export const Personal = (props) => (
  <Box {...props}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M0.00582482 9.51753C0.0453991 9.00242 0.28319 8.53371 0.675323 8.1977L2.57824 6.5666V1.93168C2.57824 1.57573 2.86682 1.28719 3.22273 1.28719H5.80077C6.15673 1.28719 6.43667 1.57577 6.43667 1.93168V3.25203L9.69848 0.47216C10.4277 -0.152564 11.4861 -0.152478 12.213 0.470312L21.3242 8.19761C22.13 8.88803 22.2345 10.1064 21.5333 10.925C20.9988 11.5465 20.1427 11.7447 19.4128 11.4882V21.3531C19.4128 21.7091 19.1328 21.9976 18.7769 21.9976H3.22277C2.86682 21.9976 2.57828 21.709 2.57828 21.3531V11.4883C1.82487 11.7547 0.986761 11.5314 0.465635 10.9241C0.129619 10.5319 -0.0337065 10.0323 0.00582482 9.51753ZM5.15628 2.57617H3.85867V5.4617L5.15628 4.35684V2.57617ZM8.37022 20.7086H13.6208V15.5439H8.37022V20.7086ZM3.85867 20.7086H7.0898V14.9081C7.0898 14.5521 7.37837 14.2636 7.73428 14.2636H14.2653C14.6213 14.2636 14.9012 14.5521 14.9012 14.9081V20.7086H18.1324V10.5612L10.959 4.47621L3.85863 10.5551V20.7086H3.85867ZM1.44421 10.085C1.67271 10.3513 2.07894 10.3904 2.35334 10.1551L10.5374 3.13984C10.7779 2.93371 11.1323 2.93286 11.3738 3.13769C11.5482 3.28559 19.6118 10.1258 19.6459 10.1548C19.9159 10.3857 20.3241 10.3542 20.5552 10.0854C20.7866 9.8153 20.7553 9.40757 20.4878 9.17829L11.3765 1.45095C11.1333 1.24263 10.7803 1.24259 10.5373 1.45086L1.51412 9.17644C1.24436 9.40761 1.21312 9.81539 1.44421 10.085Z"
          fill="#054952"
        />
        <path
          d="M9.02345 7.73175H12.9764C13.3324 7.73175 13.6123 8.02033 13.6123 8.37624V12.2433C13.6123 12.5992 13.3323 12.8877 12.9764 12.8877H9.02345C8.66749 12.8877 8.37896 12.5992 8.37896 12.2433V8.37624C8.37891 8.02029 8.66749 7.73175 9.02345 7.73175ZM9.65934 11.5988H12.3319V9.02077H9.65934V11.5988Z"
          fill="#054952"
        />
        <path
          d="M10.3125 18.13C9.9566 18.13 9.66805 17.8414 9.66805 17.4855C9.66805 17.1296 9.9566 16.841 10.3125 16.841C10.6685 16.841 10.957 17.1296 10.957 17.4855C10.957 17.8414 10.6685 18.13 10.3125 18.13Z"
          fill="#054952"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="matrix(-1 0 0 1 22 0)"
          />
        </clipPath>
      </defs>
    </svg>
  </Box>
);
