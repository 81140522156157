import { useEffect, useRef, useState } from 'react';

import Axios from 'axios';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { v4 as uuid } from 'uuid';

import {
  BOOKING_REQUEST_CATEGORY,
  BOOKING_REQUEST_DATE_TIME,
} from '@/constants/bookingRequest';
import { INPUT_TIME_FORMAT } from '@/constants/date';
import { BOOKING_REQUEST_STORAGE_KEY } from '@/constants/invoiceRequest';

import { useLocalStorage } from './localStorage';

const getStartDateAndEndDateFromDateAndTime = ({
  date,
  startTime,
  endTime,
}) => {
  const dateAppointment = dayjs(date);
  let startDate =
    (startTime &&
      dayjs(startTime, INPUT_TIME_FORMAT)
        .year(dateAppointment.year())
        .month(dateAppointment.month())
        .date(dateAppointment.date())) ||
    null;
  if (startDate !== null) startDate = startDate.format('YYYY-MM-DDTHH:mm:ss');
  let endDate =
    (endTime &&
      dayjs(endTime, INPUT_TIME_FORMAT)
        .year(dateAppointment.year())
        .month(dateAppointment.month())
        .date(dateAppointment.date())) ||
    null;
  if (endDate !== null) endDate = endDate.format('YYYY-MM-DDTHH:mm:ss');
  return { startDate, endDate };
};

const convertToAppointmentTypeProposalDates = {
  [BOOKING_REQUEST_DATE_TIME.FIXED_DATE.id]: 'one',
  [BOOKING_REQUEST_DATE_TIME.FREE_DATE.id]: 'alternatives',
  [BOOKING_REQUEST_DATE_TIME.MULTI_DATES.id]: 'multiple',
  [BOOKING_REQUEST_DATE_TIME.RECURRING_DATE.id]: 'recuring',
};

export const convertToSearchDto = (params) => {
  const { appointment } = params || {};

  const {
    datetime,
    subject,
    location,
    health,
    justice,
    meeting,
    category,
    event,
  } = appointment || {};

  const { category: justiceCategory, professionalType, serviceIndication } =
    justice || {};

  const {
    category: meetingCategory,
    type: meetingType,
    participants: meetingParticipants,
    interlocutorFullName,
  } = meeting || {};

  const {
    category: eventCategory,
    type: eventType,
    participants: eventParticipants,
    additionalInformation: concreateEventType,
  } = event || {};

  const { type: healthType, specialist } = health || {};
  const { dates } = datetime || {};
  const { city, streetAddress, zipCode, apiData } = location || {};
  let formatedDates = [];
  const {
    appointmentDateTimeType,
    recurringCount,
    recurringTimeUnit,
    meetingCount,
  } = datetime || {};
  formatedDates = dates?.map((meetingDate) =>
    meetingDate
      ? getStartDateAndEndDateFromDateAndTime(meetingDate)
      : meetingDate
  );

  let data = {
    appointmentProposalDates: formatedDates,
    appointmentTypeProposalDates:
      convertToAppointmentTypeProposalDates[appointmentDateTimeType],
    subject,
    city,
    streetAddress,
    zipCode,
    name: zipCode,
    isProfessional: category === BOOKING_REQUEST_CATEGORY.PROFESSIONAL.id,
    apiData,
  };

  if (event) {
    data = {
      event: {
        eventCategory,
        concreateEventType,
        eventType,
        eventParticipants: eventParticipants?.value,
      },
      ...data,
    };
  }

  if (appointmentDateTimeType === BOOKING_REQUEST_DATE_TIME.RECURRING_DATE.id) {
    data = {
      meetingFrequency: {
        recurringCount,
        recurringTimeUnit,
        meetingCount,
      },
      ...data,
    };
  }

  if (health) {
    data = {
      health: {
        type: healthType,
        specialist,
      },
      ...data,
    };
  }

  if (meeting) {
    data = {
      meeting: {
        meetingCategory,
        meetingType,
        meetingParticipants: meetingParticipants?.value,
        interlocutorFullName,
      },
      ...data,
    };
  }

  if (justice) {
    data = {
      justice: {
        justiceCategory,
        professionalType,
        serviceIndication,
      },
      ...data,
    };
  }
  return data;
};

export const useSearchByAddress = ({ params, ...config } = {}) =>
  useQuery(
    [`invoiceRequests/address/`, params?.search],
    () =>
      Axios.get('/route/autocomplete', {
        params: {
          text: params?.search || '',
        },
      }),
    {
      retry: 0,
      ...config,
      refetchOnWindowFocus: false,
    }
  );

export const useDateTimeRepeater = ({ dateCount, dateType }) => {
  const dateFormRef = useRef([]);
  const [bookingRequest] = useLocalStorage(BOOKING_REQUEST_STORAGE_KEY);
  const meetingDateLength =
    bookingRequest?.appointment?.datetime?.appointmentDateTimeType === dateType
      ? bookingRequest?.appointment?.datetime?.dates?.length
      : 1;
  const [dateCollection, setDateCollection] = useState(
    Array(meetingDateLength || 1)
      .fill(0)
      .map(() => ({
        id: uuid(),
      }))
  );

  useEffect(() => {
    dateFormRef.current = new Array(dateCount);
  }, [dateCount]);

  useEffect(() => {
    if (
      dateCollection.length !== 0 &&
      dateFormRef.current[dateCollection.length - 1]
    ) {
      dateFormRef.current[dateCollection.length - 1].scrollIntoView(false);
    }
  }, [dateCollection]);

  const addDate = () => {
    setDateCollection((collection) => [...collection, { id: uuid() }]);
  };

  const deleteSelectedDate = (id) => {
    setDateCollection((c) => c.filter((x) => x.id !== id));
  };

  return {
    dateCollection,
    dateFormRef,
    deleteSelectedDate,
    addDate,
  };
};
