import React from 'react';

import { Button, Flex } from '@chakra-ui/react';
import { useForm } from '@formiz/core';
import PropTypes, { string } from 'prop-types';
import { Link } from 'react-router-dom';

import { Footer } from '@/components';

export const BookingInvoiceRequestAcceptFooter = ({
  isLoading,
  price,
  ...otherProps
}) => {
  const form = useForm();
  return (
    <Footer h={{ base: form?.isLastStep ? 24 : 16, md: 16 }} {...otherProps}>
      <Flex
        justifyContent="flex-end"
        flexGrow={1}
        marginX="auto"
        maxWidth="45em"
        flexDir={{ base: 'column', md: 'row' }}
      >
        {form?.isLastStep ? (
          <>
            <Button
              as={Link}
              to="/bookings"
              colorScheme="brandSecondary"
              variant="link"
            >
              Retour à la liste des devis
            </Button>
            <Button
              type="submit"
              colorScheme="brandSecondary"
              isLoading={isLoading}
              isDisabled={
                (form.isStepSubmitted && !form.currentStep.isValid) || isLoading
              }
              ml={{ base: 0, md: 2 }}
              mt={{ base: 2, md: 0 }}
            >
              Accepter le devis {price && `${price} € TTC`}
            </Button>
          </>
        ) : (
          <Button
            type="submit"
            colorScheme="brandSecondary"
            isDisabled={form.isStepSubmitted && !form.currentStep.isValid}
          >
            Valider
          </Button>
        )}
      </Flex>
    </Footer>
  );
};

BookingInvoiceRequestAcceptFooter.propTypes = {
  isLoading: PropTypes.bool,
  price: string,
};

BookingInvoiceRequestAcceptFooter.defaultProps = {
  isLoading: false,
  price: '',
};
