// API
// The date formats allocated by the APIs

// INPUT
// The date format allocated by the inputs

export const INPUT_TIME_FORMAT = 'HH:mm';

// DISPLAY
// The date format allocated for display

export const DATE_FORMAT_WITHOUT_YEAR = 'dddd D MMMM';
export const DATE_FORMAT_WITH_YEAR = 'dddd D MMMM YYYY';
export const TIME_FORMAT = 'HH[h]mm';

export const DATE_SELECTION_TAGS = [
  'Première date',
  'Deuxième date',
  'Troisième date',
];

export const RECURRENT_DATE_UNIT = [
  {
    value: 'semaine',
    label: 'Semaines',
  },
  {
    value: 'mois',
    label: 'Mois',
  },
];
