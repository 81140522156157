import React from 'react';

import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex, Spacer } from '@chakra-ui/react';
import { FormizStep, useForm } from '@formiz/core';
import { isNotEmptyString } from '@formiz/validations';

import { FieldInput, FieldSelect } from '@/components/Fields';
import {
  BOOKING_REQUEST_CATEGORY,
  BOOKING_REQUEST_SUBJECTS,
  EVENT_HELPER_TYPE,
} from '@/constants/bookingRequest';
import { MEETING_PARTICIPANTS_CAPACITY } from '@/constants/meeting';
import { FORM_STEPS } from '@/constants/steps';
import { AppointmentHeading } from '@/pages/BookingRequest/_partials/AppointmentHeading';
import { HelpIconButton } from '@/pages/BookingRequest/_partials/HelpIconButton';

export const Event = () => {
  const form = useForm();
  const [isLessThan360] = useMediaQuery('(max-width: 360px)');
  const [isLessThan420] = useMediaQuery('(max-width: 420px)');
  const { values } = form;
  const { appointment } = values || { category: null, health: null };

  const eventCapacityOptions = Object.values(MEETING_PARTICIPANTS_CAPACITY).map(
    (value) => ({
      value,
      label: value,
    })
  );

  const getHeightDependingOnMediaWidth = () =>
    isLessThan360 ? '11rem' : '7.5rem';
  return (
    <FormizStep
      name={FORM_STEPS.EVENT_SUBJECT.TYPE.name}
      order={FORM_STEPS.EVENT_SUBJECT.TYPE.order}
    >
      <Flex>
        <AppointmentHeading
          title="Type d’événement"
          helper="De quoi s’agit-il ?"
        />
        <Spacer />
        <HelpIconButton />
      </Flex>
      <FieldInput
        name={`appointment.${BOOKING_REQUEST_SUBJECTS.EVENT.id}.type`}
        label={EVENT_HELPER_TYPE[appointment?.category]?.type?.label}
        helper={EVENT_HELPER_TYPE[appointment?.category]?.type?.helper}
        required="Vous devez fournir le type de l'événement"
        validations={[
          {
            rule: isNotEmptyString(),
            message: "Vous devez fournir le type de l'événement",
          },
        ]}
        h={isLessThan420 ? '8rem' : null}
        mt={4}
      />
      {BOOKING_REQUEST_CATEGORY.PROFESSIONAL.id === appointment?.category && (
        <>
          <FieldInput
            name={`appointment.${BOOKING_REQUEST_SUBJECTS.EVENT.id}.additionalInformation`}
            label="Informations complémentaires"
            helper={
              EVENT_HELPER_TYPE[appointment?.category]?.additionalInfo?.helper
            }
            required="Vous devez fournir les précisions concernant le type de l’événement"
            h={isLessThan420 ? getHeightDependingOnMediaWidth() : null}
            validations={[
              {
                rule: isNotEmptyString(),
                message:
                  'Vous devez fournir les précisions concernant le type de l’événement',
              },
            ]}
            mt={4}
          />
          <FieldSelect
            name={`appointment.${BOOKING_REQUEST_SUBJECTS.EVENT.id}.participants`}
            label="Nombre de participants"
            helper="Combien y’a-t-il de personnes ?"
            required="Vous devez choisir le nombre de participants"
            h={isLessThan360 ? '10rem' : null}
            options={eventCapacityOptions}
            mt={4}
          />
        </>
      )}
    </FormizStep>
  );
};
