import React from 'react';

import {
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FaInfoCircle } from 'react-icons/fa';

import { Icon } from '@/components/Icon';

export const FormGroup = ({
  children,
  errorMessage,
  helper,
  htmlFor,
  isRequired,
  label,
  showError,
  ...props
}) => {
  return (
    <FormControl isInvalid={showError} {...props}>
      {!!label && (
        <Flex align="baseline">
          <FormLabel
            htmlFor={htmlFor}
            transition="0.2s"
            mb={helper ? 0 : 1}
            mr={2}
            width="100%"
          >
            {label}
          </FormLabel>
        </Flex>
      )}
      {!!helper && (
        <Text color="gray.400" mt="0" mb="2">
          {typeof helper === 'string' ? (
            <Text as="span" fontSize="sm">
              <Icon icon={FaInfoCircle} mr="1" />
              {helper}
            </Text>
          ) : (
            helper
          )}
        </Text>
      )}
      {children}
      <FormErrorMessage id={`${htmlFor ? `${htmlFor}-` : ''}error`} mt="1">
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  );
};

FormGroup.propTypes = {
  children: PropTypes.node,
  errorMessage: PropTypes.node,
  helper: PropTypes.node,
  htmlFor: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.node,
  showError: PropTypes.bool,
};

FormGroup.defaultProps = {
  children: '',
  errorMessage: '',
  helper: '',
  htmlFor: '',
  isRequired: false,
  label: '',
  showError: false,
};
