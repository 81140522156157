import React from 'react';

import { Route, Redirect, useLocation } from 'react-router-dom';

import { useUserContext } from '@/context/user-context';

export const Authenticated = (props) => {
  const { isLogged } = useUserContext();
  const { pathname } = useLocation();
  if (isLogged) {
    return <Route {...props} />;
  }

  return (
    <Redirect
      to={{
        pathname: '/login',
        search: pathname ? `?redirect=${pathname}` : null,
      }}
    />
  );
};
