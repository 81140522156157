import React from 'react';

import { Button, useTheme } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

export const FooterMenuItem = ({ to, ...props }) => {
  const theme = useTheme();

  return (
    <Button
      as={NavLink}
      to={to}
      d="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      variant="ghost"
      borderRadius="0"
      h="100%"
      w="100%"
      transition="0.2s ease"
      color="gray.500"
      activeStyle={{
        color: theme.colors.brandSecondary[600],
      }}
      _hover={{ color: 'brandSecondary.600' }}
      {...props}
    />
  );
};

FooterMenuItem.propTypes = {
  to: PropTypes.string,
};

FooterMenuItem.defaultProps = {
  to: '',
};
