import React from 'react';

import { Flex, Text, WrapItem, Center } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { StepperNumber } from '@/components/StepperNumber';

export const StepperItem = ({
  isActive,
  isAfterTheCurrentStep,
  children,
  index,
  colorScheme,
  isDisabled,
  fontSize,
  ...otherProps
}) => {
  const colorIfNotCurrentStep = isAfterTheCurrentStep
    ? `${colorScheme}.100`
    : 'transparent';
  const colorIfDisabled = isDisabled ? `${colorScheme}.100` : 'white';
  const isActiveOrAfterTheCurrentStep = isActive || isAfterTheCurrentStep;
  return (
    <WrapItem
      justify="center"
      alignItems="center"
      isDisabled={isDisabled}
      {...otherProps}
    >
      <Center>
        <Flex alignItems="center">
          <StepperNumber
            bg={isActive ? 'white' : colorIfNotCurrentStep}
            color={
              isActiveOrAfterTheCurrentStep
                ? `${colorScheme}.600`
                : colorIfDisabled
            }
            {...(isActiveOrAfterTheCurrentStep
              ? { borderColor: colorIfDisabled, borderWidth: 0 }
              : { borderWidth: 1 })}
          >
            {index + 1}
          </StepperNumber>
          {isActive && (
            <Text
              color="white"
              fontSize={fontSize ?? 'lg'}
              fontWeight="700"
              ml="2"
            >
              {children}
            </Text>
          )}
        </Flex>
      </Center>
    </WrapItem>
  );
};

StepperItem.propTypes = {
  isActive: PropTypes.bool,
  isAfterTheCurrentStep: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([
    PropTypes.objectOf(PropTypes.any),
    PropTypes.string,
  ]),
  colorScheme: PropTypes.string,
  isDisabled: PropTypes.bool,
};

StepperItem.defaultProps = {
  isActive: false,
  isAfterTheCurrentStep: false,
  children: null,
  index: 0,
  fontSize: null,
  colorScheme: null,
  isDisabled: false,
};
