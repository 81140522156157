import React from 'react';

import { Button } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { Link } from '@/components';
import { useQueryParam } from '@/services/queryParam';

export const LoginFooter = ({ isLoading }) => {
  const redirect = useQueryParam('redirect');

  return (
    <>
      <Button
        as={Link}
        to={{
          pathname: '/register',
          search: redirect ? `?redirect=${redirect}` : null,
        }}
        colorScheme="brandSecondary"
        variant="link"
        py={1}
      >
        Pas encore de compte ?
      </Button>
      <Button
        type="submit"
        colorScheme="brandSecondary"
        w="100%"
        isDisabled={isLoading}
        isLoading={isLoading}
      >
        Se connecter
      </Button>
    </>
  );
};

LoginFooter.propTypes = {
  isLoading: PropTypes.bool,
};

LoginFooter.defaultProps = {
  isLoading: false,
};
