import React from 'react';

import { Flex, useTheme } from '@chakra-ui/react';

export const HeaderTitle = (props) => {
  const theme = useTheme();

  return (
    <Flex
      position="absolute"
      zIndex="2"
      w="100%"
      h="100%"
      justifyContent="center"
      alignItems="center"
      color="gray.700"
      fontWeight="700"
      fontSize="1.3rem"
      fontFamily={theme.fonts.heading}
      {...props}
    />
  );
};
