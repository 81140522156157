import React from 'react';

import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import {
  Badge,
  Card,
  CardContent,
  CardDescription,
  CardTitle,
  Icon,
} from '@/components';
import {
  BOOKING_REQUEST,
  BOOKING_REQUEST_SUBJECTS,
} from '@/constants/bookingRequest';
import { TIME_FORMAT } from '@/constants/date';
import { INVOICE_REQUEST_RESPONSES_STATUS } from '@/constants/invoiceRequest';
import { APPOINTMENT_CARD_ICONS } from '@/constants/meeting';
import { getAppointmentSubjectTextContent } from '@/services/utils';
import { formatAddress } from '@/utils/textUtils';

import { TooltipDateShower } from './TooltipDateShower';

export const InvoiceRequestCard = ({ invoiceRequest, ...otherProps }) => {
  const isXsScreen = useBreakpointValue({ base: true, sm: false });
  const isWide = useBreakpointValue({ base: false, md: true });

  const history = useHistory();
  const {
    subject: type,
    appointmentProposalDates,
    appointmentAddress,
    id,
  } = invoiceRequest;

  const subject = invoiceRequest[type];
  const address = formatAddress(appointmentAddress);

  const dates = (appointmentProposalDates || []).map((proposalDate) => {
    const { startDate, endDate } = proposalDate;
    const startDateObj =
      startDate && dayjs(startDate).isValid() ? dayjs(startDate) : null;
    const endDateObj =
      endDate && dayjs(endDate).isValid() ? dayjs(endDate) : null;
    const dateToDisplay = startDateObj
      ? startDateObj.format('DD MMMM YYYY')
      : 'N/A';
    const startTimeToDisplay = startDateObj
      ? startDateObj.format(TIME_FORMAT)
      : 'N/A';
    const endTimeToDisplay = endDateObj
      ? endDateObj.format(TIME_FORMAT)
      : 'N/A';

    return {
      dateToDisplay,
      startTimeToDisplay,
      endTimeToDisplay,
    };
  });

  const subjectName =
    BOOKING_REQUEST_SUBJECTS[BOOKING_REQUEST[type]]?.name || '';

  const iconProps =
    APPOINTMENT_CARD_ICONS[type] || APPOINTMENT_CARD_ICONS.DEFAULT;

  const invoiceRequestSubjectContent = getAppointmentSubjectTextContent(
    subject
  );
  const countResponses =
    (!!invoiceRequest?.responses &&
      invoiceRequest?.responses?.reduce(
        (countResponse, currentValue) =>
          currentValue.status !== INVOICE_REQUEST_RESPONSES_STATUS.WAITING
            ? countResponse + 1
            : countResponse,
        0
      )) ||
    0;

  return (
    <Card
      w="100%"
      borderWidth="0"
      {...otherProps}
      as="button"
      _focus={{
        boxShadow: 'outline',
        outline: 'none',
      }}
      onClick={() => history.push(`/bookings/${invoiceRequest?.id || ''}`)}
    >
      <CardContent position="relative">
        <Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            w="3rem"
            h="3rem"
            overflow="hidden"
            bg="brandSecondary.100"
            borderRadius="spot.variant1"
          >
            <Icon mt="1" color="brandSecondary.700" {...iconProps} />
          </Flex>
          <Box ml="3">
            <CardTitle>
              {dates[0]?.dateToDisplay}
              {isWide && <TooltipDateShower dates={dates} />}
            </CardTitle>
            <CardTitle as="h3" size="sm">
              {`${dates[0]?.startTimeToDisplay} à ${dates[0]?.endTimeToDisplay}`}
            </CardTitle>
            {!isWide && <TooltipDateShower dates={dates} id={id} />}
          </Box>
        </Flex>
        <CardDescription textAlign="left" h={isXsScreen ? '5.5rem' : '4.5rem'}>
          <Text fontSize="sm">
            {subjectName}
            {!!invoiceRequestSubjectContent[type] && (
              <Box as="span" d="inline" fontWeight="bold">
                {!!subjectName && ' - '}
                {invoiceRequestSubjectContent[type]}
              </Box>
            )}
          </Text>
          <Text fontSize="sm">
            <Icon icon={FaMapMarkerAlt} color="brandSecondary.700" mr="2" />
            {address}
          </Text>
        </CardDescription>
        <Badge
          position="absolute"
          top="1"
          right="0"
          colorScheme="brandSecondary"
        >
          {isXsScreen
            ? countResponses || 0
            : `${countResponses || 0} réponse${countResponses > 1 ? 's' : ''}`}
        </Badge>
      </CardContent>
    </Card>
  );
};

InvoiceRequestCard.propTypes = {
  invoiceRequest: PropTypes.shape({
    id: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
    subject: PropTypes.string,
    appointmentProposalDates: PropTypes.arrayOf(PropTypes.object),

    appointmentAddress: PropTypes.shape({
      streetAddress: PropTypes.string,
      zipCode: PropTypes.string,
      city: PropTypes.string,
    }),
    responses: PropTypes.arrayOf(PropTypes.object),
  }),
};

InvoiceRequestCard.defaultProps = {
  invoiceRequest: {
    id: '',
    subject: '',
    appointmentProposalDates: null,
    appointmentAddress: {
      streetAddress: '',
      zipCode: '',
      city: '',
    },
    responses: [],
  },
};
