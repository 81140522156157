import React, { useState } from 'react';

import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useTheme,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

export const LoggedInMenuOptions = ({
  menuButtonProps,
  children,
  ...otherProps
}) => {
  const theme = useTheme();
  const [isOpen, setIsOpened] = useState(false);
  const location = useLocation();

  const isHomePage = location?.pathname === '/';
  const activeHeaderColor = isHomePage ? 'brand' : 'brandSecondary';

  return (
    <Menu
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      {...otherProps}
    >
      <MenuButton
        as={Button}
        variant="ghost"
        colorScheme={
          isOpen ? `${activeHeaderColor}.600` : `${activeHeaderColor}.800`
        }
        {...(isHomePage
          ? {
              colorScheme: isOpen ? 'brand.600' : 'brand.800',
              color: 'brand.800',
            }
          : {
              colorScheme: isOpen ? 'brandSecondary.600' : 'brandSecondary.800',
            })}
        fontWeight={700}
        bg={isOpen ? `${activeHeaderColor}.50` : 'transparent'}
        transition="0.2s ease"
        _hover={{
          color: theme.colors[activeHeaderColor][700],
          bg: theme.colors[activeHeaderColor][50],
        }}
        _active={{
          boxShadow: 'outline',
        }}
        fontFamily={theme.fonts.body}
        {...menuButtonProps}
      >
        {children}
      </MenuButton>
      <MenuList fontFamily={theme.fonts.body}>
        <MenuItem
          as={Link}
          to="/profile"
          fontWeight="bold"
          {...(isHomePage
            ? {
                color: 'brand.800',
              }
            : {
                color: 'gray.600',
              })}
          {...(location?.pathname === '/profile'
            ? {
                color: theme.colors[activeHeaderColor][600],
              }
            : {})}
          _hover={{
            color: theme.colors[activeHeaderColor][700],
            bg: theme.colors[activeHeaderColor][50],
          }}
          _focus={{
            color: theme.colors[activeHeaderColor][700],
            bg: theme.colors[activeHeaderColor][50],
          }}
        >
          Profil
        </MenuItem>
        <MenuItem
          as={Link}
          to="/password"
          fontWeight="bold"
          {...(isHomePage
            ? {
                color: 'brand.800',
              }
            : {
                color: 'gray.600',
              })}
          {...(location?.pathname === '/password'
            ? {
                color: theme.colors[activeHeaderColor][600],
              }
            : {})}
          _hover={{
            color: theme.colors[activeHeaderColor][700],
            bg: theme.colors[activeHeaderColor][50],
          }}
          _focus={{
            color: theme.colors[activeHeaderColor][700],
            bg: theme.colors[activeHeaderColor][50],
          }}
        >
          Changer le mot de passe
        </MenuItem>
        <MenuItem
          as={Link}
          to="/logout"
          fontWeight="bold"
          {...(isHomePage
            ? {
                color: 'brand.800',
              }
            : {
                color: 'gray.600',
              })}
          _hover={{
            color: theme.colors[activeHeaderColor][700],
            bg: theme.colors[activeHeaderColor][50],
          }}
          _focus={{
            color: theme.colors[activeHeaderColor][700],
            bg: theme.colors[activeHeaderColor][50],
          }}
        >
          Déconnexion
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

LoggedInMenuOptions.propTypes = {
  menuButtonProps: PropTypes.object, // eslint-disable-line
  children: PropTypes.node,
};

LoggedInMenuOptions.defaultProps = {
  menuButtonProps: {},
  children: null,
};
