import { colors } from './colors';

export const shadows = {
  'reversed-sm': '0 -1px 2px 0 rgba(0, 0, 0, 0.05)',
  'reversed-base':
    '0 -1px 3px 0 rgba(0, 0, 0, 0.1), 0 -1px 2px 0 rgba(0, 0, 0, 0.06)',
  'reversed-md':
    '0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06)',
  'reversed-lg':
    '0 -10px 15px -3px rgba(0, 0, 0, 0.1), 0 -4px 6px -2px rgba(0, 0, 0, 0.05)',
  'reversed-xl':
    '0 -20px 25px -5px rgba(0, 0, 0, 0.1), 0 -10px 10px -5px rgba(0, 0, 0, 0.04)',
  'reversed-2xl': '0 -25px 50px -12px rgba(0, 0, 0, 0.25)',
  outline: `0 0 0 3px ${colors.brandSecondary[400]}`,
};
