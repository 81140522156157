import React, { useEffect, useState } from 'react';

import { Box, Checkbox } from '@chakra-ui/react';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import PropTypes from 'prop-types';

import { FormGroup } from '@/components';

export const FieldCheckbox = (props) => {
  const {
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    errorMessage,
  } = useField(props);

  const {
    label,
    isDisabled,
    required,
    formatValue,
    keepValue,
    asyncValidations,
    checkboxProps,
    ...otherProps
  } = props;

  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const handleBlur = () => {
    setIsTouched(true);
  };

  const handleChange = (e) => setValue(e.target.checked);

  const formGroupProps = {
    errorMessage,
    htmlFor: id,
    isRequired: !!required,
    showError,
    isDisabled,
    ...otherProps,
  };

  return (
    <FormGroup {...formGroupProps}>
      <Checkbox
        id={id}
        key={resetKey}
        defaultIsChecked={value}
        isChecked={value}
        onBlur={handleBlur}
        onChange={handleChange}
        aria-invalid={showError}
        aria-describedby={!isValid ? `${id}-error` : null}
        disabled={isDisabled}
        colorScheme="brandSecondary"
        display="flex"
        alignItems="flex-start"
        mt={1}
        {...checkboxProps}
      >
        <Box mt={-1}>{label}</Box>
      </Checkbox>
    </FormGroup>
  );
};

FieldCheckbox.propTypes = {
  label: PropTypes.node,
  isDisabled: PropTypes.bool,
  checkboxProps: PropTypes.objectOf(PropTypes.any),
  ...fieldPropTypes,
};

FieldCheckbox.defaultProps = {
  label: '',
  isDisabled: false,
  checkboxProps: {},
  ...fieldDefaultProps,
};
