export const BOOKING_REQUEST_STORAGE_KEY = 'booking_request';

export const INVOICE_REQUEST_STEPS = {
  AGENCIES: {
    name: 'INVOICE_REQUEST_AGENCIES',
    order: 100,
  },
  FURTHER_INFORMATION: {
    name: 'INVOICE_REQUEST_FURTHER_INFORMATION',
    order: 200,
  },
  PERSONAL_INFORMATION: {
    name: 'INVOICE_REQUEST_PERSONAL_INFORMATION',
    order: 300,
  },
  FURTHER_PAYMENT_INFORMATION: {
    name: 'INVOICE_REQUEST_FURTHER_PAYMENT_INFORMATION',
    order: 400,
  },
  PAYMENT_INFORMATION: {
    name: 'INVOICE_REQUEST_PAYMENT_INFORMATION',
    order: 500,
  },
};

export const BOOKING_INVOICE_REQUEST_ACCEPT_STEPS = {
  ACCEPT_FURTHER_INFORMATION: {
    name: 'BOOKING_INVOICE_REQUEST_ACCEPT_FURTHER_INFORMATION',
    order: 100,
  },
  INVOICE_REQUEST_VALIDATION: {
    name: 'BOOKING_INVOICE_REQUEST_ACCEPT_VALIDATION',
    order: 200,
  },
};

export const INVOICE_REQUEST_BENEFICIARIES = {
  YOU: 'you',
  OTHER_PERSON: 'other-person',
};

export const INVOICE_REQUEST_PAYERS = {
  YOU: 'you',
  COMPANY: 'company',
};

export const INVOICE_REQUEST_RESPONSES_STATUS = {
  RECEIVED: 'RECEIVED',
  WAITING: 'WAITING',
  UNAVAILABLE: 'UNAVAILABLE',
  REFUSED: 'REFUSED',
};

export const INVOICE_REQUEST_RESPONSES_LABEL = {
  [INVOICE_REQUEST_RESPONSES_STATUS.RECEIVED]: {
    name: 'Devis reçu',
    color: 'brandSecondary',
  },
  [INVOICE_REQUEST_RESPONSES_STATUS.WAITING]: {
    name: 'En attente',
    color: 'warning',
  },
  [INVOICE_REQUEST_RESPONSES_STATUS.UNAVAILABLE]: {
    name: 'Indisponible',
    color: 'danger',
  },
  [INVOICE_REQUEST_RESPONSES_STATUS.REFUSED]: {
    name: 'Refus',
    color: 'danger',
  },
};
