import React from 'react';

import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { FaChevronRight, FaMapMarkerAlt } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
  Icon,
} from '@/components';
import {
  BOOKING_REQUEST,
  BOOKING_REQUEST_SUBJECTS,
} from '@/constants/bookingRequest';
import { TIME_FORMAT } from '@/constants/date';
import { APPOINTMENT_CARD_ICONS } from '@/constants/meeting';
import { getAppointmentSubjectTextContent } from '@/services/utils';
import { formatAddress } from '@/utils/textUtils';

export const AppointmentCard = ({ appointment, ...otherProps }) => {
  const isWide = useBreakpointValue({ base: false, md: true });
  const history = useHistory();
  const { startDate, endDate, subject, appointmentAddress } = appointment || {};
  const subjectObject = appointment[subject];
  const address = formatAddress(appointmentAddress);

  const startDateObj = dayjs(startDate).isValid() ? dayjs(startDate) : null;
  const endDateObj = dayjs(endDate).isValid() ? dayjs(endDate) : null;

  const dateToDisplay = startDateObj
    ? startDateObj.format('DD MMMM YYYY')
    : 'N/A';

  const startTimeToDisplay = startDateObj
    ? startDateObj.format(TIME_FORMAT)
    : 'N/A';

  const endTimeToDisplay = endDateObj ? endDateObj.format(TIME_FORMAT) : 'N/A';

  const subjectName =
    BOOKING_REQUEST_SUBJECTS[BOOKING_REQUEST[subject]]?.name || '';

  const iconProps =
    APPOINTMENT_CARD_ICONS[subject] || APPOINTMENT_CARD_ICONS.DEFAULT;

  const subjectText = getAppointmentSubjectTextContent(subjectObject);

  const handleClick = (e) => {
    history.push(`/agenda/${appointment?.id}`);
    if (otherProps.onClick) {
      otherProps.onClick(e);
    }
  };

  return (
    <Card
      w="100%"
      borderWidth="0"
      {...otherProps}
      as="button"
      _focus={{
        boxShadow: 'outline',
        outline: 'none',
      }}
      onClick={handleClick}
      {...(isWide
        ? {
            minH: '6rem',
            px: 8,
          }
        : {})}
    >
      <CardContent
        {...(isWide
          ? {
              display: 'flex',
              justifyContent: 'center',
            }
          : {})}
      >
        <Box
          {...(isWide
            ? {
                display: 'flex',
                alignItems: 'center',
              }
            : {})}
        >
          <Flex>
            <Flex
              justifyContent="center"
              alignItems="center"
              w="3rem"
              h="3rem"
              overflow="hidden"
              bg="brandSecondary.100"
              borderRadius="spot.variant1"
            >
              <Icon mt="1" color="brandSecondary.700" {...iconProps} />
            </Flex>
            <Box ml={3}>
              <CardTitle whiteSpace="nowrap">{dateToDisplay}</CardTitle>
              <CardTitle as="h3" size="sm" whiteSpace="nowrap">
                {`${startTimeToDisplay} à ${endTimeToDisplay}`}
              </CardTitle>
            </Box>
          </Flex>
          <CardDescription
            textAlign="left"
            {...(isWide
              ? {
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  mt: 0,
                  ml: 8,
                  width: '20rem',
                }
              : {})}
          >
            <Text
              fontSize="sm"
              maxW={{ base: 'auto', md: '22rem' }}
              isTruncated={{ base: false, md: true }}
            >
              {subjectName}
              {!!subjectText[subject] && (
                <Box as="span" d="inline" fontWeight="bold">
                  {!!subjectName && ' - '}
                  {subjectText[subject]}
                </Box>
              )}
            </Text>
            <Text
              fontSize="sm"
              maxW={{ base: 'auto', md: '22rem' }}
              isTruncated={{ base: false, md: true }}
            >
              <Icon icon={FaMapMarkerAlt} color="brandSecondary.700" mr="2" />
              {address}
            </Text>
          </CardDescription>
          {isWide && (
            <Flex
              alignSelf="right"
              flex={1}
              minW="4rem"
              justifyContent="flex-end"
            >
              <Icon icon={FaChevronRight} fontSize="1rem" color="gray.700" />
            </Flex>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

AppointmentCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  appointment: PropTypes.object,
};

AppointmentCard.defaultProps = {
  appointment: null,
};
