import React, { useContext } from 'react';

import { USER_STORAGE, USER_TOKEN_STORAGE_KEY } from '@/services/config';
import { useLocalStorage } from '@/services/localStorage';

export const UserContext = React.createContext();
export const useUserContext = () => useContext(UserContext);

export const UserProvider = (props) => {
  const [token, updateToken] = useLocalStorage(USER_TOKEN_STORAGE_KEY, null);
  const [user, updateUser] = useLocalStorage(USER_STORAGE, null);

  return (
    <UserContext.Provider
      value={{
        isLogged: !!token,
        token,
        updateToken,
        user,
        updateUser,
      }}
      {...props}
    />
  );
};
