import React from 'react';

import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex, Center, Heading, Spinner, Text, Box } from '@chakra-ui/react';
import { FormizStep, useForm } from '@formiz/core';
import PropTypes from 'prop-types';

import { BOOKING_REQUEST_DATE_TIME } from '@/constants/bookingRequest';
import { FORM_STEPS } from '@/constants/steps';
import { HelpIconButton } from '@/pages/BookingRequest/_partials/HelpIconButton';

import { CardStep } from './_partials/CardStep';
import { DisplayDateCard } from './_partials/DisplayDateCard';
import { SummarySubjectDetail } from './_partials/SummarySubjectDetail';

export const BookingSummary = ({ agencies, isAgenciesLoading }) => {
  const [isLessThan360] = useMediaQuery('(max-width: 360px)');
  const form = useForm();
  const { values, goToStep } = form;
  const { appointment } = values;

  const agenciesStats = {
    totalAgencies: (agencies || []).length,
    lowestPrice:
      Math.min(
        ...(agencies || []).map((agency) => Number(agency.lowestPrice))
      ) || 0,
    highestPrice:
      Math.max(
        ...(agencies || []).map((agency) => Number(agency.highestPrice))
      ) || 0,
  };

  const isFreeDateStep =
    appointment?.datetime?.appointmentDateTimeType ===
    BOOKING_REQUEST_DATE_TIME.FREE_DATE.id;

  const isMultiDates =
    appointment?.datetime?.appointmentDateTimeType ===
    BOOKING_REQUEST_DATE_TIME.MULTI_DATES.id;

  const isRecurringDateStep =
    appointment?.datetime?.appointmentDateTimeType ===
    BOOKING_REQUEST_DATE_TIME.RECURRING_DATE.id;

  return (
    <>
      {/* We're using order feature from Formiz to keep the steps in order
       * 40 is for the booking summary step
       */}
      <FormizStep
        name={FORM_STEPS.SUMMARY.name}
        order={FORM_STEPS.SUMMARY.order}
      >
        <Flex marginX="auto" flexDirection="column" maxWidth="45rem">
          <Center
            flexDirection="column"
            mx="10"
            textAlign="center"
            flex={1}
            position="relative"
          >
            {isAgenciesLoading ? (
              <Spinner
                thickness="4px"
                emptyColor="gray.200"
                color="brandSecondary.600"
                size="xl"
              />
            ) : (
              <>
                {(agencies || []).length ? (
                  <>
                    <Text fontWeight="700" fontSize="1.4em">
                      Selon votre demande
                    </Text>
                    <Text>
                      {agenciesStats?.totalAgencies > 1
                        ? `Nous avons identifié ${agenciesStats?.totalAgencies} agences susceptibles de répondre à votre demande.`
                        : 'Nous avons identifié une agence susceptible de répondre à votre demande.'}
                    </Text>
                    <Text>
                      L’interprétation coûterait entre{' '}
                      {agenciesStats.lowestPrice} € et{' '}
                      {agenciesStats.highestPrice} € TTC sans les frais de
                      déplacement.
                    </Text>
                  </>
                ) : (
                  <>
                    <Heading as="h3" size="lg">
                      Aucune agence n&apos;est disponible
                    </Heading>
                    <Text mt={2}>
                      Vous pouvez modifier les informations saisies
                    </Text>
                  </>
                )}
              </>
            )}
            <HelpIconButton
              position="relative"
              top={{ base: 1, sm: 1 }}
              right={{ base: 0, sm: 2 }}
            />
          </Center>
          <Box px={{ base: 4, sm: 0 }} mt={4}>
            <CardStep
              stepNumber="1"
              title="Adresse"
              onClick={() => goToStep(FORM_STEPS.LOCATION.ADDRESS.name)}
              mb={2}
            >
              <Text fontSize="lg" isTruncated>
                {values?.appointment?.location?.streetAddress || ''}
              </Text>
              <Text fontSize="lg" isTruncated>
                {`${values?.appointment?.location?.zipCode || ''}${
                  (values?.appointment?.location?.city &&
                    ` - ${values?.appointment?.location?.city}`) ||
                  ''
                }`}
              </Text>
            </CardStep>
            <CardStep
              stepNumber="2"
              title="Objet du rendez-vous"
              onClick={() => goToStep(FORM_STEPS.INFORMATION.CATEGORY.name)}
              mb={2}
            >
              <SummarySubjectDetail />
            </CardStep>
            <CardStep
              stepNumber="3"
              title={`Date et horaires ${
                isFreeDateStep ? 'des' : 'du'
              } rendez-vous`}
              onClick={() => goToStep(FORM_STEPS.DATETIME.TYPE.name)}
              mb="20"
              cardDescriptionProps={
                isLessThan360 ||
                isFreeDateStep ||
                isRecurringDateStep ||
                isMultiDates
                  ? { h: 'auto' }
                  : null
              }
              cardContentProps={{
                overflow: 'initial',
              }}
            >
              <DisplayDateCard appointment={appointment} />
            </CardStep>
          </Box>
        </Flex>
      </FormizStep>
    </>
  );
};
BookingSummary.propTypes = {
  agencies: PropTypes.arrayOf(PropTypes.any),
  isAgenciesLoading: PropTypes.bool,
};

BookingSummary.defaultProps = {
  agencies: [],
  isAgenciesLoading: false,
};
