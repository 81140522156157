import React, { useEffect, useState } from 'react';

import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import { FormGroup, Select } from '@/components';

import { Icon } from '../Icon';

export const FieldSelect = (props) => {
  const {
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    errorMessage,
  } = useField(props);

  const {
    colorScheme,
    label,
    helper,
    placeholder,
    noOptionsMessage,
    loadingMessage,
    formatCreateLabel,
    isCreatable,
    isDisabled,
    isMulti,
    isSearchable,
    required,
    options,
    onInputChange,
    formatValue,
    keepValue,
    asyncValidations,
    selectProps,
    icon,
    defaultValue,
    ...otherProps
  } = props;

  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  const formGroupProps = {
    htmlFor: id,
    label,
    helper,
    errorMessage,
    showError,
    isRequired: !!required,
    isDisabled,
    ...otherProps,
  };

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const handleChange = (values) => setValue(values);

  const handleBlur = () => {
    if (isTouched) return;
    setIsTouched(true);
  };

  return (
    <FormGroup {...formGroupProps}>
      <Select
        id={id}
        key={resetKey}
        defaultValue={value}
        colorScheme={colorScheme}
        placeholder={placeholder}
        noOptionsMessage={noOptionsMessage || 'Aucune option'}
        loadingMessage={loadingMessage}
        formatCreateLabel={formatCreateLabel}
        isCreatable={isCreatable}
        isDisabled={isDisabled}
        isError={showError}
        isMulti={isMulti}
        isSearchable={isSearchable}
        options={options}
        onBlur={handleBlur}
        onChange={handleChange}
        onInputChange={onInputChange}
        aria-invalid={showError}
        aria-required={!!required}
        aria-describedby={showError ? `${id}-error` : null}
        value={value}
        {...(icon
          ? {
              components: {
                DropdownIndicator: (iconProps) => (
                  <components.DropdownIndicator {...iconProps}>
                    <Icon icon={icon} color="brandSecondary.600" />
                  </components.DropdownIndicator>
                ),
              },
            }
          : {})}
        {...selectProps}
      />
    </FormGroup>
  );
};

FieldSelect.propTypes = {
  colorScheme: PropTypes.string,
  label: PropTypes.node,
  helper: PropTypes.string,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  loadingMessage: PropTypes.string,
  formatCreateLabel: PropTypes.func,
  isCreatable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node,
      label: PropTypes.node,
    })
  ),
  onInputChange: PropTypes.func,
  selectProps: PropTypes.object, // eslint-disable-line
  ...fieldPropTypes,
};

FieldSelect.defaultProps = {
  colorScheme: 'brandSecondary',
  label: '',
  helper: '',
  placeholder: '',
  noOptionsMessage: null,
  loadingMessage: null,
  formatCreateLabel: null,
  isCreatable: false,
  isDisabled: false,
  isMulti: false,
  isSearchable: true,
  options: [],
  onInputChange: () => {},
  selectProps: {},
  ...fieldDefaultProps,
};
