import { BOOKING_REQUEST_CATEGORY } from './bookingRequest';

export const HEALTH_APPOINTMENT = {
  GENERAL_PRACTITIONER: 'general_practitioner',
  MEDICAL_SPECIALIST: 'medical_specialist',
  OCCUPATIONAL_MEDICINE: 'occupational_medicine',
  WELL_BEING_AT_WORK: 'well_being_at_work',
};

export const HEALTH_APPOINTMENT_TYPE = {
  [HEALTH_APPOINTMENT.GENERAL_PRACTITIONER]: {
    name: 'Soin général',
    description: 'Médecin traitant, diététicien, infirmier…',
  },
  [HEALTH_APPOINTMENT.MEDICAL_SPECIALIST]: {
    name: 'Soin spécialisé',
    description: 'Médecin spécialiste, masseur kinésithérapeute, ostéopathe…',
  },
  [HEALTH_APPOINTMENT.OCCUPATIONAL_MEDICINE]: {
    name: 'Médecine du travail',
    description: 'Visite d’aptitude – Rendez-vous spécifique',
  },
  [HEALTH_APPOINTMENT.WELL_BEING_AT_WORK]: {
    name: 'Bien-être au travail',
    description: 'Adaptation de poste – Accompagnement spécifique',
  },
};

export const HEALTH_BY_CATEGORY = {
  [BOOKING_REQUEST_CATEGORY.PROFESSIONAL.id]: {
    heading: `Type de rendez-vous santé au travail`,
    helperHeading: `Type de rendez-vous santé au travail`,
    serviceType: 'Type de rendez-vous',
    serviceTypeHelper: 'Avec quel professionnel avez-vous rendez-vous ?',
    serviceTypePlaceholder: 'ex : Ergonome, Psychologue, Sophrologue, ...',
  },
  [BOOKING_REQUEST_CATEGORY.PERSONAL.id]: {
    heading: `Type de rendez-vous santé`,
    helperHeading: `Type de rendez-vous santé au travail`,
    serviceType: 'Type de soin',
    serviceTypePlaceholder: 'Ex : Médecin traitant, Infirmier, ...',
  },
};

export const HEALTH_MEDICAL_SPECIALIST_TYPE = [
  { label: 'Alcoologie', value: 'alcoholology' },
  { label: 'Algologie', value: 'algology' },
  { label: 'Allergologie', value: 'allergology' },
  {
    label: 'Anatomie et cytologie pathologiques',
    value: 'pathologicalAnatomyAndCytology',
  },
  { label: 'Andrologie', value: 'andrology' },
  {
    label: 'Anesthésiologie-réanimation chirurgicale',
    value: 'surgicalAnesthesiologyResuscitation',
  },
  { label: 'Angiologie', value: 'angiology' },
  {
    label: 'Audio-prothésiste',
    value: 'audio-prosthesist',
  },
  { label: 'Biochimie', value: 'biochemistry' },
  {
    label: 'Biologie médicale et physiologie',
    value: 'medicalBiologyAndPhysiology',
  },
  { label: 'Cardiologie', value: 'cardiology' },
  { label: 'Chirurgie', value: 'surgery' },
  {
    label: 'Chirurgie digestive',
    value: 'digestiveSurgery',
  },
  {
    label: 'Chirurgie infantile',
    value: 'childhoodSurgery',
  },
  {
    label: 'Chirurgie maxillo-faciale et stomatologie',
    value: 'maxillofacialSurgeryAndStomatology',
  },
  {
    label: 'Chirurgie orthopédique et traumatologique',
    value: 'orthopedicAndTraumaSurgery',
  },
  {
    label: 'Chirurgie plastique',
    value: 'plasticSurgery',
  },
  {
    label: 'Chirurgie plastique, reconstructrice et esthétique',
    value: 'plastic,ReconstructiveAndAestheticSurgery',
  },
  {
    label: 'Chirurgie thoracique et cardio-vasculaire',
    value: 'thoracicAndCardiovascularSurgery',
  },
  {
    label: 'Chirurgie urologique',
    value: 'urologicalSurgery',
  },
  {
    label: 'Chirurgie vasculaire',
    value: 'vascularSurgery',
  },
  {
    label: 'Chirurgie viscérale',
    value: 'visceralSurgery',
  },
  { label: 'Contactologie', value: 'contactology' },
  {
    label: 'Coronarographie',
    value: 'coronaryAngiography',
  },
  { label: 'Dentisterie', value: 'dentistry' },
  {
    label: 'Dermatologie et vénéréologie',
    value: 'dermatologyAndVenereology',
  },
  {
    label: 'Endocrinologie et métabolisme',
    value: 'endocrinologyAndMetabolism',
  },
  {
    label: 'Explorations fonctionnelles',
    value: 'functionalExplorations',
  },
  {
    label: 'Gastro-entérologie et hépatologie',
    value: 'gastroenterologyAndHepatology',
  },
  { label: 'Génétique', value: 'genetic' },
  { label: 'Gériatrie', value: 'geriatrics' },
  {
    label: 'Gynécologie-obstétrique',
    value: 'obstetricGynecology',
  },
  { label: 'Hématologie', value: 'hematology' },
  {
    label: 'Hygiène hospitalière',
    value: 'hospitalHygiene',
  },
  {
    label: 'Imagerie médicale(radiologie, échographie, IRM, scanner, etc.)',
    value: 'radiology',
  },
  {
    label: 'Information médicale',
    value: 'medicalInformation',
  },
  {
    label: 'Laboratoire d’analyse',
    value: 'medical laboratory',
  },
  {
    label: 'Maladies infectieuses et tropicales',
    value: 'infectiousAndTropicalDiseases',
  },
  {
    label: 'Masseur-kinésithérapeute',
    value: 'physiotherapy massager',
  },
  {
    label: 'Médecine du travail',
    value: 'occupationalMedicine',
  },
  {
    label: 'Médecine interne',
    value: 'internalMedicine',
  },
  {
    label: 'Médecine légale',
    value: 'forensicMedecine',
  },
  {
    label: 'Médecine nucléaire',
    value: 'nuclearMedicine',
  },
  {
    label: 'Médecine physique et de réadaptation',
    value: 'physicalMedicineAndRehabilitation',
  },
  { label: 'Néonatologie', value: 'neonatology' },
  { label: 'Néphrologie', value: 'nephrology' },
  {
    label: 'Neurochirurgie',
    value: 'neurosurgery',
  },
  { label: 'Neurologie', value: 'neurology' },
  {
    label: 'Neuropédiatrie',
    value: 'neuropediatrics',
  },
  {
    label: 'Neuropsychiatrie',
    value: 'neuropsychiatry',
  },
  {
    label: 'Nutritionniste',
    value: 'nutritionist',
  },
  { label: 'Odontologie', value: 'odontology' },
  { label: 'Oncologie', value: 'oncology' },
  {
    label: 'Ophtalmologie',
    value: 'ophthalmology',
  },
  { label: 'Orthodontie', value: 'orthodontics' },
  { label: 'Orthopédie', value: 'orthopedics' },
  { label: 'Orthophonie', value: 'speechTherapy' },
  {
    label: 'Orthophoniste',
    value: 'speech Therapist',
  },
  { label: 'Orthoptie', value: 'orthoptics' },
  { label: 'Orthoptiste', value: 'orthoptist' },
  { label: 'Ostéopathie', value: 'osteopathy' },
  {
    label: 'Oto-rhino-laryngologie',
    value: 'otorhinolaryngology',
  },
  { label: 'Otologie', value: 'otology' },
  { label: 'Otoneurologie', value: 'otoneurology' },
  { label: 'Parodontie', value: 'periodontics' },
  {
    label: 'Parodontologie',
    value: 'periodontology',
  },
  { label: 'Pédiatrie', value: 'pediatrics' },
  { label: 'Pédicure', value: 'pedicure' },
  { label: 'Pédodontie', value: 'pedodontics' },
  {
    label: 'Pédopsychiatrie',
    value: 'childPsychiatry',
  },
  { label: 'Pharmacie', value: 'pharmacy' },
  { label: 'Pharmacologie', value: 'pharmacology' },
  { label: 'Phlébologie', value: 'phlebology' },
  { label: 'Pneumologie', value: 'pulmonology' },
  { label: 'Podo-orthésiste', value: 'orthesist' },
  { label: 'Posturologie', value: 'posturology' },
  { label: 'Proctologie', value: 'proctology' },
  { label: 'Prothésiste', value: 'prosthetist' },
  {
    label: 'Psychanalyste',
    value: 'psychoanalyst',
  },
  { label: 'Psychiatrie', value: 'psychiatry' },
  {
    label: 'Psychologue – Psychothérapeute',
    value: 'psychologist - psychotherapist',
  },
  {
    label: 'Psychomotricien',
    value: 'psychomotrician',
  },
  {
    label: 'Puéricultrice',
    value: 'puericultrist',
  },
  { label: 'Radiothérapie', value: 'radiotherapy' },
  {
    label: 'Réanimation médicale',
    value: 'medicalReanimation',
  },
  { label: 'Rhumatologie', value: 'rheumatology' },
  { label: 'Sage-femme', value: 'midwife' },
  { label: 'SAMU-SMUR', value: 'samuSmur' },
  {
    label: 'Santé publique et médecine sociale',
    value: 'publicHealthAndSocialMedicine',
  },
  {
    label: 'Soins palliatifs',
    value: 'palliativeCare',
  },
  { label: 'Stomatologie', value: 'stomatology' },
  { label: 'Strabologie', value: 'strabology' },
  { label: 'Tabacologie', value: 'tobacco' },
  { label: 'Urgences', value: 'emergencies' },
  { label: 'Urologie', value: 'urology' },
  { label: 'Vénéréologie', value: 'venereology' },
];

export const HEALTH_WELL_BEING_AT_WORK_TYPE = [
  {
    value: 'specialist_1',
    label: 'Psychologue',
  },
  {
    value: 'specialist_2',
    label: 'Ergonome',
  },
  {
    value: 'specialist_3',
    label: 'Sophrologue',
  },
];
