import React from 'react';

import { Heading } from '@chakra-ui/react';

import { Link } from '@/components';
import { Page, AppHeader } from '@/layout';

export const NoMatch = () => (
  <Page>
    <AppHeader title="Page non trouvée" />
    <Heading as="h1" size="2xl">
      Page 404
    </Heading>
    <Link to="/">Retour à la page d&apos;accueil</Link>
  </Page>
);
