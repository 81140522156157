import React from 'react';

import { Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import {
  BOOKING_REQUEST,
  BOOKING_REQUEST_SUBJECTS,
  JUSTICE_REQUEST_CATEGORY,
  MEETING_REQUEST_CATEGORY,
} from '@/constants/bookingRequest';
import { HEALTH_APPOINTMENT_TYPE } from '@/constants/health';
import { TEXT_FOR_JUSTICE_PROCEDURE_TYPE } from '@/constants/justice';
import { MEETING_APPOINTMENT_LABEL } from '@/constants/meeting';

export const SubjectSummaryTitle = ({
  currentBookingRequestSubject,
  subjectType,
  appointment,
}) => {
  const requestName = BOOKING_REQUEST?.[appointment?.subject];
  const subjectName = BOOKING_REQUEST_SUBJECTS?.[requestName]?.name || '';

  const displayIfIsMeetingAndHaveType =
    BOOKING_REQUEST_SUBJECTS.MEETING.id === currentBookingRequestSubject?.id &&
    appointment?.meeting?.type;

  const displayIfIsProfessionalAndFaceToFaceAndHaveFirstNameAndLastName =
    BOOKING_REQUEST_SUBJECTS.MEETING.id === currentBookingRequestSubject?.id &&
    MEETING_REQUEST_CATEGORY.MEETING_FACE_TO_FACE.id ===
      appointment?.meeting?.category &&
    appointment?.meeting?.interlocutorFullName;

  const procedureMessage = appointment?.justice?.type !==
    JUSTICE_REQUEST_CATEGORY.NO.id && (
    <Text as="span" fontSize="lg" color="gray.500">
      {TEXT_FOR_JUSTICE_PROCEDURE_TYPE[appointment?.justice?.type]}{' '}
    </Text>
  );
  const textHealthSpeciality =
    (currentBookingRequestSubject?.id ===
      BOOKING_REQUEST_SUBJECTS?.HEALTH?.id &&
      appointment[currentBookingRequestSubject?.id]?.specialist &&
      appointment[currentBookingRequestSubject?.id]?.specialist?.label) ||
    appointment[currentBookingRequestSubject?.id]?.specialist ||
    HEALTH_APPOINTMENT_TYPE[subjectType]?.name;

  return (
    <Text as="span" fontSize="lg">
      {subjectName}
      {HEALTH_APPOINTMENT_TYPE[subjectType]?.name &&
        `, ${HEALTH_APPOINTMENT_TYPE[subjectType]?.name}`}
      {displayIfIsMeetingAndHaveType && (
        <>
          , {MEETING_APPOINTMENT_LABEL[appointment?.meeting?.category]},{' '}
          <Text as="span" color="gray.500" isTruncated>
            {appointment?.meeting?.type}
          </Text>
        </>
      )}
      {displayIfIsProfessionalAndFaceToFaceAndHaveFirstNameAndLastName && (
        <>
          , {MEETING_APPOINTMENT_LABEL[appointment?.meeting?.category]},
          <Text as="span" color="gray.500" isTruncated>
            {appointment?.meeting?.interlocutorFullName &&
              ` ${appointment.meeting.interlocutorFullName}`}
          </Text>
        </>
      )}
      {BOOKING_REQUEST_SUBJECTS.EVENT.id ===
        currentBookingRequestSubject?.id && (
        <>
          ,{' '}
          <Text as="span" color="gray.500" isTruncated>
            {appointment?.event?.type}
          </Text>
        </>
      )}
      {BOOKING_REQUEST_SUBJECTS.JUSTICE.id ===
        currentBookingRequestSubject?.id && <>, {procedureMessage}</>}
      {BOOKING_REQUEST_SUBJECTS.HEALTH.id ===
        currentBookingRequestSubject?.id && (
        <>{(textHealthSpeciality && `, ${textHealthSpeciality}`) || ''} </>
      )}
    </Text>
  );
};

SubjectSummaryTitle.propTypes = {
  currentBookingRequestSubject: PropTypes.objectOf(PropTypes.any),
  subjectType: PropTypes.string,
  appointment: PropTypes.objectOf(PropTypes.any),
};

SubjectSummaryTitle.defaultProps = {
  appointment: {},
  subjectType: '',
  currentBookingRequestSubject: {},
};
