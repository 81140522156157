import React from 'react';

import { useDisclosure } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const helpIconContext = React.createContext(null);

export const HelpIconProvider = ({ children }) => {
  const {
    isOpen: isPreviewModalOpen,
    onOpen: onOpenPreviewModal,
    onClose: onClosePreviewModal,
  } = useDisclosure();

  return (
    <helpIconContext.Provider
      value={{
        isPreviewModalOpen,
        onOpenPreviewModal,
        onClosePreviewModal,
      }}
    >
      {children}
    </helpIconContext.Provider>
  );
};
HelpIconProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

HelpIconProvider.defaultProps = {
  children: null,
};
