import React from 'react';

import { Flex, Spacer, Stack, Text } from '@chakra-ui/react';
import { FormizStep, useForm } from '@formiz/core';
import { isNotEmptyString } from '@formiz/validations';

import { Alert, FieldInput, FieldRadioGroup } from '@/components';
import {
  BOOKING_REQUEST_SUBJECTS,
  JUSTICE_REQUEST_CATEGORY,
} from '@/constants/bookingRequest';
import { FORM_STEPS } from '@/constants/steps';
import { AppointmentHeading } from '@/pages/BookingRequest/_partials/AppointmentHeading';
import { HelpIconButton } from '@/pages/BookingRequest/_partials/HelpIconButton';
import { RadioItem } from '@/pages/BookingRequest/_partials/RadioItem';

import { JusticeStepRadioGroup } from './_partial/JusticeRadioGroup';

export const Justice = () => {
  const { values } = useForm({
    subscribe: {
      form: false,
      fields: ['appointment.justice.type', 'appointment.justice'],
    },
  });
  const { appointment } = values || { justice: null };

  return (
    <>
      <FormizStep
        name={FORM_STEPS.JUSTICE_SUBJECT.TYPE.name}
        order={FORM_STEPS.JUSTICE_SUBJECT.TYPE.order}
      >
        <Flex>
          <AppointmentHeading
            title="Type de rendez-vous justice"
            helper="Avez-vous une convocation, y’a-t-il une procédure en cours ?"
          />
          <Spacer />
          <HelpIconButton />
        </Flex>
        <FieldRadioGroup
          id="appointment-meeting-type"
          name={`appointment.${BOOKING_REQUEST_SUBJECTS.JUSTICE.id}.type`}
          required="Vous devez choisir un type de rendez-vous"
          mt={4}
          validations={[
            {
              rule: (val) => val !== JUSTICE_REQUEST_CATEGORY.YES.id,
              message: '',
            },
          ]}
        >
          <Stack>
            <RadioItem autoHeight {...JUSTICE_REQUEST_CATEGORY.YES} />
            <RadioItem autoHeight {...JUSTICE_REQUEST_CATEGORY.NO} />
          </Stack>
          {appointment?.[BOOKING_REQUEST_SUBJECTS.JUSTICE.id]?.type ===
            JUSTICE_REQUEST_CATEGORY.YES.id && (
            <Alert
              status="warning"
              mt={4}
              description={
                <>
                  <Text>
                    Attention, dans le cadre d&apos;une procédure,{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      c&apos;est à la justice de convoquer l&apos;interprète.
                    </span>
                  </Text>
                  <Text mt={2}>
                    Signalez au greffe du service dans lequel vous êtes convoqué
                    que vous êtes sourd et que vous avez besoin d&apos;un
                    interprète.
                  </Text>
                </>
              }
            />
          )}
        </FieldRadioGroup>
      </FormizStep>
      <FormizStep
        name={FORM_STEPS.JUSTICE_SUBJECT.MEETING.name}
        order={FORM_STEPS.JUSTICE_SUBJECT.MEETING.order}
      >
        <JusticeStepRadioGroup />
      </FormizStep>
      <FormizStep
        name={FORM_STEPS.JUSTICE_SUBJECT.INFORMATION.name}
        order={FORM_STEPS.JUSTICE_SUBJECT.INFORMATION.order}
      >
        <Flex>
          <Spacer />
          <HelpIconButton />
        </Flex>
        <FieldInput
          name={`appointment.${BOOKING_REQUEST_SUBJECTS.JUSTICE.id}.professionalType`}
          label="Vous avez rendez-vous avec :"
          helper="Avocat, Notaire, Huissier, …"
          required="Vous devez fournir le type de professionnel"
          validations={[
            {
              rule: isNotEmptyString(),
              message: 'Vous devez fournir le type de professionnel',
            },
          ]}
          mt={4}
        />
        <FieldInput
          name={`appointment.${BOOKING_REQUEST_SUBJECTS.JUSTICE.id}.serviceIndication`}
          label="Informations complémentaires"
          helper="Si vous avez des indications supplémentaires"
          mt={4}
        />
      </FormizStep>
    </>
  );
};
