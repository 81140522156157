import React from 'react';

import { Flex, Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const Header = ({ position, ...props }) => (
  <Box
    w="100%"
    backgroundColor="white"
    zIndex="2"
    position={position}
    top="0"
    left="0"
    right="0"
  >
    <Flex
      position="relative"
      height={16}
      boxShadow="md"
      alignItems="center"
      flexDirection="column"
      {...props}
    />
  </Box>
);

Header.propTypes = {
  position: PropTypes.string,
};

Header.defaultProps = {
  position: 'inherit',
};
