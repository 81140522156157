import React from 'react';

import { Redirect } from 'react-router-dom';

import { useLogout } from '@/services/login';

export const Logout = () => {
  useLogout();

  return <Redirect to="/" />;
};
