import Axios from 'axios';
import { useQuery } from 'react-query';

export const useAgencies = ({ params, ...config } = {}) =>
  useQuery(
    ['agencies', params],
    () =>
      Axios.get('/agencies', {
        params,
      }),
    { retry: 0, ...config }
  );
