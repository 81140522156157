import React from 'react';

import { Flex } from '@chakra-ui/react';

export const HeaderLeftAction = (props) => (
  <Flex
    h="100%"
    position="absolute"
    alignItems="center"
    left={2}
    zIndex="3"
    {...props}
  />
);
