import React from 'react';

import { forwardRef, IconButton } from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';

export const DeleteButton = forwardRef(({ ...rest }, ref) => {
  return (
    <IconButton
      size="lg"
      ref={ref}
      colorScheme="brandSecondary"
      icon={<FaTrash />}
      borderRadius="spot.variant1"
      {...rest}
    />
  );
});
