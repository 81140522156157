import React from 'react';

import { Box, Center, Spinner, useToast } from '@chakra-ui/react';
import { FormizStep, useForm } from '@formiz/core';

import { FieldCheckboxGroup, FieldInput } from '@/components';
import {
  BOOKING_REQUEST_STORAGE_KEY,
  INVOICE_REQUEST_STEPS,
} from '@/constants/invoiceRequest';
import { useAgencies } from '@/services/agencies';
import { convertToSearchDto } from '@/services/bookingRequest';
import { useLocalStorage } from '@/services/localStorage';

import { InvoiceRequestHeading } from '../_partials/InvoiceRequestHeading';
import { CardCheckboxAll } from './_partials/CardCheckboxAll';
import { CardCheckboxItem } from './_partials/CardCheckboxItem';

export const InvoiceRequestAgencies = () => {
  const form = useForm();
  const { currentStep, values } = form;
  const { agencies } = values;
  const toast = useToast();
  const [bookingRequest] = useLocalStorage(BOOKING_REQUEST_STORAGE_KEY, '');

  const { data: agenciesFromAPI, isLoading } = useAgencies({
    params: convertToSearchDto(bookingRequest),
    enabled:
      bookingRequest &&
      currentStep?.name === INVOICE_REQUEST_STEPS.AGENCIES.name,
    onError: () => {
      toast({
        title:
          'Une erreur est survenue lors de la recherche des agences disponibles',
        status: 'error',
      });
    },
  });

  const isAllAgenciesClosed = agenciesFromAPI?.every(
    (agency) => agency?.isOpen === false
  );

  const openedAgencies = agenciesFromAPI?.filter((agency) => !!agency?.isOpen);

  return (
    <FormizStep
      name={INVOICE_REQUEST_STEPS.AGENCIES.name}
      order={INVOICE_REQUEST_STEPS.AGENCIES.order}
    >
      <InvoiceRequestHeading
        title="Sélection"
        helper="Choisissez une ou plusieurs agences pour envoyer votre demande et recevoir un devis."
        mb={4}
      />
      {isLoading ? (
        <Center>
          <Spinner
            thickness="4px"
            emptyColor="gray.200"
            color="brandSecondary.600"
            size="xl"
          />
        </Center>
      ) : (
        <Box>
          {!!agenciesFromAPI && openedAgencies?.length > 1 && (
            <CardCheckboxAll agencies={agenciesFromAPI} />
          )}
          <FieldCheckboxGroup name="agencies" mb={0} h="auto">
            {(agenciesFromAPI || []).map((agency) => (
              <CardCheckboxItem
                key={agency.id?.toString()}
                id={agency.id?.toString()}
                name={agency.name}
                lowestPrice={Number(agency.lowestPrice)}
                highestPrice={Number(agency.highestPrice)}
                isDisabled={!agency.isOpen}
                closePhrase={agency.closePhrase}
                {...(agency.address || {})}
              />
            ))}
          </FieldCheckboxGroup>

          {!!agenciesFromAPI && openedAgencies?.length > 3 && (
            <CardCheckboxAll agencies={agenciesFromAPI} />
          )}
          <FieldInput
            name="agencies-hidden-validation"
            type="hidden"
            validations={[
              {
                rule: () => agencies?.length > 0,
                message: isAllAgenciesClosed
                  ? 'Toutes les agences de cette zone géographique sont fermées pour cette date, veuillez tenter votre demande à une autre date.'
                  : 'Vous devez sélectionner une ou plusieurs agences',
                deps: [agencies],
              },
            ]}
          />
        </Box>
      )}
    </FormizStep>
  );
};
