/* eslint-disable max-len */
import React from 'react';

import { Box } from '@chakra-ui/react';

export const Help = (props) => (
  <Box {...props}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.2247 19.0247C25.0075 15.8002 22.8992 14.188 22.8992 14.188C21.8947 13.5299 21.1803 13.6159 20.756 14.4454C20.6418 15.068 20.9821 15.68 21.7765 16.2805C23.9735 18.2148 25.0718 19.1821 25.0718 19.1821C25.4573 19.7144 25.1681 20.1056 24.2039 20.3563C17.0087 20.9897 13.411 21.306 13.411 21.306C12.1536 21.7915 12.2624 22.7937 13.2947 23.1369C18.0971 23.0156 20.4982 22.9551 20.4982 22.9551C20.9449 23.1631 20.9113 23.4254 20.3994 23.7415C15.4304 24.7372 12.9463 25.2353 12.9463 25.2353C11.8375 25.8393 12.1245 26.8475 13.4107 26.9246C18.129 26.2568 20.4882 25.9233 20.4882 25.9233C21.0696 26.0787 21.1451 26.4052 20.7147 26.9029C16.7588 28.1514 14.7807 28.7755 14.7807 28.7755C14.465 29.1073 14.3419 29.4032 14.4109 29.6621C14.5427 30.1237 14.8199 30.3442 15.242 30.3232C19.3705 29.2619 21.4348 28.731 21.4348 28.731C22.0509 28.8084 22.1754 29.0887 21.8087 29.5723C19.1027 30.8101 17.75 31.4292 17.75 31.4292C17.3676 31.7285 17.2692 32.0152 17.4558 32.2894C17.6905 32.5819 18.0525 32.6542 18.5422 32.506C24.8039 30.5385 28.5039 29.1459 29.642 28.3273C31.5977 26.509 32.2704 24.3979 31.6609 21.9948C31.0918 20.938 30.2797 19.9479 29.2247 19.0247L29.2247 19.0247Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7729 10.202C27.0846 12.5505 27.2789 14.6547 27.3555 16.5151C27.3555 16.5151 26.306 15.7148 24.207 14.1144C24.6312 11.1741 24.8436 9.70449 24.8436 9.70449C25.4633 8.79675 26.1065 8.9627 26.7729 10.202L26.7729 10.202Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1578 13.7882C20.8023 12.8701 21.3061 12.9389 21.3061 12.9389C21.3061 12.9389 19.25 11.3738 15.3599 8.42594C14.0144 7.91975 13.3189 9.38337 14.1641 10.2126C17.3872 13.2818 18.9987 14.8161 18.9987 14.8161C18.942 15.4153 18.6961 15.6274 18.2613 15.4526C14.03 12.1506 11.9146 10.4998 11.9146 10.4998C10.5993 10.0226 9.90394 11.498 10.7704 12.5477C15.0912 15.8795 17.2513 17.5454 17.2513 17.5454C17.1848 17.8681 16.9637 18.0613 16.5877 18.1238C12.8412 16.009 10.9681 14.9516 10.9681 14.9516C9.69763 14.7605 9.59733 15.8854 10.1633 16.5911C13.8693 18.9532 15.7225 20.1345 15.7225 20.1345C20.2062 19.6776 22.4477 19.4489 22.4477 19.4489C23.185 19.4117 23.116 18.8858 22.9452 18.6876C21.0528 17.0594 20.1028 16.0131 20.1028 16.0131C19.6249 15.3118 19.5988 14.4341 20.1578 13.7883L20.1578 13.7882Z"
        fill="white"
      />
    </svg>
  </Box>
);
