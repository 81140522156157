/* eslint-disable max-len */
import React from 'react';

import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const Logo = ({ isFull, ...otherProps }) => (
  <Box {...otherProps}>
    {isFull ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 519.65 130.83"
        width="100%"
        height="100%"
      >
        <path
          fill="#092728"
          d="M189.67,62.7a15.11,15.11,0,0,1,9.39-3c8.77,0,14.88,6.1,14.88,14.88v6.1c0,8.78-6.11,14.88-14.88,14.88a15,15,0,0,1-9.39-3v3h-5.49v-48h5.49Zm2.53,5a9.65,9.65,0,0,0-2.53,6.85v6.1a8.73,8.73,0,0,0,8,9.39,7.84,7.84,0,0,0,1.37,0,9.45,9.45,0,0,0,6.85-2.49,9.27,9.27,0,0,0,2.54-6.86V74.55a9.3,9.3,0,0,0-2.54-6.86,9.51,9.51,0,0,0-6.85-2.53A9.71,9.71,0,0,0,192.2,67.7Z"
        />
        <path
          fill="#092728"
          d="M253.42,63.73a14.06,14.06,0,0,1,3.5,4.8,14.72,14.72,0,0,1,1.16,6v21h-5.51V92.18a17.64,17.64,0,0,1-10.87,3.37,15.54,15.54,0,0,1-9.26-2.61,9.29,9.29,0,0,1,0-15.42,15.58,15.58,0,0,1,9.26-2.68h10.87v-.29a8.69,8.69,0,0,0-2.81-6.72,11.77,11.77,0,0,0-8.09-2.67,12.07,12.07,0,0,0-7.75,2.4l-3.49-4.26a17.48,17.48,0,0,1,11.24-3.63C246.43,59.68,250.47,61.05,253.42,63.73ZM241.7,80.32A10.68,10.68,0,0,0,235.6,82a3.86,3.86,0,0,0,0,6.52,10.74,10.74,0,0,0,6.1,1.57,11.77,11.77,0,0,0,8.09-2.67,8.7,8.7,0,0,0,2.78-6.72v-.34Z"
        />
        <path
          fill="#b58f37"
          d="M348.87,91.89c-3.57,0-6.85-2.58-9.24-7.26l-4.52-8.85c-1.62-3.17-3.67-4.91-5.78-4.91s-4.15,1.74-5.77,4.91l-4.41,8.64c-2.4,4.68-5.67,7.26-9.24,7.26s-6.84-2.58-9.24-7.26L296.37,76c-1.62-3.17-3.67-4.91-5.78-4.91s-4.15,1.74-5.77,4.91l-7.39,14.47a1.86,1.86,0,0,1-2.4,1,1.76,1.76,0,0,1-.42-.24,2.87,2.87,0,0,1-.64-3.45l7.39-14.47c2.39-4.68,5.67-7.26,9.23-7.26s6.85,2.58,9.24,7.26l4.3,8.43c1.62,3.16,3.67,4.91,5.78,4.91s4.16-1.75,5.78-4.91l4.41-8.64c2.39-4.68,5.67-7.26,9.23-7.26s6.85,2.58,9.24,7.26L343.09,82c1.62,3.16,3.67,4.91,5.78,4.91S353,85.14,354.64,82l4.57-9c2.4-4.68,5.67-7.26,9.24-7.26s6.84,2.58,9.24,7.26l7.55,14.79a2.84,2.84,0,0,1-.65,3.45,1.84,1.84,0,0,1-2.81-.8l-7.55-14.78c-1.66-3.09-3.66-4.88-5.78-4.88s-4.16,1.75-5.78,4.92l-4.57,9C355.71,89.31,352.43,91.89,348.87,91.89Z"
        />
        <path
          fill="#092728"
          d="M427.19,63.73a14.06,14.06,0,0,1,3.5,4.8,14.89,14.89,0,0,1,1.17,6v21h-5.49V92.18a17.6,17.6,0,0,1-10.9,3.37,15.56,15.56,0,0,1-9.26-2.61,9.29,9.29,0,0,1,0-15.42,15.58,15.58,0,0,1,9.26-2.68h10.9v-.29a8.71,8.71,0,0,0-2.8-6.71,11.77,11.77,0,0,0-8.09-2.67,12.07,12.07,0,0,0-7.75,2.4l-3.49-4.26a17.53,17.53,0,0,1,11.24-3.63C420.2,59.68,424.25,61.05,427.19,63.73ZM415.47,80.32a10.68,10.68,0,0,0-6.1,1.64,3.86,3.86,0,0,0,0,6.52,10.74,10.74,0,0,0,6.1,1.57,11.73,11.73,0,0,0,8.12-2.67,8.67,8.67,0,0,0,2.81-6.72v-.34Z"
        />
        <path
          fill="#092728"
          d="M468.57,59.68h6.86l-11.25,15,11.28,20.87h-6.24l-8.65-16-6.1,8.09v7.91H449v-48h5.49V78.47Z"
        />
        <path
          fill="#092728"
          d="M516.18,74.55v6.1c0,8.78-6.11,14.88-14.88,14.88s-14.88-6.1-14.88-14.88v-6.1c0-8.78,6.1-14.88,14.88-14.88S516.18,65.78,516.18,74.55Zm-21.74-6.86a9.65,9.65,0,0,0-2.53,6.86v6.1a8.73,8.73,0,0,0,8,9.39,7.84,7.84,0,0,0,1.37,0,9.48,9.48,0,0,0,6.86-2.49,9.35,9.35,0,0,0,2.53-6.86V74.55a9.37,9.37,0,0,0-2.53-6.86,9.55,9.55,0,0,0-6.86-2.53,9.71,9.71,0,0,0-6.86,2.54Z"
        />
        <path
          fill="#b58f37"
          d="M112,26.88C89-.9,48.38-7.38,21.93,26.88-4.26,60.8-6.15,99.6,21.93,117c30.16,18.68,72.39,7.54,89.29-7.54C137.47,86,134.46,54,112,26.88ZM29.43,78.21a3.48,3.48,0,1,1,3.48-3.48,3.48,3.48,0,0,1-3.48,3.48Zm35.4,0a3.48,3.48,0,1,1,3.48-3.48,3.48,3.48,0,0,1-3.48,3.48Zm49.74-.66a2.08,2.08,0,0,1-1.27.44,1.38,1.38,0,0,1-.45,0,2.36,2.36,0,0,1-1.56-1.28l-6.8-13.29c-1.36-2.68-3-4.15-4.74-4.15S96.36,60.69,95,63.37l-4.12,8c-2.24,4.38-5.34,6.8-8.75,6.8h0c-3.4,0-6.51-2.42-8.75-6.8l-4.06-8c-1.37-2.67-3.06-4.15-4.75-4.15s-3.39,1.48-4.75,4.15l-4,7.77c-2.24,4.38-5.35,6.8-8.75,6.8h0c-3.4,0-6.51-2.42-8.75-6.8l-3.87-7.58c-1.37-2.67-3.06-4.15-4.75-4.15S26.31,60.89,25,63.56l-6.65,13a2.34,2.34,0,0,1-1.56,1.28,1.38,1.38,0,0,1-.45,0,2,2,0,0,1-1.22-.34,3,3,0,0,1-.72-3.73l6.64-13c2.24-4.39,5.35-6.8,8.76-6.8h0c3.4,0,6.51,2.41,8.75,6.8l3.87,7.58c1.36,2.65,3.05,4.15,4.75,4.15s3.38-1.47,4.75-4.14l4-7.77c2.24-4.39,5.34-6.8,8.75-6.8h0c3.4,0,6.51,2.41,8.75,6.8l4.06,8c1.37,2.67,3.06,4.14,4.75,4.14s3.39-1.54,4.72-4.23l4.11-8c2.24-4.38,5.35-6.8,8.76-6.8h0c3.4,0,6.51,2.42,8.75,6.8l6.79,13.3A3,3,0,0,1,114.57,77.55Z"
        />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 133.31 130.83"
        width="100%"
        height="100%"
      >
        <path
          fill="#b58f36"
          d="M112,26.88C89-.9,48.38-7.38,21.93,26.88-4.26,60.8-6.15,99.6,21.93,117c30.16,18.68,72.39,7.54,89.29-7.54C137.47,86,134.46,54,112,26.88ZM29.43,78.21a3.48,3.48,0,1,1,3.48-3.48,3.48,3.48,0,0,1-3.48,3.48Zm35.4,0a3.48,3.48,0,1,1,3.48-3.48,3.48,3.48,0,0,1-3.48,3.48Zm49.74-.66a2.08,2.08,0,0,1-1.27.44,1.38,1.38,0,0,1-.45,0,2.36,2.36,0,0,1-1.56-1.28l-6.8-13.29c-1.36-2.68-3-4.15-4.74-4.15S96.36,60.69,95,63.37l-4.12,8c-2.24,4.38-5.34,6.8-8.75,6.8h0c-3.4,0-6.51-2.42-8.75-6.8l-4.06-8c-1.37-2.67-3.06-4.15-4.75-4.15s-3.39,1.48-4.75,4.15l-4,7.77c-2.24,4.38-5.35,6.8-8.75,6.8h0c-3.4,0-6.51-2.42-8.75-6.8l-3.87-7.58c-1.37-2.67-3.06-4.15-4.75-4.15S26.31,60.89,25,63.56l-6.65,13a2.34,2.34,0,0,1-1.56,1.28,1.38,1.38,0,0,1-.45,0,2,2,0,0,1-1.22-.34,3,3,0,0,1-.72-3.73l6.64-13c2.24-4.39,5.35-6.8,8.76-6.8h0c3.4,0,6.51,2.41,8.75,6.8l3.87,7.58c1.36,2.65,3.05,4.15,4.75,4.15s3.38-1.47,4.75-4.14l4-7.77c2.24-4.39,5.34-6.8,8.75-6.8h0c3.4,0,6.51,2.41,8.75,6.8l4.06,8c1.37,2.67,3.06,4.14,4.75,4.14s3.39-1.54,4.72-4.23l4.11-8c2.24-4.38,5.35-6.8,8.76-6.8h0c3.4,0,6.51,2.42,8.75,6.8l6.79,13.3A3,3,0,0,1,114.57,77.55Z"
        />
      </svg>
    )}
  </Box>
);

Logo.propTypes = {
  isFull: PropTypes.bool,
};

Logo.defaultProps = {
  isFull: false,
};
