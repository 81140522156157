import React, { useEffect, useState } from 'react';

import {
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  useDisclosure,
  PopoverBody,
  Box,
  Text,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import PropTypes from 'prop-types';
import { FiX, FiMapPin } from 'react-icons/fi';

import { FormGroup } from '@/components/FormGroup';
import { Icon } from '@/components/Icon';

export const FieldSelectAddress = (props) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = React.useRef(null);
  const {
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    errorMessage,
    value,
  } = useField(props);

  const {
    label,
    helper,
    placeholder,
    isDisabled,
    required,
    formatValue,
    keepValue,
    asyncValidations,
    inputProps,
    options,
    inputValue,
    onInputChange,
    isOptionLoading,
    noOptionsMessage,
    ...otherProps
  } = props;
  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  const formGroupProps = {
    htmlFor: id,
    label,
    helper,
    errorMessage,
    showError,
    isRequired: !!required,
    isDisabled,
    ...otherProps,
  };

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const handleBlur = () => {
    if (isTouched) return;
    setIsTouched(true);
  };

  const filtredOptions = options && options.length ? options : [];

  return (
    <FormGroup {...formGroupProps}>
      <Popover
        isOpen={isOpen}
        initialFocusRef={firstFieldRef}
        onOpen={onOpen}
        onClose={onClose}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <InputGroup onBlur={handleBlur}>
            <Input
              placeholder={value?.value ?? placeholder ?? ''}
              background="gray.50"
              id={id}
              ref={firstFieldRef}
              type="text"
              value={inputValue}
              onChange={({ target }) => onInputChange(target.value)}
            />
            <InputRightElement>
              {inputValue && (
                <Icon
                  icon={FiX}
                  onClick={(e) => {
                    e.stopPropagation();
                    onInputChange('');
                    onClose();
                  }}
                  iconProps={{ fontSize: 'lg' }}
                  __css={{ cursor: 'pointer' }}
                />
              )}
            </InputRightElement>
            <InputLeftElement>
              <Icon icon={FiMapPin} iconProps={{ fontSize: 'lg' }} />
            </InputLeftElement>
          </InputGroup>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody overflow="auto" maxH={60}>
            {!isOptionLoading && !filtredOptions.length && (
              <Text textAlign="center" my={2}>
                {noOptionsMessage}
              </Text>
            )}
            {!isOptionLoading &&
              filtredOptions.map((val) => {
                return (
                  <Box
                    key={val?.value}
                    p={2}
                    _hover={{ backgroundColor: 'gray.100' }}
                    onClick={() => {
                      onInputChange(val.value);
                      setValue(val);
                      onClose();
                    }}
                  >
                    {val.value}
                  </Box>
                );
              })}
            {isOptionLoading && (
              <Center>
                <Spinner color="red.500" size="lg" />
              </Center>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </FormGroup>
  );
};

FieldSelectAddress.propTypes = {
  label: PropTypes.node,
  helper: PropTypes.node,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  inputValue: PropTypes.string,
  onInputChange: PropTypes.func,
  isOptionLoading: PropTypes.bool,
  noOptionsMessage: PropTypes.string,
  ...fieldPropTypes,
};

FieldSelectAddress.defaultProps = {
  label: '',
  helper: '',
  placeholder: '',
  isDisabled: false,
  inputValue: '',
  onInputChange: () => {},
  isOptionLoading: false,
  noOptionsMessage: '',
  ...fieldDefaultProps,
};
