import React from 'react';

import { Box, Heading, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const InvoiceRequestHeading = ({ title, helper, ...otherProps }) => (
  <Box {...otherProps}>
    <Heading as="h2" size="lg" pt={2}>
      {title}
    </Heading>
    {helper && (
      <Text as="h4" size="sm" mt={1}>
        {helper}
      </Text>
    )}
  </Box>
);

InvoiceRequestHeading.propTypes = {
  title: PropTypes.node,
  helper: PropTypes.node,
};

InvoiceRequestHeading.defaultProps = {
  title: '',
  helper: '',
};
