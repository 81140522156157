import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { FaMapMarkerAlt } from 'react-icons/fa';

import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
  Icon,
} from '@/components';
import {
  BOOKING_REQUEST,
  BOOKING_REQUEST_CATEGORY,
  BOOKING_REQUEST_SUBJECTS,
} from '@/constants/bookingRequest';
import { TIME_FORMAT } from '@/constants/date';
import { APPOINTMENT_CARD_ICONS } from '@/constants/meeting';
import { getAppointmentSubjectTextContent } from '@/services/utils';
import { formatAddress, formatFullName } from '@/utils/textUtils';

export const AppointmentInformation = ({ appointment, ...otherProps }) => {
  const {
    suject,
    startDate,
    endDate,
    appointmentAddress,
    firstName,
    lastName,
  } = appointment;
  const type =
    suject === BOOKING_REQUEST.other
      ? BOOKING_REQUEST_SUBJECTS.OTHER.id
      : suject;
  const subject = appointment[type];
  const address = formatAddress(appointmentAddress);
  const appointmentUserFullname = formatFullName({ firstName, lastName });

  const startDateObj = dayjs(startDate).isValid() ? dayjs(startDate) : null;
  const endDateObj = dayjs(endDate).isValid() ? dayjs(endDate) : null;
  const dateToDisplay = startDateObj
    ? startDateObj.format('DD MMMM YYYY')
    : 'N/A';
  const startTimeToDisplay = startDateObj
    ? startDateObj.format(TIME_FORMAT)
    : 'N/A';
  const endTimeToDisplay = endDateObj ? endDateObj.format(TIME_FORMAT) : 'N/A';

  const subjectName =
    BOOKING_REQUEST_SUBJECTS[BOOKING_REQUEST[type]]?.name || '';

  const iconProps =
    APPOINTMENT_CARD_ICONS[type] || APPOINTMENT_CARD_ICONS.DEFAULT;

  const appointmentSubjectContent = getAppointmentSubjectTextContent(subject);

  return (
    <>
      <Card w="100%" borderWidth="0" {...otherProps}>
        <CardContent>
          <Flex>
            <Flex
              justifyContent="center"
              alignItems="center"
              w="3rem"
              h="3rem"
              overflow="hidden"
              bg="brandSecondary.100"
              borderRadius="spot.variant1"
            >
              <Icon mt="1" color="brandSecondary.700" {...iconProps} />
            </Flex>
            <Box ml="3">
              <CardTitle size="lg">{dateToDisplay}</CardTitle>
              <CardTitle as="h3" size="md">
                {`${startTimeToDisplay} à ${endTimeToDisplay}`}
              </CardTitle>
            </Box>
          </Flex>
          <CardDescription h="auto">
            <Text mt={4}>
              <Icon icon={FaMapMarkerAlt} color="brandSecondary.700" mr="2" />
              {address}
            </Text>
            <Box mt={6}>
              <Text as="span" fontWeight="bold">
                {appointment?.siret && appointment?.companyName
                  ? BOOKING_REQUEST_CATEGORY.PROFESSIONAL.name
                  : BOOKING_REQUEST_CATEGORY.PERSONAL.name}
              </Text>
              <Box fontWeight="bold">
                {`${subjectName ? ` ${subjectName}` : ''}${
                  appointmentSubjectContent[type] ? ' -' : ''
                }`}
                <Text as="span" fontWeight="bold">
                  {` ${appointmentSubjectContent[type] || ''}`}
                </Text>
              </Box>
              <Box mt={6}>
                <Text as="span">Ce rendez-vous est pour:</Text>
                <Text as="span" fontWeight="bold">
                  {` ${appointmentUserFullname}`}
                </Text>
              </Box>
              <Text fontWeight="bold">
                {`${appointment?.phoneNumber || ''}${
                  appointment?.phoneNumber && appointment?.email ? ' - ' : ''
                }${appointment?.email || ''}`}
              </Text>
            </Box>
          </CardDescription>
        </CardContent>
      </Card>
    </>
  );
};

AppointmentInformation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  appointment: PropTypes.object,
};

AppointmentInformation.defaultProps = {
  appointment: null,
};
