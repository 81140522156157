import React from 'react';

import { Stack } from '@chakra-ui/react';

import { FieldRadioGroup } from '@/components';
import { JUSTICE_MEETING_REQUEST_CATEGORY } from '@/constants/bookingRequest';
import { AppointmentHeading } from '@/pages/BookingRequest/_partials/AppointmentHeading';
import { RadioItem } from '@/pages/BookingRequest/_partials/RadioItem';

export const JusticeStepRadioGroup = () => {
  return (
    <>
      <AppointmentHeading
        title="Type de rendez-vous Justice"
        helper="Quel est le format du rendez-vous ?"
      />
      <FieldRadioGroup
        id="appointment-meeting-type"
        name="appointment.justice.category"
        required="Vous devez choisir un type de rendez-vous"
        mt={4}
      >
        <Stack>
          <RadioItem
            {...JUSTICE_MEETING_REQUEST_CATEGORY.MEETING_FACE_TO_FACE}
          />
          <RadioItem {...JUSTICE_MEETING_REQUEST_CATEGORY.MEETING} />
        </Stack>
      </FieldRadioGroup>
    </>
  );
};
