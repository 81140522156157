import React, { useEffect, useState, createContext } from 'react';

import { RadioGroup } from '@chakra-ui/react';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import PropTypes from 'prop-types';

import { FormGroup } from '../FormGroup';

export const FieldRadioGroupContext = createContext(null);

export const FieldRadioGroup = (props) => {
  const {
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    errorMessage,
  } = useField({ ...props, debounce: 0 });

  const {
    colorScheme,
    label,
    helper,
    isDisabled,
    required,
    formatValue,
    keepValue,
    asyncValidations,
    children,
    ...otherProps
  } = props;

  const [isTouched, setIsTouched] = useState(false);
  const [activeRadioValue, setActiveRadioValue] = useState(null);
  const showError = !isValid && (isTouched || isSubmitted);

  const formGroupProps = {
    htmlFor: id,
    label,
    helper,
    errorMessage,
    showError,
    isRequired: !!required,
    isDisabled,
    ...otherProps,
  };

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const handleChange = (updatedValue) => {
    if (!isTouched) {
      setIsTouched(true);
    }

    setValue(updatedValue);
  };

  useEffect(() => {
    setActiveRadioValue(value);
  }, [value]);

  return (
    <FieldRadioGroupContext.Provider value={{ activeRadioValue }}>
      <FormGroup {...formGroupProps}>
        <RadioGroup
          colorScheme={colorScheme}
          value={value || []}
          onChange={handleChange}
          aria-invalid={showError}
          aria-required={!!required}
          aria-describedby={showError ? `${id}-error` : null}
        >
          {children}
        </RadioGroup>
      </FormGroup>
    </FieldRadioGroupContext.Provider>
  );
};

FieldRadioGroup.propTypes = {
  colorScheme: PropTypes.string,
  label: PropTypes.string,
  helper: PropTypes.string,
  isDisabled: PropTypes.bool,
  children: PropTypes.node,
  ...fieldPropTypes,
};

FieldRadioGroup.defaultProps = {
  colorScheme: 'brandSecondary',
  label: '',
  helper: '',
  isDisabled: false,
  children: null,
  ...fieldDefaultProps,
};
