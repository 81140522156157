import React, { useState, useEffect } from 'react';

import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  useBreakpointValue,
  useDisclosure,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import { FaRegCalendarPlus } from 'react-icons/fa';
import { useQueryCache } from 'react-query';

import { Help } from '@/assets/icons/Help';
import { Card, Icon, Link, PreviewModal } from '@/components';
import { Page, AppHeader, AppFooter } from '@/layout';

import { IllustrationWomen } from '../_partials/IllustrationWomen';
import { AppointmentsList } from './_partials/AppointmentsList';

export const Agenda = () => {
  const [isHistory, setIsHistory] = useState(0);
  const queryCache = useQueryCache();
  const isWide = useBreakpointValue({ base: false, md: true });

  useEffect(() => {
    queryCache.clear();
  }, [queryCache]);

  const handleTabsChange = (index) => {
    setIsHistory(index);
    queryCache.clear();
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <AppHeader title="Agenda" />
      <Page
        px="0"
        mb={isWide ? 0 : 16}
        overflowX="hidden"
        background="url('/images/wave.svg') top center no-repeat"
        backgroundColor="gray.100"
        backgroundSize="contain"
        position="relative"
        overflowY="scroll"
        height={{ base: 'calc(100vh - 8rem)', md: 'calc(100vh - 4rem)' }}
      >
        <IllustrationWomen illustration="/images/greenWomen.svg" />
        <Box position="relative" height="auto">
          <Flex
            mt="5"
            flexGrow="1"
            flexDirection="column"
            w="100%"
            maxWidth="45em"
            mx="auto"
          >
            <Flex px="6" justifyContent="space-between" alignItems="center">
              <Heading as="h2" size="lg">
                Rendez-vous
              </Heading>
              <Flex>
                {isWide ? (
                  <Button
                    as={Link}
                    to="/booking-request"
                    borderRadius="full"
                    colorScheme="brandSecondary"
                    variant="inverse"
                    w="100%"
                    py="6"
                    px="10"
                    style={{
                      textDecoration: 'none',
                    }}
                    mr={4}
                  >
                    <Icon mr="2" icon={FaRegCalendarPlus} />
                    Nouveau rendez-vous
                  </Button>
                ) : null}
                <IconButton
                  size="lg"
                  colorScheme="brandSecondary"
                  icon={<Icon icon={Help} fontSize="3rem" />}
                  borderRadius="spot.variant1"
                  onClick={onOpen}
                />
              </Flex>
            </Flex>

            <Box
              position="relative"
              px="2"
              pt={{ base: 16, md: 0 }}
              overflow="hidden"
            >
              <>
                <Box
                  as="img"
                  src="/images/greenWomen.svg"
                  alt=""
                  position="absolute"
                  top={{ base: '-4vw', md: '3vw' }}
                  {...(!isWide
                    ? {
                        left: '50%',
                        transform: 'translate(-50%, 0%)',
                      }
                    : {
                        right: '-13vw',
                      })}
                  display={{ base: 'block', md: 'none' }}
                />
                <Box
                  d={{ base: 'none', md: 'block' }}
                  as="img"
                  src="/images/grid.svg"
                  alt=""
                  position="absolute"
                  top="8vw"
                  left="-13vw"
                />
              </>

              {!isWide && (
                <Button
                  as={Link}
                  to="/booking-request"
                  mt="8"
                  borderRadius="full"
                  colorScheme="brandSecondary"
                  variant="inverse"
                  w="100%"
                  py="6"
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <Icon mr="2" icon={FaRegCalendarPlus} />
                  Nouveau rendez-vous
                </Button>
              )}
            </Box>
            <Tabs
              variant="soft-rounded"
              colorScheme="brandSecondary"
              mt="4"
              onChange={handleTabsChange}
              isLazy={false}
              zIndex={5}
            >
              <TabList mb={{ base: -6, md: 0 }} mx={{ base: 0, md: 6 }}>
                {isWide ? (
                  <>
                    <Tab>Prochains rendez-vous</Tab>
                    <Tab>Anciens rendez-vous</Tab>
                  </>
                ) : (
                  <Card
                    width="100%"
                    mx="6"
                    autoHeight
                    borderWidth="0"
                    justifyContent="center"
                  >
                    <Box alignSelf="center" mr="2">
                      <Tab>Prochains rendez-vous</Tab>
                    </Box>
                    <Box alignSelf="center">
                      <Tab>Anciens rendez-vous</Tab>
                    </Box>
                  </Card>
                )}
              </TabList>
              <TabPanels>
                <TabPanel>
                  <AppointmentsList
                    isHistory={isHistory}
                    emptyStateMessage="vous n'avez aucun rendez-vous disponible"
                    emptyStateHelper="Demandez un devis pour votre nouveau
                      rendez-vous, à l’aide du bouton ci-dessous."
                  />
                </TabPanel>
                <TabPanel>
                  <AppointmentsList
                    isHistory={isHistory}
                    emptyStateMessage="vous n'avez aucun ancien rendez-vous"
                    emptyStateHelper="Demandez un devis pour votre nouveau
                      rendez-vous, à l’aide du bouton ci-dessous."
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Box>
      </Page>
      {!isWide ? <AppFooter /> : null}
      <PreviewModal
        onClose={onClose}
        isOpen={isOpen}
        url="videos/placeholder.mp4"
      />
    </>
  );
};
