import Axios from 'axios';
import { useMutation, useQuery } from 'react-query';

import { HEALTH_APPOINTMENT } from '@/constants/health';
import { INVOICE_REQUEST_BENEFICIARIES } from '@/constants/invoiceRequest';

export const useCreateInvoiceRequest = (config) =>
  useMutation((values) => Axios.post('/invoice-requests', values), config);

export const convertToInvoiceRequestDto = ({
  bookingRequest,
  currentUser,
  invoiceRequest,
}) => {
  const {
    invoiceAddress,
    agencies,
    phoneNumber,
    companyName,
    siret,
    useAsDefaultInvoiceAddress,
  } = invoiceRequest || {};

  const {
    city,
    streetAddress,
    zipCode,
    appointmentProposalDates,
    subject,
    health,
    meeting,
    event,
    justice,
    isProfessional,
    name,
    apiData,
    appointmentTypeProposalDates,
    meetingFrequency,
  } = bookingRequest || {};

  const { beneficiary } = invoiceRequest || {};
  const isForSameUser = INVOICE_REQUEST_BENEFICIARIES.YOU === beneficiary;
  const information = isForSameUser ? currentUser : invoiceRequest;

  const { firstName, lastName, email } = information || {};

  const appointmentAddress = {
    city,
    name,
    streetAddress,
    zipCode,
    apiData,
  };
  const specialistValue = {
    [HEALTH_APPOINTMENT.GENERAL_PRACTITIONER]: health?.specialist,
    [HEALTH_APPOINTMENT.MEDICAL_SPECIALIST]: health?.specialist?.value,
    [HEALTH_APPOINTMENT.WELL_BEING_AT_WORK]: health?.specialist?.value,
  };
  const healthStructure = {
    healthType: health?.type,
    specialist: specialistValue[health?.type],
  };

  const data = {
    appointmentAddress,
    appointmentProposalDates,
    subject,
    isForSameUser,
    isProfessional,
    invoiceAddress: {
      ...invoiceAddress,
      useAsDefaultInvoiceAddress: !!useAsDefaultInvoiceAddress,
    },
    agencyIds: agencies,
    phoneNumber,
    firstName,
    lastName,
    email,
    health: healthStructure,
    meeting,
    event,
    justice,
    companyName,
    siret,
    appointmentTypeProposalDates,
  };
  if (meetingFrequency) {
    data.meetingFrequency = meetingFrequency;
  }
  return data;
};

export const useInvoiceRequests = ({ params, ...config } = {}) =>
  useQuery(
    ['invoiceRequests', params],
    () =>
      Axios.get('/invoice-requests', {
        params,
      }),
    { retry: 0, ...config }
  );

export const useInvoiceRequest = ({ params, ...config } = {}) =>
  useQuery(
    [`invoiceRequests/${params?.id || ''}`, params],
    () => {
      return Axios.get(`/invoice-requests/${params?.id}`);
    },
    { retry: 0, ...config }
  );

export const useCancelInvoiceRequest = (config) =>
  useMutation(
    (params) => Axios.patch(`/invoice-requests/${params?.id}/cancel`, params),
    config
  );

export const useRefuseInvoiceRequestResponse = (config) =>
  useMutation(
    (params) =>
      Axios.patch(
        `/invoice-requests/${params?.id}/responses/${params?.responseId}/refuse`,
        params
      ),
    config
  );

export const useAcceptInvoiceRequestResponse = (config) =>
  useMutation(
    ({ invoiceRequestId, responseId, ...rest }) =>
      Axios.patch(
        `/invoice-requests/${invoiceRequestId}/responses/${responseId}/accept`,
        { ...rest }
      ),
    config
  );

export const useInvoiceRequestResponse = ({ params, ...config } = {}) =>
  useQuery(
    [`invoiceRequests/response`, params.invoiceRequestId, params.reponseId],
    () =>
      Axios.get(
        `/invoice-requests/${params?.invoiceRequestId}/responses/${params?.responseId}`,
        {
          params,
        }
      ),
    { retry: 0, ...config }
  );

export const useUploadFile = (config) =>
  useMutation(({ formData }) => {
    const source = Axios?.CancelToken.source();
    const promise = Axios.post('/upload', formData, {
      cancelToken: source?.token,
    });
    promise.cancel = () => {
      source.cancel('uploadFileCancel');
    };
    return promise;
  }, config);
