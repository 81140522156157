import {
  random,
  date,
  address,
  name,
  internet,
  phone,
  company,
  commerce,
} from 'faker';
import { Factory } from 'miragejs';

export const appointmentFactory = Factory.extend({
  id: () => random.uuid(),
  userId: () => random.uuid(),
  subject: 'other',
  appointmentAddress: () => ({
    city: address.city(),
    zipCode: address.zipCode(),
    streetAddress: address.streetAddress(),
  }),
  invoiceAddress: () => ({
    city: address.city(),
    zipCode: address.zipCode(),
    streetAddress: address.streetAddress(),
  }),
  other: {
    sujet: 'les etudes',
  },
  /*
  When the subject is MEETING
  When the subject is Event
  event: {
    eventType: 'Colloque',
    concreateEventType: 'presentation',
    eventParticipants: '3-5',
  },
    justice: {
    justiceCategory: 'face_to_face_meeting',
    professionalType: 'Avocat',
    serviceIndication: null,
  },
   meeting: {
  meetingCategory: 'meeting',
  meetingType: 'rentrée scolaire',
  meetingParticipants: '3-5',
},
  */
  startDate: () => date.soon(),
  endDate: () => date.soon(),
  firstName: () => name.firstName(),
  lastName: () => name.lastName(),
  email: () => internet.email(),
  phoneNumber: () => phone.phoneNumber(),
  agency: () => ({
    id: () => random.uuid(),
    name: `${company.companyName()}`,
    address: {
      city: address.city(),
      zipCode: address.zipCode(),
      streetAddress: address.streetAddress(),
    },
    email: internet.email(),
    lowestPrice: commerce.price(1, 150),
    highestPrice: commerce.price(150),
  }),
});
