import React from 'react';

import { Box, Center, Spinner, useToast } from '@chakra-ui/react';
import { Formiz, useForm } from '@formiz/core';
import { useQueryCache } from 'react-query';
import { Redirect } from 'react-router-dom';

import {
  BOOKING_REQUEST_STORAGE_KEY,
  INVOICE_REQUEST_PAYERS,
  INVOICE_REQUEST_STEPS,
} from '@/constants/invoiceRequest';
import { useUserContext } from '@/context/user-context';
import { Page } from '@/layout';
import { convertToSearchDto } from '@/services/bookingRequest';
import {
  convertToInvoiceRequestDto,
  useCreateInvoiceRequest,
} from '@/services/invoiceRequest';
import { useLocalStorage } from '@/services/localStorage';
import { useGetAccount } from '@/services/login';

import { InvoiceRequestAgencies } from './InvoiceRequestAgencies';
import { InvoiceRequestBeneficiaryInformation } from './InvoiceRequestBeneficiaryInformation';
import { InvoiceRequestSelectBeneficiary } from './InvoiceRequestSelectBeneficiary';
import { InvoiceRequestSelectPayer } from './InvoiceRequestSelectPayer';
import { PaymentInformationForm } from './PaymentInformationForm';
import { InvoiceRequestFooter } from './_partials/InvoiceRequestFooter';
import { InvoiceRequestHeader } from './_partials/InvoiceRequestHeader';
import { InvoiceRequestSent } from './_partials/InvoiceRequestSent';

export const InvoiceRequest = () => {
  const form = useForm({
    subscribe: {
      form: true,
      fields: true,
    },
  });
  const toast = useToast();
  const [bookingRequest, setBookingRequest] = useLocalStorage(
    BOOKING_REQUEST_STORAGE_KEY,
    ''
  );

  const { currentStep, submitStep, setFieldsValues, values: formValues } = form;
  const queryCache = useQueryCache();

  const [mutate, { isSuccess, isLoading, data }] = useCreateInvoiceRequest({
    onError: () => {
      toast({
        title: 'Une erreur est survenue lors de la demande de devis',
        status: 'error',
      });
    },
    onSuccess: () => {
      setBookingRequest(null);
      queryCache.clear();
      toast({
        title: 'La demande de devis a été envoyée avec succès',
        status: 'success',
      });
    },
  });
  const { user: currentUser } = useUserContext();

  const { data: userInformation, isLoading: isUserLoading } = useGetAccount({
    params: { email: currentUser?.email },
    onError: () => {
      toast({
        title:
          "Une erreur est survenue lors de la récuperation des information d'utilisateur",
        status: 'error',
      });
    },
  });

  if (!bookingRequest && !isSuccess) {
    // without booking we can not be here only if we have isSuccess to show the confirmation page
    return <Redirect to="/booking-request" />;
  }

  const handleValidSubmit = (values) => {
    const allValues = {
      bookingRequest: convertToSearchDto(bookingRequest),
      currentUser,
      invoiceRequest: values,
    };
    const finalInvoiceRequestDto = convertToInvoiceRequestDto(allValues);
    mutate(finalInvoiceRequestDto);
  };

  const {
    invoiceAddress,
    useSavedInvoiceAddress,
    userPref_checkSaveInvoiceAddress: checkSaveInvoiceAddress,
  } = userInformation || {};

  const handleSubmitStep = (event) => {
    submitStep(event);
    if (
      currentStep.name ===
        INVOICE_REQUEST_STEPS.FURTHER_PAYMENT_INFORMATION.name ||
      currentStep.name === INVOICE_REQUEST_STEPS.FURTHER_INFORMATION.name
    ) {
      if (
        formValues.payer === INVOICE_REQUEST_PAYERS.YOU ||
        !formValues.payer
      ) {
        setFieldsValues({
          useAsDefaultInvoiceAddress: checkSaveInvoiceAddress,
          invoiceAddress:
            useSavedInvoiceAddress &&
            invoiceAddress &&
            invoiceAddress?.apiData?.properties?.label
              ? {
                  ...invoiceAddress,
                  label: invoiceAddress.apiData.properties.label,
                  value: invoiceAddress.apiData.properties.label,
                }
              : null,
        });
      }

      if (
        formValues.payer === INVOICE_REQUEST_PAYERS.COMPANY &&
        (formValues.useAsDefaultInvoiceAddress || formValues.invoiceAddress)
      ) {
        setFieldsValues({
          useAsDefaultInvoiceAddress: false,
          invoiceAddress: {},
        });
      }
    }
  };

  if (isSuccess) {
    return (
      <InvoiceRequestSent
        bookingId={data?.createdInvoiceRequestId}
        isActivationNeeded={data?.isActivationNeeded}
      />
    );
  }

  if (isUserLoading) {
    return (
      <Center h="20rem">
        <Spinner
          thickness="4px"
          emptyColor="gray.200"
          color="brandSecondary.600"
          size="xl"
        />
      </Center>
    );
  }
  return (
    <Page px={6} pb={4} bg="gray.100">
      <Formiz
        connect={form}
        onValidSubmit={handleValidSubmit}
        // initialValues={initialValues}
      >
        <form noValidate onSubmit={handleSubmitStep}>
          <Box marginX="auto" maxWidth="45em">
            <InvoiceRequestHeader />

            {/* <!-- Selection de l'agence --> */}
            <InvoiceRequestAgencies />

            {/* <!-- Information sur le bénéficiaire  --> */}
            <InvoiceRequestSelectBeneficiary />
            <InvoiceRequestBeneficiaryInformation />

            {/* <!-- Information sur le payeur  --> */}
            <InvoiceRequestSelectPayer />
            <PaymentInformationForm />

            <InvoiceRequestFooter
              isSubmitDisabled={isLoading || !form?.values?.agencies?.length}
            />
          </Box>
        </form>
      </Formiz>
    </Page>
  );
};
