import React from 'react';

import PropTypes from 'prop-types';

import { Card } from '@/components/Card';

export const AddZone = ({ isDisabled, ...otherProps }) => {
  const getStyle = () => {
    if (isDisabled) {
      return {
        borderColor: 'gray.300',
        _hover: {
          bg: 'white',
        },
        _active: {
          bg: 'white',
        },
        _focusWithin: {
          boxShadow: 'none',
          bg: 'white',
        },
        pointerEvents: 'none',
      };
    }

    return {
      borderColor: 'brandSecondary.600',
      borderWidth: '2px',
      _hover: {
        bg: 'gray.50',
      },
      _active: {
        bg: 'brandSecondary.50',
      },
      _focusWithin: {
        boxShadow: 'outline',
        bg: 'brandSecondary.50',
      },
    };
  };

  return (
    <Card
      as="label"
      d="flex"
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      boxShadow="none"
      borderStyle="dashed"
      transition="box-shadow 0.2s, background-color 0.2s"
      minH={20}
      {...getStyle()}
      {...otherProps}
    />
  );
};

AddZone.propTypes = {
  isDisabled: PropTypes.bool,
};

AddZone.defaultProps = {
  isDisabled: false,
};
