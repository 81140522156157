import React from 'react';

import { Text } from '@chakra-ui/react';
import { useForm } from '@formiz/core';

import {
  BOOKING_REQUEST,
  BOOKING_REQUEST_CATEGORY,
  BOOKING_REQUEST_SUBJECTS,
} from '@/constants/bookingRequest';
import { MEETING_APPOINTMENT_LABEL } from '@/constants/meeting';

import { SubjectSummaryTitle } from './SubjectSummaryTitle';

export const SummarySubjectDetail = () => {
  const { values } = useForm();
  const subjectName = BOOKING_REQUEST?.[values?.appointment?.subject];
  const currentBookingRequestSubject = BOOKING_REQUEST_SUBJECTS[subjectName];
  const subjectType = values?.appointment?.[values?.appointment?.subject]?.type;
  const category = values?.appointment?.category;
  const meetAppointmentLabel =
    MEETING_APPOINTMENT_LABEL[values?.appointment?.meeting?.category] || '';

  return (
    <>
      <SubjectSummaryTitle
        currentBookingRequestSubject={currentBookingRequestSubject}
        subjectType={subjectType}
        appointment={values?.appointment}
      />
      {values?.appointment?.meeting?.category && (
        <Text color="gray.500">
          {meetAppointmentLabel}
          {values?.appointment?.meeting?.participants && (
            <Text color="gray.500" isTruncated>
              {values?.appointment?.meeting?.participants.value} participants
            </Text>
          )}
        </Text>
      )}
      {BOOKING_REQUEST_SUBJECTS.EVENT.id === currentBookingRequestSubject?.id &&
        BOOKING_REQUEST_CATEGORY.PROFESSIONAL.id === category && (
          <>
            <Text color="gray.500" isTruncated>
              {values?.appointment?.event?.additionalInformation}
            </Text>
            {values?.appointment?.event?.participants?.value && (
              <Text color="gray.500" isTruncated>
                {values?.appointment?.event?.participants.value} participants
              </Text>
            )}
          </>
        )}
      {BOOKING_REQUEST_SUBJECTS.JUSTICE.id ===
        currentBookingRequestSubject?.id &&
        BOOKING_REQUEST_CATEGORY.PERSONAL.id === category && (
          <>
            {values?.appointment?.justice?.professionalType}
            {values?.appointment?.justice?.category && (
              <>
                ,{' '}
                <Text as="span" color="gray.500">
                  {
                    MEETING_APPOINTMENT_LABEL[
                      values?.appointment?.justice?.category
                    ]
                  }{' '}
                </Text>
              </>
            )}
            {values?.appointment?.justice?.serviceIndication && (
              <Text as="span" color="gray.500" isTruncated>
                {values?.appointment?.justice?.serviceIndication}
              </Text>
            )}
          </>
        )}
    </>
  );
};
