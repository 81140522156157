import React, { useState, useRef, forwardRef } from 'react';

import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  useBreakpointValue,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { FaCalendar } from 'react-icons/fa';

const MONTHS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];
const WEEKDAYS_LONG = [
  'Dimanche',
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
];
const WEEKDAYS_SHORT = ['Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.'];
const FORMAT = 'DD/MM/YYYY';

const ReactDayPickerInput = forwardRef(({ isDisabled, ...otherProps }, ref) => (
  <InputGroup>
    <Input
      ref={ref}
      focusBorderColor="brandSecondary.600"
      backgroundColor="white"
      pr="2.75rem"
      {...otherProps}
    />
    <InputRightElement
      color={`gray.${isDisabled ? '300' : '400'}`}
      width="2.75rem"
      zIndex={1}
    >
      <FaCalendar />
    </InputRightElement>
  </InputGroup>
));

export const DayPicker = ({
  disabledDays,
  defaultValue,
  placeholder,
  onChange,
  dayPickerInputProps,
  dayPickerProps,
  ...otherProps
}) => {
  const isSmartphoneFormat = useBreakpointValue({ base: true, sm: false });
  const [selectedDay, setSelectedDay] = useState(defaultValue);
  const dayPickerInputRef = useRef(null);

  const handleDayChange = (day) => {
    setSelectedDay(day);
    onChange(day);
  };

  const formatDate = (date, format) => dayjs(date).format(format);

  const parseDate = (str, format) => {
    const parsed = dayjs(str, format).toDate();
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return null;
  };

  return (
    <Box {...otherProps}>
      <DayPickerInput
        ref={dayPickerInputRef}
        component={ReactDayPickerInput}
        onDayChange={handleDayChange}
        value={selectedDay}
        formatDate={formatDate}
        format={FORMAT}
        placeholder={placeholder}
        parseDate={parseDate}
        keepFocus={false}
        dayPickerProps={{
          months: MONTHS,
          weekdaysLong: WEEKDAYS_LONG,
          weekdaysShort: WEEKDAYS_SHORT,
          firstDayOfWeek: 1,
          disabledDays,
          ...dayPickerProps,
        }}
        {...dayPickerInputProps}
        inputProps={{
          readOnly: isSmartphoneFormat,
          ...dayPickerInputProps.inputProps,
        }}
      />
    </Box>
  );
};

DayPicker.propTypes = {
  defaultValue: PropTypes.instanceOf(Date),
  disabledDays: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.func,
  ]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  dayPickerInputProps: PropTypes.object, // eslint-disable-line
  dayPickerProps: PropTypes.object, // eslint-disable-line
};

DayPicker.defaultProps = {
  defaultValue: null,
  disabledDays: { before: new Date() },
  placeholder: '',
  onChange: () => {},
  dayPickerInputProps: {},
  dayPickerProps: {},
};

ReactDayPickerInput.propTypes = {
  isDisabled: PropTypes.bool,
};

ReactDayPickerInput.defaultProps = {
  isDisabled: false,
};
