import React from 'react';

import { Textarea as ChakraUITextarea } from '@chakra-ui/react';

export const Textarea = (props) => (
  <ChakraUITextarea
    bg="gray.50"
    focusBorderColor="brandSecondary.600"
    backgroundColor="white"
    {...props}
  />
);
