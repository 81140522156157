import React from 'react';

import { Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FaInfoCircle, FaMapMarkerAlt } from 'react-icons/fa';

import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
  Icon,
} from '@/components';
import { formatAddress } from '@/utils/textUtils';

export const AppointmentAgencyDetail = ({
  appointmentAgency,
  ...otherProps
}) => {
  const address = formatAddress(appointmentAgency.address);
  return (
    <Card borderWidth="0" {...otherProps}>
      <CardContent position="relative">
        <CardTitle>
          {!!appointmentAgency?.name && appointmentAgency?.name}
        </CardTitle>
        <CardDescription textAlign="left" pb={2} h="auto">
          <Text isTruncated>
            <Icon icon={FaMapMarkerAlt} color="brandSecondary.700" mr={2} />
            {address}
          </Text>
          <Text mt={6} color="gray.400">
            <Icon icon={FaInfoCircle} color="inherit" mr={2} />
            Cette agence vous a envoyé un devis sur votre adresse mail{' '}
            {appointmentAgency?.email ? ': ' : ''}
            <Text as="span" color="gray.600">
              {` ${appointmentAgency?.email || ''}`}
            </Text>
          </Text>
        </CardDescription>
      </CardContent>
    </Card>
  );
};

AppointmentAgencyDetail.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  appointmentAgency: PropTypes.object,
};

AppointmentAgencyDetail.defaultProps = {
  appointmentAgency: null,
};
