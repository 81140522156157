import React from 'react';

import { Wrap } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const Stepper = ({
  children,
  activeStep,
  colorScheme,
  spacing,
  ...otherProps
}) => {
  const childrenArray = (children
    ? React.Children.toArray(children)
    : []
  ).filter((child) => !!child);
  const activeChildIndex = childrenArray.findIndex(
    (child) => child?.props?.name === activeStep
  );

  const childrenClones = React.Children.map(childrenArray, (child, index) =>
    React.cloneElement(child, {
      index,
      isActive: activeChildIndex === index,
      isAfterTheCurrentStep: index > activeChildIndex,
      colorScheme: child?.props?.colorScheme || colorScheme,
    })
  );

  return (
    <Wrap spacing={spacing} {...otherProps}>
      {childrenClones}
    </Wrap>
  );
};

Stepper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  activeStep: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  colorScheme: PropTypes.string,
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Stepper.defaultProps = {
  children: null,
  activeStep: null,
  colorScheme: 'brandSecondary',
  spacing: 4,
};
