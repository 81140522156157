import React from 'react';

import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';

export const InputNumber = (props) => (
  <NumberInput
    bg="gray.50"
    focusBorderColor="brandSecondary.600"
    backgroundColor="white"
    {...props}
  >
    <NumberInputField />
    <NumberInputStepper>
      <NumberIncrementStepper />
      <NumberDecrementStepper />
    </NumberInputStepper>
  </NumberInput>
);
