import React from 'react';

import {
  Box,
  Flex,
  Heading,
  IconButton,
  Spacer,
  Spinner,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useForm } from '@formiz/core';
import PropTypes from 'prop-types';
import { FaTrash } from 'react-icons/fa';

import { FieldUploader } from '@/components';
import { MEGA } from '@/components/Fields/FieldUploader';
import { useUploadFile } from '@/services/invoiceRequest';
import { useChunkyUploadFile } from '@/services/utils';

import { FileUploadButtonPlaceHolder } from './InvoiceRequestValidation/_partials/FileUploadButtonPlaceHolder';

export const FilesUploadElement = ({
  index,
  id,
  collection,
  removeItem,
  addItem,
  helperText,
  setDocuments,
}) => {
  const toast = useToast();
  const form = useForm();
  const [mutateUpload] = useUploadFile({
    onSuccess: ({ uuid: fileId }, { isLastPart }) => {
      if (isLastPart) {
        toast({
          title: `Le document numéro ${index + 1} a été bien telechargé`,
          status: 'success',
        });
        setDocuments((docs) => [...docs, fileId]);
      }
    },
  });

  const { handleUploadFile, isLoading } = useChunkyUploadFile({
    mutateUpload,
    onError: () => {
      toast({
        title: `Une erreur est survenue lors de la téléchargement du fichier numéro ${
          index + 1
        }`,
        status: 'error',
      });
      const formFileName = `docFiles[${index}]`;
      form.setFieldsValues({ [formFileName]: null });
    },
  });

  const removeSavedFileId = () => {
    setDocuments((docs) => docs.filter((ele) => ele.id !== id));
  };

  const handleRemoveItem = () => {
    removeItem(id);
    removeSavedFileId();
  };

  return (
    <Box
      mt="4"
      backgroundColor="gray.50"
      border="1px dashed"
      borderColor="gray.200"
      p="3"
      pb="5"
    >
      <Flex direction="row" alignItems="center">
        <Heading as="h4" size="lg" pt={2}>
          Fichier numéro {index + 1}
        </Heading>
        <Spacer />
        {isLoading && (
          <Spinner
            mr="4"
            thickness="4px"
            emptyColor="gray.200"
            color="brandSecondary.600"
            size="md"
          />
        )}
        <IconButton
          size="lg"
          colorScheme="brandSecondary"
          icon={<FaTrash />}
          borderRadius="spot.variant1"
          onClick={handleRemoveItem}
          disabled={collection.length <= 1}
        />
      </Flex>
      <>
        <FieldUploader
          name={`docFiles[${index}]`}
          id={collection[index].id}
          label="Document(s)"
          helper={index === 0 ? helperText : null}
          title="Télécharger un document"
          accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
          onChange={handleUploadFile}
          isDisabledTrash={isLoading}
          onTrashClick={removeSavedFileId}
          maxFileSize={25 * MEGA}
          validations={[
            {
              rule: () => !isLoading,
              deps: [isLoading],
              message: 'Veuillez patienter pendant le téléchargement',
            },
          ]}
          maxFileSizeLabel={
            <Text color="gray.400" fontSize={12}>
              Taille maximale 25MB
            </Text>
          }
          h={44}
        />
        {collection.length === index + 1 && (
          <FileUploadButtonPlaceHolder
            maxFileCount={10}
            selectedFileCount={index + 1}
            onClick={addItem}
          />
        )}
      </>
    </Box>
  );
};

FilesUploadElement.propTypes = {
  index: PropTypes.number,
  id: PropTypes.string,
  addItem: PropTypes.func,
  collection: PropTypes.arrayOf(PropTypes.object),
  removeItem: PropTypes.func,
  helperText: PropTypes.node,
  setDocuments: PropTypes.func,
};

FilesUploadElement.defaultProps = {
  index: null,
  id: null,
  addItem: () => {},
  collection: [],
  removeItem: () => {},
  setDocuments: () => {},
  helperText: '',
};
