/* eslint-disable max-len */
import React from 'react';

import { Box } from '@chakra-ui/react';

export const Accessibility = (props) => (
  <Box {...props}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 25 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2242 12.0248C17.007 8.80022 14.8987 7.18811 14.8987 7.18811C13.8943 6.53 13.1798 6.61592 12.7556 7.44546C12.6413 8.06801 12.9816 8.68005 13.776 9.28052C15.973 11.2148 17.0713 12.1822 17.0713 12.1822C17.4568 12.7144 17.1676 13.1057 16.2034 13.3564C9.00817 13.9898 5.41052 14.3061 5.41052 14.3061C4.15311 14.7915 4.26188 15.7937 5.29424 16.1369C10.0966 16.0157 12.4977 15.9551 12.4977 15.9551C12.9444 16.1631 12.9108 16.4255 12.3989 16.7415C7.42989 17.7372 4.94582 18.2354 4.94582 18.2354C3.837 18.8394 4.12402 19.8476 5.4102 19.9246C10.1285 19.2569 12.4877 18.9233 12.4877 18.9233C13.0691 19.0787 13.1446 19.4053 12.7142 19.903C8.75835 21.1515 6.78023 21.7755 6.78023 21.7755C6.46452 22.1074 6.34141 22.4033 6.41038 22.6622C6.54223 23.1238 6.8194 23.3442 7.24147 23.3233C11.37 22.262 13.4344 21.7311 13.4344 21.7311C14.0505 21.8085 14.1749 22.0888 13.8082 22.5724C11.1022 23.8102 9.74947 24.4293 9.74947 24.4293C9.36715 24.7286 9.26872 25.0153 9.45533 25.2894C9.68997 25.5819 10.052 25.6543 10.5418 25.506C16.8034 23.5386 20.5034 22.146 21.6415 21.3273C23.5972 19.509 24.2699 17.398 23.6604 14.9948C23.0913 13.938 22.2792 12.948 21.2242 12.0248L21.2242 12.0248Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7719 3.20203C19.0836 5.55046 19.2779 7.65467 19.3545 9.51506C19.3545 9.51506 18.305 8.71484 16.206 7.11443C16.6303 4.17412 16.8426 2.70449 16.8426 2.70449C17.4623 1.79675 18.1055 1.9627 18.7719 3.20202L18.7719 3.20203Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1573 6.7882C12.8018 5.87006 13.3056 5.93885 13.3056 5.93885C13.3056 5.93885 11.2495 4.37377 7.35938 1.42594C6.01388 0.919747 5.31838 2.38337 6.16361 3.21259C9.38669 6.28183 10.9982 7.81607 10.9982 7.81607C10.9415 8.41529 10.6956 8.62736 10.2608 8.45258C6.02954 5.15063 3.91414 3.49985 3.91414 3.49985C2.59884 3.02255 1.90345 4.49801 2.76988 5.54775C7.09074 8.87946 9.25083 10.5454 9.25083 10.5454C9.1843 10.8681 8.96325 11.0613 8.58716 11.1238C4.84075 9.00899 2.96756 7.95158 2.96756 7.95158C1.69714 7.76051 1.59684 8.88535 2.16278 9.59108C5.86881 11.9532 7.72202 13.1345 7.72202 13.1345C12.2057 12.6776 14.4472 12.4489 14.4472 12.4489C15.1846 12.4117 15.1155 11.8858 14.9447 11.6876C13.0524 10.0594 12.1023 9.01312 12.1023 9.01312C11.6244 8.31183 11.5983 7.43407 12.1573 6.78825L12.1573 6.7882Z"
        fill="white"
      />
    </svg>
  </Box>
);
