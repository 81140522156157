import React from 'react';

import { useForm } from '@formiz/core';

import { BOOKING_REQUEST_SUBJECTS } from '@/constants/bookingRequest';
import {
  Event,
  Health,
  Justice,
  Meeting,
} from '@/pages/BookingRequest/_steps/_subjects';

const subjectComponent = {
  [BOOKING_REQUEST_SUBJECTS.HEALTH.id]: <Health />,
  [BOOKING_REQUEST_SUBJECTS.MEETING.id]: <Meeting />,
  [BOOKING_REQUEST_SUBJECTS.EVENT.id]: <Event />,
  [BOOKING_REQUEST_SUBJECTS.JUSTICE.id]: <Justice />,
};

export const AppointmentSubjectDetail = () => {
  const form = useForm({ subscribe: { fields: ['appointment.subject'] } });
  const { appointment } = form.values;
  const { category, subject } = appointment || {
    category: null,
    subject: null,
  };

  if ((!category && !subject) || !subjectComponent[subject]) {
    return null;
  }

  return subjectComponent[subject];
};
