import React, { useEffect, useContext } from 'react';

import { Radio } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { CardContext } from '.';
import { FieldRadioGroupContext } from '../Fields/FieldRadioGroup';

export const CardRadio = ({ value, ...otherProps }) => {
  const { setHasFormElement, setIsActive } = useContext(CardContext);
  const { activeRadioValue } = useContext(FieldRadioGroupContext);

  useEffect(() => {
    setHasFormElement(true);
    return () => setHasFormElement(false);
  }, [setHasFormElement]);

  useEffect(() => {
    setIsActive(activeRadioValue === value);
  }, [activeRadioValue, value, setIsActive]);

  return (
    <Radio
      as="span"
      position="absolute"
      top="3"
      right="5"
      value={value}
      {...otherProps}
    />
  );
};

CardRadio.propTypes = {
  value: PropTypes.node,
};

CardRadio.defaultProps = {
  value: null,
};
