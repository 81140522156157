import React from 'react';

import { Flex, Spacer, Stack } from '@chakra-ui/react';

import { FieldRadioGroup } from '@/components';
import { MEETING_REQUEST_CATEGORY } from '@/constants/bookingRequest';
import { AppointmentHeading } from '@/pages/BookingRequest/_partials/AppointmentHeading';
import { HelpIconButton } from '@/pages/BookingRequest/_partials/HelpIconButton';
import { RadioItem } from '@/pages/BookingRequest/_partials/RadioItem';

export const MeetingStepRadioGroup = () => {
  return (
    <>
      <Flex>
        <AppointmentHeading
          title="Type de rencontre"
          helper="Quel est le format du rendez-vous ?"
        />
        <Spacer />
        <HelpIconButton />
      </Flex>
      <FieldRadioGroup
        id="appointment-meeting-type"
        name="appointment.meeting.category"
        required="Vous devez choisir un type de rendez-vous"
        mt={4}
      >
        <Stack>
          <RadioItem {...MEETING_REQUEST_CATEGORY.MEETING_FACE_TO_FACE} />
          <RadioItem {...MEETING_REQUEST_CATEGORY.MEETING} />
        </Stack>
      </FieldRadioGroup>
    </>
  );
};
