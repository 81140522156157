import { FaCalendar } from 'react-icons/fa';

import { Event } from '@/assets/icons/Event';
import { Health } from '@/assets/icons/Health';
import { Justice } from '@/assets/icons/Justice';
import { Meeting } from '@/assets/icons/Meeting';
import { Personal } from '@/assets/icons/Personal';
import { Professional } from '@/assets/icons/Professional';

// BookingRequest constants
export const BOOKING_REQUEST_STEPS = {
  APPOINTMENT_DATE_TIME: 'appointment-date-time',
  APPOINTMENT_INFORMATION: 'appointment-information',
  APPOINTMENT_LOCATION: 'appointment-location',
  APPOINTMENT_SUMMARY: 'appointment-summary',
};

export const BOOKING_REQUEST_DATE_TIME = {
  FIXED_DATE: {
    id: 'fixed-date',
    name: 'Date fixe',
    description: 'Le rendez-vous est organisé, vous connaissez la date',
  },
  FREE_DATE: {
    id: 'free-date',
    name: 'Date libre',
    description:
      'Le rendez-vous est en attente, vous souhaitez proposer plusieurs dates possibles (3 maximum)',
  },
  MULTI_DATES: {
    id: 'multi-dates',
    name: 'Multi dates',
    description:
      'Vous avez plusieurs rendez-vous identiques et souhaitez les réserver tous en même temps',
  },
  RECURRING_DATE: {
    id: 'recurring-date',
    name: 'Date récurrente',
    description: 'Le rendez-vous a lieu toutes les semaines ou tous les mois',
  },
};

export const BOOKING_REQUEST_CATEGORY = {
  PERSONAL: {
    id: 'personal',
    name: 'Vie personnelle',
    icon: Personal,
  },
  PROFESSIONAL: {
    id: 'professional',
    name: 'Vie professionnelle',
    icon: Professional,
  },
};

export const MEETING_REQUEST_CATEGORY = {
  MEETING: {
    id: 'meeting',
    name: 'Réunion',
    description: 'Plusieurs personnes sont présentes et participent',
  },
  MEETING_FACE_TO_FACE: {
    id: 'face_to_face_meeting',
    name: 'Rendez-vous simple',
    description: 'Face à face en présence d’une ou deux personnes',
  },
};

export const JUSTICE_MEETING_REQUEST_CATEGORY = {
  MEETING: {
    id: 'meeting',
    name: 'Réunion',
    description: 'Plusieurs personnes sont présentes et participent',
  },
  MEETING_FACE_TO_FACE: {
    id: 'face_to_face_meeting',
    name: 'Rendez-vous simple',
    description: 'Face à face en présence d’une ou deux personnes',
  },
};

export const JUSTICE_REQUEST_CATEGORY = {
  YES: {
    id: 'yes',
    name: 'Oui',
    description: '',
  },
  NO: {
    id: 'no',
    name: 'Non',
    description: '',
  },
};

export const EVENT_HELPER_TYPE = {
  [BOOKING_REQUEST_CATEGORY.PERSONAL.id]: {
    type: {
      label: '',
      helper: 'Cérémonie, Fête, Spectacle, …',
    },
  },
  [BOOKING_REQUEST_CATEGORY.PROFESSIONAL.id]: {
    type: {
      label: 'Objet',
      helper: 'Formation, Convention, Séminaire, Cérémonie, …',
    },
    additionalInfo: {
      helper: 'Sujet, Objectifs, Participants',
    },
  },
};

export const BOOKING_REQUEST = {
  health: 'HEALTH',
  event: 'EVENT',
  meeting: 'MEETING',
  justice: 'JUSTICE',
  other: 'OTHER',
};

export const BOOKING_REQUEST_SUBJECTS = {
  MEETING: {
    id: 'meeting',
    name: 'Rencontre',
    description: 'Rendez-vous individuel ou réunion',
    icon: Meeting,
  },
  EVENT: {
    id: 'event',
    name: 'Événement',
    description: 'Cérémonie – Représentation',
    icon: Event,
  },
  HEALTH: {
    id: 'health',
    name: 'Santé',
    description: 'Médical – Paramédical',
    icon: Health,
  },
  JUSTICE: {
    id: 'justice',
    name: 'Justice',
    description: 'Procédure – Juridique',
    icon: Justice,
  },
  OTHER: {
    id: 'other',
    name: 'Autre',
    description: "Un rendez-vous d'un sujet différent",
    icon: FaCalendar,
  },
};
export const BOOKING_REQUEST_SUBJECTS_PROFESSIONAL = {
  PROFESSIONAL_MEETING: {
    id: 'meeting',
    name: 'Rencontre',
    description: 'Entretien - Réunion',
    icon: Meeting,
  },
  PROFESSIONAL_EVENT: {
    id: 'event',
    name: 'Événement',
    description: 'Séminaire – Formation',
    icon: Event,
  },
  PROFESSIONAL_HEALTH: {
    id: 'health',
    name: 'Santé au travail',
    description: 'Médecine du travail – Adaptation de poste',
    icon: Health,
  },
};
