import {
  random,
  date,
  commerce,
  address,
  name,
  internet,
  phone,
  company,
} from 'faker';
import { Factory } from 'miragejs';

import { INVOICE_REQUEST_RESPONSES_STATUS } from '@/constants/invoiceRequest';

const { RECEIVED, UNAVAILABLE, WAITING } = INVOICE_REQUEST_RESPONSES_STATUS;

export const invoiceRequestFactory = Factory.extend({
  id: () => random.uuid(),
  userId: () => random.uuid(),
  status: () => 'REQUESTED', // CANCELED
  subject: () => 'other',
  startDate: () => date.soon(),
  endDate: () => date.soon(),
  appointmentAddress: () => ({
    city: address.city(),
    zipCode: address.zipCode(),
    streetAddress: address.streetAddress(),
  }),
  invoiceAddress: () => ({
    city: address.city(),
    zipCode: address.zipCode(),
    streetAddress: address.streetAddress(),
  }),
  /* CompanyInformations */
  siret: '222222222222',
  companyName: 'Bearstudio',

  other: {
    sujet: 'sujet de rendez-vous',
  },
  /*
  When the subject is MEETING
  meeting: {
    meetingCategory: 'meeting',
    meetingType: 'rentrée scolaire',
    meetingParticipants: '3-5',
  },
  When the subject is Event
  event: {
    eventType: 'Colloque',
    concreateEventType: 'presentation',
    eventParticipants: '3-5',
  },
  */

  /* Information sur les beneficiare */
  firstName: () => name.firstName(),
  lastName: () => name.lastName(),
  email: () => internet.email(),
  phoneNumber: () => phone.phoneNumber(),

  agencyIds: () => [random.uuid(), random.uuid()],
  mediaIds: () => [],
  responses: () =>
    [...Array(random.number(10))].map(() => ({
      id: random.uuid(),
      agency: {
        name: `${company.companyName()}`,
        address: {
          city: address.city(),
          zipCode: address.zipCode(),
          streetAddress: address.streetAddress(),
        },
        email: internet.email(),
        lowestPrice: commerce.price(1, 150),
        highestPrice: commerce.price(150),
      },
      status: random.arrayElement([RECEIVED, WAITING, UNAVAILABLE]),
      price: commerce.price(),
    })),
});
