import React from 'react';

import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/lazy';

export const PreviewModal = ({ title, helper, url, isOpen, onClose }) => {
  const modalSize = useBreakpointValue({
    base: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={4}>
          {(!!title || !!helper) && (
            <Box p={4}>
              {title && (
                <Heading as="h3" size="md" pt={2}>
                  {title}
                </Heading>
              )}
              {helper && (
                <Text as="h4" size="sm" mt={4}>
                  {helper}
                </Text>
              )}
            </Box>
          )}
          <Flex flex={1}>
            {/* TODO Il faut retirer le video et le props ici quand on aura les bonnes videos */}
            <ReactPlayer
              url={url || 'videos/placeholder.mp4'}
              width="100%"
              height="100%"
              controls
              playing
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

PreviewModal.propTypes = {
  title: PropTypes.node,
  helper: PropTypes.node,
  url: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

PreviewModal.defaultProps = {
  title: '',
  helper: '',
  url: '',
  isOpen: false,
  onClose: () => {},
};
