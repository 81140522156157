import React from 'react';

import { Stack } from '@chakra-ui/react';
import { FormizStep } from '@formiz/core';

import { FieldRadioGroup } from '@/components';
import { BOOKING_REQUEST_CATEGORY } from '@/constants/bookingRequest';
import {
  BOOKING_REQUEST_STORAGE_KEY,
  INVOICE_REQUEST_PAYERS,
  INVOICE_REQUEST_STEPS,
} from '@/constants/invoiceRequest';
import { useUserContext } from '@/context/user-context';
import { RadioItem } from '@/pages/BookingRequest/_partials/RadioItem';
import { InvoiceRequestHeading } from '@/pages/InvoiceRequest/_partials/InvoiceRequestHeading';
import { useLocalStorage } from '@/services/localStorage';

export const InvoiceRequestSelectPayer = () => {
  const [bookingRequest] = useLocalStorage(BOOKING_REQUEST_STORAGE_KEY, '');
  const { user: currentUser } = useUserContext();
  const { firstName, lastName } = currentUser || {};

  return (
    <FormizStep
      name={INVOICE_REQUEST_STEPS.FURTHER_PAYMENT_INFORMATION.name}
      order={INVOICE_REQUEST_STEPS.FURTHER_PAYMENT_INFORMATION.order}
      isEnabled={
        bookingRequest?.appointment?.category ===
        BOOKING_REQUEST_CATEGORY.PROFESSIONAL.id
      }
    >
      <InvoiceRequestHeading
        title="Information pour la facturation"
        helper="Qui prend en charge le rendez-vous ?"
        mb={4}
      />
      <FieldRadioGroup
        id="invoiceRequest-beneficiary"
        name="payer"
        required="Vous devez choisir le payeur de la demande de devis"
        helper="Le payeur est t’il une entreprise ?"
        mt={4}
      >
        <Stack>
          <RadioItem
            id={INVOICE_REQUEST_PAYERS.YOU}
            name={`${lastName} ${firstName} (vous) `}
            description="Je suis le payeur"
          />
          <RadioItem
            id={INVOICE_REQUEST_PAYERS.COMPANY}
            name="Quelqu’un d’autre"
            description="Un organisme, une entreprise, une autre personne, …"
          />
        </Stack>
      </FieldRadioGroup>
    </FormizStep>
  );
};
