/* eslint-disable consistent-return */
/* eslint-disable default-case */

export const Radio = {
  baseStyle: ({ colorScheme }) => {
    switch (colorScheme) {
      case 'brand':
        return {
          control: {
            _checked: {
              bg: `${colorScheme}.700`,
              borderColor: `${colorScheme}.700`,
              _hover: {
                bg: `${colorScheme}.800`,
                borderColor: `${colorScheme}.800`,
              },
            },
            _indeterminate: {
              bg: `${colorScheme}.700`,
              borderColor: `${colorScheme}.700`,
            },
          },
        };
      case 'brandSecondary':
        return {
          control: {
            _checked: {
              bg: `${colorScheme}.600`,
              borderColor: `${colorScheme}.600`,
              _disabled: {
                color: `${colorScheme}.600`,
              },
              _hover: {
                bg: `${colorScheme}.700`,
                borderColor: `${colorScheme}.700`,
              },
            },
            _indeterminate: {
              bg: `${colorScheme}.600`,
              borderColor: `${colorScheme}.600`,
            },
          },
        };
    }
  },
};
