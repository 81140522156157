/* eslint-disable consistent-return */
/* eslint-disable default-case */

export const Form = {
  baseStyle: () => ({
    errorIcon: {
      color: 'danger.500',
    },
    errorText: {
      color: 'danger.500',
    },
    requiredIndicatorText: {
      color: 'danger.500',
    },
  }),
};
