import React from 'react';

import { Box, ListItem, OrderedList, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { BOOKING_REQUEST_DATE_TIME } from '@/constants/bookingRequest';
import {
  DATE_FORMAT_WITH_YEAR,
  INPUT_TIME_FORMAT,
  RECURRENT_DATE_UNIT,
  TIME_FORMAT,
} from '@/constants/date';

const displayDate = (datetime) => (
  <>
    <Text textTransform="capitalize">
      {dayjs(datetime?.date).format(DATE_FORMAT_WITH_YEAR)}
    </Text>
    <Text as="span">
      {dayjs(datetime?.startTime, INPUT_TIME_FORMAT).format(TIME_FORMAT)} -{' '}
      {dayjs(datetime?.endTime, INPUT_TIME_FORMAT).format(TIME_FORMAT)}
    </Text>
  </>
);

const getPluralIfWeeks = (recurringTimeUnit, recurringNumber) => {
  if (recurringTimeUnit?.value === RECURRENT_DATE_UNIT[0].value) {
    return recurringNumber > 1
      ? `${recurringTimeUnit?.value}s`
      : recurringTimeUnit?.value;
  }
  return recurringTimeUnit?.value;
};

const displayRecurringDate = ({
  dates,
  recurringNumber,
  recurringTimeUnit,
  meetingCount,
}) => {
  const { date, startTime, endTime } = dates[0];
  return (
    <>
      <Text color="gray.500">
        Chaque {recurringNumber > 1 ? recurringNumber : ''}{' '}
        <Text as="span" color="gray.800">
          {getPluralIfWeeks(recurringTimeUnit, recurringNumber)}{' '}
        </Text>
      </Text>
      <Text textTransform="capitalize" color="gray.500">
        a partir du
      </Text>
      <Text textTransform="capitalize">
        {dayjs(date).format(DATE_FORMAT_WITH_YEAR)}
      </Text>
      <Text as="span">
        {dayjs(startTime, INPUT_TIME_FORMAT).format(TIME_FORMAT)} -{' '}
        {dayjs(endTime, INPUT_TIME_FORMAT).format(TIME_FORMAT)}
      </Text>
      <Text color="gray.500">
        pour{' '}
        <Text as="span" color="gray.800">
          {meetingCount !== 1 ? meetingCount : 'un'}
        </Text>{' '}
        rendez-vous au total
      </Text>
    </>
  );
};

export const DisplayDateCard = ({ appointment, ...otherProps }) => (
  <Box {...otherProps}>
    {[
      BOOKING_REQUEST_DATE_TIME.FREE_DATE.id,
      BOOKING_REQUEST_DATE_TIME.MULTI_DATES.id,
    ].includes(appointment?.datetime?.appointmentDateTimeType) && (
      <OrderedList spacing={2}>
        {(appointment?.datetime?.dates || []).map((meetingDate) => (
          <ListItem key={uuid()}>{displayDate(meetingDate)}</ListItem>
        ))}
      </OrderedList>
    )}
    {appointment?.datetime?.appointmentDateTimeType ===
      BOOKING_REQUEST_DATE_TIME.FIXED_DATE.id &&
      appointment?.datetime?.dates &&
      displayDate(appointment.datetime.dates[0])}

    {appointment?.datetime?.appointmentDateTimeType ===
      BOOKING_REQUEST_DATE_TIME.RECURRING_DATE.id &&
      appointment?.datetime?.dates &&
      displayRecurringDate(appointment.datetime)}
  </Box>
);

DisplayDateCard.propTypes = {
  appointment: PropTypes.objectOf(PropTypes.any),
};

DisplayDateCard.defaultProps = {
  appointment: {},
};
