import React, { useEffect, useState } from 'react';

import { InputGroup } from '@chakra-ui/react';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import PropTypes from 'prop-types';

import {
  FormGroup,
  Input,
  Textarea,
  TimePicker,
  InputPassword,
  InputNumber,
} from '@/components';

export const FieldInput = (props) => {
  const {
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    errorMessage,
  } = useField(props);

  const {
    label,
    helper,
    placeholder,
    type,
    isDisabled,
    required,
    formatValue,
    keepValue,
    asyncValidations,
    inputProps,
    h,
    ...otherProps
  } = props;

  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  let formGroupProps = {
    htmlFor: id,
    label,
    helper,
    errorMessage,
    showError,
    isRequired: !!required,
    isDisabled,
    ...otherProps,
  };

  if (h) {
    formGroupProps = {
      ...formGroupProps,
      h,
    };
  }
  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const handleChange = (e) => {
    const { target } = e;
    if (type === 'number') {
      setValue(e);
    } else {
      setValue(target.value);
    }
  };

  const handleBlur = () => {
    if (isTouched) return;
    setIsTouched(true);
  };

  const getElementAndProps = () => {
    if (type === 'textarea') {
      return [
        Textarea,
        {
          height: '1.8rem',
        },
      ];
    }

    if (type === 'time') {
      return [TimePicker, {}];
    }

    if (type === 'password') {
      return [InputPassword, {}];
    }

    if (type === 'number') {
      return [InputNumber, {}];
    }

    return [Input, {}];
  };

  const [Element, inputExtraProps] = getElementAndProps();

  return (
    <FormGroup {...formGroupProps}>
      <InputGroup>
        <Element
          id={id}
          type={type || 'text'}
          value={value ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          aria-invalid={showError}
          aria-required={!!required}
          aria-describedby={showError ? `${id}-error` : null}
          {...inputExtraProps}
          {...inputProps}
        />
      </InputGroup>
    </FormGroup>
  );
};

FieldInput.propTypes = {
  label: PropTypes.node,
  helper: PropTypes.node,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
  ...fieldPropTypes,
};

FieldInput.defaultProps = {
  label: '',
  helper: '',
  placeholder: '',
  type: 'text',
  isDisabled: false,
  ...fieldDefaultProps,
};
