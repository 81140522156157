import React from 'react';

import { Text, VisuallyHidden } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { AddZone } from '@/components/AddZone';
import { Input } from '@/components/Input';

export const Uploader = ({
  title,
  children,
  onChange,
  isDisabled,
  inputProps,
  ...otherProps
}) => (
  <AddZone isDisabled={isDisabled} {...otherProps}>
    <VisuallyHidden>
      <Input onChange={onChange} type="file" {...inputProps} />
    </VisuallyHidden>
    {title && (
      <Text color="brandSecondary.600" fontSize="xl">
        {title}
      </Text>
    )}
    {children}
  </AddZone>
);

Uploader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  inputProps: PropTypes.object, // eslint-disable-line
};

Uploader.defaultProps = {
  title: '',
  children: null,
  onChange: () => {},
  isDisabled: false,
  inputProps: {},
};
