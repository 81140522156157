import React from 'react';

import { Flex, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import {
  Card,
  CardContent,
  CardDescription,
  CardRadio,
  CardTitle,
  Icon,
} from '@/components';

export const RadioItem = ({
  id,
  name,
  description,
  icon,
  isForYou,
  isDisabled,
  autoHeight,
}) => (
  <Card autoHeight={autoHeight} borderWidth="0">
    <CardRadio value={id} isDisabled={isDisabled} />
    <CardContent>
      <CardTitle>
        {icon && (
          <Flex
            justifyContent="center"
            alignItems="center"
            w="2.5rem"
            h="2.5rem"
            overflow="hidden"
            bg="brandSecondary.100"
            borderRadius="spot.variant1"
            mr={2}
          >
            <Icon color="brandSecondary.700" icon={icon} fontSize="1.5rem" />
          </Flex>
        )}
        {name}
        {isForYou && (
          <Text fontSize="md" color="gray.500" ml={2}>
            (vous même)
          </Text>
        )}
      </CardTitle>
      {description && <CardDescription>{description}</CardDescription>}
    </CardContent>
  </Card>
);

RadioItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.func,
  isForYou: PropTypes.bool,
  isDisabled: PropTypes.bool,
  autoHeight: PropTypes.bool,
};

RadioItem.defaultProps = {
  id: '',
  description: '',
  icon: null,
  isForYou: false,
  isDisabled: false,
  autoHeight: false,
};
