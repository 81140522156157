import React from 'react';

import { Box, Flex, Spacer } from '@chakra-ui/react';

import { FieldInput } from '@/components';
import { BOOKING_REQUEST_DATE_TIME } from '@/constants/bookingRequest';
import { AppointmentHeading } from '@/pages/BookingRequest/_partials/AppointmentHeading';
import { HelpIconButton } from '@/pages/BookingRequest/_partials/HelpIconButton';
import { useDateTimeRepeater } from '@/services/bookingRequest';

import { AppointmentButtonPlaceHolder } from './AppointmentButtonPlaceHolder';
import { AppointmentMultiDateRepeaterForm } from './AppointmentMultiDateRepeaterForm';

export const AppointmentMultiDateInformation = () => {
  const {
    dateCollection: meetingDateCollection,
    dateFormRef,
    deleteSelectedDate,
    addDate: addDateMeeting,
  } = useDateTimeRepeater({
    dateCount: 10,
    dateType: BOOKING_REQUEST_DATE_TIME.MULTI_DATES.id,
  });

  return (
    <>
      <Flex>
        <AppointmentHeading
          title="Rendez-vous multi-dates"
          helper="Enregistrez jusqu’à 10 rendez-vous identiques"
        />
        <Spacer />
        <HelpIconButton />
      </Flex>
      <Box>
        <>
          {meetingDateCollection?.map((meetingDate, i) => (
            <AppointmentMultiDateRepeaterForm
              ref={(el) => {
                dateFormRef.current[i] = el;
              }}
              key={meetingDate.id}
              index={i}
              deleteButtonProps={{
                isDisabled: meetingDateCollection?.length === 1,
                onClick: () => deleteSelectedDate(meetingDate.id),
              }}
            />
          ))}
          <AppointmentButtonPlaceHolder
            onClick={addDateMeeting}
            selectedDateCount={meetingDateCollection?.length}
            isDisabled={meetingDateCollection?.length >= 10}
            maxDatesCount={10}
          />
          <FieldInput
            type="hidden"
            name="helperDateText"
            helper="La durée d'un rendez-vous est d'au moins une heure"
            mt={4}
          />
        </>
      </Box>
    </>
  );
};
