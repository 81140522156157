import React from 'react';

import { IconButton, InputGroup, InputRightElement } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { Input } from '@/components/Input';

export const InputPassword = ({
  id,
  value,
  onChange,
  onBlur,
  placeholder,
  isDisabled,
  'aria-invalid': ariaInvalid,
  'aria-required': ariaRequired,
  'aria-describedby': ariaDescribedby,
  inputProps,
  ...otherProps
}) => {
  const [show, setShow] = React.useState(false);
  const toggleShow = () => setShow((prev) => !prev);

  return (
    <InputGroup {...otherProps}>
      <Input
        pr={12}
        type={show ? 'text' : 'password'}
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        isDisabled={isDisabled}
        aria-invalid={ariaInvalid}
        aria-required={ariaRequired}
        aria-describedby={ariaDescribedby}
        {...inputProps}
      />
      <InputRightElement>
        <IconButton
          onClick={toggleShow}
          icon={show ? <FaEye /> : <FaEyeSlash />}
          variant="ghost"
          _focus={{
            boxShadow: 'none',
          }}
        />
      </InputRightElement>
    </InputGroup>
  );
};

InputPassword.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  'aria-invalid': PropTypes.bool,
  'aria-required': PropTypes.bool,
  'aria-describedby': PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
};

InputPassword.defaultProps = {
  id: '',
  value: '',
  onChange: () => {},
  onBlur: () => {},
  placeholder: '',
  isDisabled: false,
  'aria-invalid': null,
  'aria-required': null,
  'aria-describedby': null,
  inputProps: {},
};
