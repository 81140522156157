import React from 'react';

import { Input as ChakraUIInput } from '@chakra-ui/react';

export const Input = (props) => (
  <ChakraUIInput
    bg="gray.50"
    focusBorderColor="brandSecondary.600"
    backgroundColor="white"
    {...props}
  />
);
