import React from 'react';

import { Flex } from '@chakra-ui/react';

export const CardContent = (props) => (
  <Flex
    flexDirection="column"
    flex={1}
    w="100%"
    overflowWrap="break-word"
    overflow="hidden"
    {...props}
  />
);
