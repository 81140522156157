import { company, address, commerce, internet } from 'faker';
import { Factory } from 'miragejs';

export const agencyFactory = Factory.extend({
  id(i) {
    return parseInt(i + 1, 10);
  },
  name: () => `${company.companyName()}`,
  email: () => internet.email(),
  address: {
    city: () => address.city(),
    zipCode: () => address.zipCode(),
    streetAddress: () => address.streetAddress(),
  },
  lowestPrice: () => parseInt(commerce.price(1, 150), 10),
  highestPrice: () => parseInt(commerce.price(150), 10),
});
