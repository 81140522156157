import React from 'react';

import {
  Box,
  Flex,
  Heading,
  useBreakpointValue,
  useToast,
  Center,
  Spinner,
  IconButton,
  Button,
} from '@chakra-ui/react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, useHistory, useParams } from 'react-router-dom';

import {
  Badge,
  Header,
  HeaderLeftAction,
  HeaderRightAction,
  HeaderTitle,
} from '@/components';
import { Page, AppFooter } from '@/layout';
import { useInvoiceRequest } from '@/services/invoiceRequest';

import { InvoiceRequestInformation } from './_partials/InvoiceRequestInformation';
import { ResponseCard } from './_partials/ResponseCard';

export const BookingDetail = () => {
  const isWide = useBreakpointValue({ base: false, md: true });
  const toast = useToast();
  const { bookingId: id } = useParams();
  const history = useHistory();
  const {
    data: invoiceRequestDetail,
    isLoading,
    isSuccess,
  } = useInvoiceRequest({
    params: { id },
    onError: (err) => {
      if (err.response.status === 403) {
        return;
      }
      toast({
        title:
          'Une erreur est survenue lors de la recherche des demandes de devis',
        status: 'error',
      });
    },
  });

  return (
    <Page
      px="0"
      mb={0}
      overflowX="hidden"
      background="url('/images/wave.svg') top center no-repeat"
      backgroundColor="gray.100"
      backgroundSize="contain"
      position="relative"
    >
      <Header position="fixed">
        <Box maxWidth="45em" position="relative" width="100%" h="100%">
          <HeaderLeftAction>
            <IconButton
              onClick={() => history.push('/bookings')}
              colorScheme="gray"
              color="gray.400"
              variant="ghost"
              icon={<FaArrowLeft />}
            />
          </HeaderLeftAction>

          <HeaderTitle>Détails de la demande</HeaderTitle>
          <HeaderRightAction />
        </Box>
      </Header>

      <Box position="relative">
        <Flex
          mt="5"
          flexGrow="1"
          flexDirection="column"
          w="100%"
          maxWidth="45em"
          mx="auto"
        >
          <Flex px="2" justifyContent="space-between" alignItems="center">
            <Heading as="h2" size="lg">
              Votre demande
            </Heading>
          </Flex>
          <Box position="relative" px="2" py={{ base: 20, md: 0 }}>
            <Box
              as="img"
              src="/images/greenWomen.svg"
              alt=""
              position="absolute"
              top={{ base: 0, md: '3vw' }}
              right={{ base: 0, md: '-13vw' }}
            />
            <Box
              d={{ base: 'none', md: 'block' }}
              as="img"
              src="/images/grid.svg"
              alt=""
              position="absolute"
              top="8vw"
              left="-13vw"
            />
            {!!invoiceRequestDetail && (
              <InvoiceRequestInformation
                invoiceRequest={invoiceRequestDetail}
              />
            )}
            {!!invoiceRequestDetail?.responses?.length &&
              invoiceRequestDetail.responses?.map((invResp) => (
                <ResponseCard
                  key={invResp?.id}
                  invoiceRequestId={invoiceRequestDetail?.id}
                  invoiceRequestResponse={invResp}
                  mt={4}
                />
              ))}
          </Box>
          {isLoading && (
            <Center>
              <Spinner
                thickness="4px"
                emptyColor="gray.200"
                color="brandSecondary.600"
                size="xl"
              />
            </Center>
          )}
          {!isLoading && isSuccess && !invoiceRequestDetail && (
            <Center h={40} flexDir="column">
              <Badge
                textAlign="center"
                size="lg"
                mt={10}
                py={4}
                colorScheme="warning"
              >
                Vous ne pouvez pas accepter ce devis
              </Badge>
              <Button
                as={Link}
                variant="link"
                to="/bookings"
                colorScheme="brandSecondary"
                mt={4}
              >
                Retournez vers la liste des demandes
              </Button>
            </Center>
          )}
        </Flex>
      </Box>

      {!isWide ? <AppFooter /> : null}
    </Page>
  );
};
