import Axios from 'axios';
import { useQuery } from 'react-query';

// export const useAppointments = ({ params, ...config } = {}) =>
//   useQuery(
//     ['appointments', params],
//     () =>
//       Axios.get('/appointments', {
//         params,
//       }),
//     config
//   );

export const useAppointment = ({ params, ...config } = {}) =>
  useQuery(
    [`appointment`, params?.appointmentId],
    () =>
      Axios.get(`/appointment/${params?.appointmentId}`, {
        params,
      }),
    { retry: 0, ...config }
  );
