import React from 'react';

import {
  Button,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';

import { Link } from '@/components';
import { BOOKING_REQUEST_STORAGE_KEY } from '@/constants/invoiceRequest';
import { useLocalStorage } from '@/services/localStorage';

export const AbortRequestModal = ({ title, helper, isOpen, onClose }) => {
  const [, setBookingRequest] = useLocalStorage(
    BOOKING_REQUEST_STORAGE_KEY,
    ''
  );
  const modalSize = useBreakpointValue({
    base: 'xs',
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'md',
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
      <ModalOverlay />
      <ModalContent>
        <IconButton
          aria-label="Fermer"
          colorScheme="brandSecondary"
          variant="ghost"
          position="absolute"
          top="0.5em"
          right="0.75em"
          onClick={onClose}
          icon={<FaTimes />}
        />
        <ModalBody>
          <Heading as="h3" size="md" pt={2}>
            {title}
          </Heading>
          <Text as="h4" size="sm" mt={4}>
            {helper}
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="brandSecondary"
            variant="ghost"
            mr={3}
            onClick={onClose}
          >
            Annuler
          </Button>
          <Button
            colorScheme="danger"
            as={Link}
            style={{
              textDecoration: 'none',
            }}
            to="/"
            onClick={() => setBookingRequest(null)}
          >
            Abandonner
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

AbortRequestModal.propTypes = {
  title: PropTypes.node,
  helper: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

AbortRequestModal.defaultProps = {
  title: '',
  helper: '',
  isOpen: false,
  onClose: () => {},
};
