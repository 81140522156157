import React, { useEffect, useState } from 'react';

import { Heading, IconButton, Text } from '@chakra-ui/react';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import PropTypes from 'prop-types';
import { FaTrash } from 'react-icons/fa';

import {
  Card,
  CardContent,
  CardRightElement,
  FormGroup,
  Uploader,
} from '@/components';

export const FieldUploaderMulti = (props) => {
  const {
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    errorMessage,
  } = useField(props);

  const {
    label,
    helper,
    title,
    isDisabled,
    required,
    formatValue,
    keepValue,
    asyncValidations,
    children,
    maxFileSizeLabel,
    accept,
    acceptLabel,
    isMultiple,
    ...otherProps
  } = props;

  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  const formGroupProps = {
    htmlFor: id,
    label,
    helper,
    errorMessage,
    showError,
    isRequired: !!required,
    isDisabled,
    ...otherProps,
  };

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const handleChange = (e) => {
    const { target } = e;
    const { files: uploadedFiles } = target || { files: null };
    if (!uploadedFiles) return;

    const uploadedFilesArray = Array.from(uploadedFiles) || [];
    setValue([...(value || []), ...uploadedFilesArray]);
  };

  const handleBlur = () => {
    if (isTouched) return;
    setIsTouched(true);
  };

  const handleRemove = (removedFile) => () => {
    const filesSelected = value.map((item) => item);
    const fileIndex = filesSelected.findIndex(
      (file) =>
        file.name === removedFile.name &&
        file.lastModified === removedFile.lastModified &&
        file.type === removedFile.type &&
        file.size === removedFile.size
    );

    if (fileIndex === -1) return;
    filesSelected.splice(fileIndex, 1);
    setValue([...filesSelected]);
  };

  const formatFileSize = (size) =>
    size > 1000000
      ? `${Math.round(size / 100000) / 10} Mo`
      : `${Math.round(size / 100) / 10} Ko`;

  return (
    <>
      <FormGroup {...formGroupProps}>
        <Uploader
          id={id}
          value={value ?? ''}
          onChange={handleChange}
          onBlur={handleBlur}
          title={title}
          aria-invalid={showError}
          aria-required={!!required}
          aria-describedby={showError ? `${id}-error` : null}
          isDisabled={isDisabled}
          inputProps={{
            ...(accept ? { accept } : {}),
            multiple: isMultiple,
          }}
        >
          {children}
        </Uploader>
        {maxFileSizeLabel}
        {acceptLabel}
      </FormGroup>
      {value && value.length ? (
        <FormGroup
          mt="10"
          label={`Document${value.length > 1 ? 's' : ''} ajouté${
            value.length > 1 ? 's' : ''
          }`}
        >
          {value.map((file, index) => (
            <Card
              key={file.lastModified}
              {...(index === 0 ? { mt: 3 } : {})}
              {...(index !== value.length - 1 ? { mb: 2 } : {})}
              minH="auto"
            >
              <CardContent>
                <Heading as="h2" size="md" overflowWrap="anywhere">
                  {file.name}
                </Heading>
                <Text>{formatFileSize(file.size)}</Text>
              </CardContent>
              <CardRightElement>
                <IconButton
                  onClick={handleRemove(file)}
                  colorScheme="brandSecondary"
                  variant="ghost"
                  icon={<FaTrash />}
                />
              </CardRightElement>
            </Card>
          ))}
        </FormGroup>
      ) : (
        ''
      )}
    </>
  );
};

FieldUploaderMulti.propTypes = {
  label: PropTypes.string,
  helper: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
  children: PropTypes.node,
  maxFileSizeLabel: PropTypes.node,
  accept: PropTypes.string,
  acceptLabel: PropTypes.node,
  isMultiple: PropTypes.bool,
  ...fieldPropTypes,
};

FieldUploaderMulti.defaultProps = {
  label: '',
  helper: '',
  title: '',
  type: 'text',
  isDisabled: false,
  children: null,
  maxFileSizeLabel: '',
  accept: null,
  acceptLabel: '',
  isMultiple: false,
  ...fieldDefaultProps,
};
