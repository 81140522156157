import React from 'react';

import { Box, Flex, Spacer } from '@chakra-ui/react';
import { useForm } from '@formiz/core';

import { FieldInput } from '@/components';
import { BOOKING_REQUEST_DATE_TIME } from '@/constants/bookingRequest';
import { AppointmentHeading } from '@/pages/BookingRequest/_partials/AppointmentHeading';
import { HelpIconButton } from '@/pages/BookingRequest/_partials/HelpIconButton';
import { useDateTimeRepeater } from '@/services/bookingRequest';

import { AppointmentButtonPlaceHolder } from './AppointmentButtonPlaceHolder';
import {
  AppointmentFreeDateRepeaterForm,
  hasDateOverlap,
} from './AppointmentFreeDateRepeaterForm';

export const AppointmentFreeDateInformation = () => {
  const form = useForm({
    subscribe: {
      form: true,
      fields: ['appointment.datetime.dates'],
    },
  });
  const {
    dateCollection: meetingDateCollection,
    dateFormRef,
    deleteSelectedDate,
    addDate: addFreeDateMeeting,
  } = useDateTimeRepeater({
    dateCount: 3,
    dateType: BOOKING_REQUEST_DATE_TIME.FREE_DATE.id,
  });

  const { values } = form;

  return (
    <>
      <Flex>
        <AppointmentHeading
          title="Dates libres du rendez-vous"
          helper="Choisissez 1 à 3 dates et proposez-les pour votre rendez-vous"
        />
        <Spacer />
        <HelpIconButton />
      </Flex>
      <Box>
        <>
          {meetingDateCollection?.map((meetingDate, i) => (
            <AppointmentFreeDateRepeaterForm
              ref={(el) => {
                dateFormRef.current[i] = el;
              }}
              key={meetingDate.id}
              index={i}
              deleteButtonProps={{
                isDisabled: meetingDateCollection?.length === 1,
                onClick: () => deleteSelectedDate(meetingDate.id),
              }}
            />
          ))}
          <AppointmentButtonPlaceHolder
            onClick={addFreeDateMeeting}
            selectedDateCount={meetingDateCollection?.length}
            isDisabled={meetingDateCollection?.length >= 3}
          />
          <FieldInput
            type="hidden"
            name="helperDateText"
            helper="La durée d'un rendez-vous est d'au moins une heure"
            validations={[
              {
                rule: () => hasDateOverlap(values?.appointment.datetime.dates),
                message:
                  'Il y a un conflit de rendez-vous. Veuillez sélectionner une plage horaire différente.',
                deps: [values?.appointment.datetime],
              },
            ]}
            mt={4}
          />
        </>
      </Box>
    </>
  );
};
