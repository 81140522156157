import React, { useEffect, useContext } from 'react';

import { Flex } from '@chakra-ui/react';

import { CardContext } from '.';

export const CardRightElement = (props) => {
  const { hasFormElement, setHasRightElement } = useContext(CardContext);

  useEffect(() => {
    setHasRightElement(true);
    return () => setHasRightElement(false);
  }, [setHasRightElement]);

  return (
    <Flex
      flexDirection="column"
      align="flex-end"
      pl={4}
      {...(hasFormElement ? { mt: 6 } : {})}
      {...props}
    />
  );
};
