import React from 'react';

import { Box, IconButton, useDisclosure } from '@chakra-ui/react';
import { FaTimes } from 'react-icons/fa';

import { Header, HeaderTitle, HeaderRightAction } from '@/components';
import { AbortRequestModal } from '@/pages/_partials/AbortRequestModal';

export const InvoiceRequestHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Header position="fixed" top="0" left="0" right="0">
        <HeaderTitle>Demander un devis</HeaderTitle>
        <Box position="relative" w="100%" maxWidth="45em" h="100%">
          <HeaderRightAction right={0}>
            <IconButton
              onClick={onOpen}
              colorScheme="gray"
              color="gray.400"
              variant="ghost"
              icon={<FaTimes />}
            />
          </HeaderRightAction>
        </Box>
      </Header>

      <AbortRequestModal
        title="Êtes-vous sûr de vouloir abandonner le processus de réservation ?"
        helper="Toutes les informations que vous avez renseignées seront perdues."
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};
