import React from 'react';

import { Button, Flex } from '@chakra-ui/react';
import { useForm } from '@formiz/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Footer } from '@/components';
import { MAIN_STEPS } from '@/constants/steps';

export const InvoiceRequestFooter = ({ isSubmitDisabled, ...otherProps }) => {
  const form = useForm();
  const history = useHistory();
  const handleGoBack = () => {
    if (form.isFirstStep) {
      history.push({
        pathname: '/booking-request',
        state: { goToSummaryStep: MAIN_STEPS.SUMMARY },
      });
      return;
    }
    form.prevStep();
  };

  return (
    <Footer h={16} {...otherProps}>
      <Flex
        justifyContent="space-between"
        flexGrow={1}
        marginX="auto"
        maxWidth="45em"
      >
        <Button
          onClick={handleGoBack}
          width={{ base: '100%', md: 'auto' }}
          colorScheme="gray"
        >
          Précédent
        </Button>
        <Button
          type="submit"
          width={{ base: '100%', md: 'auto' }}
          colorScheme="brandSecondary"
          isDisabled={
            (form.isStepSubmitted && !form.currentStep.isValid) ||
            isSubmitDisabled
          }
        >
          {form.isLastStep ? 'Envoyer la demande de devis' : 'Suivant'}
        </Button>
      </Flex>
    </Footer>
  );
};

InvoiceRequestFooter.propTypes = {
  isSubmitDisabled: PropTypes.bool,
};

InvoiceRequestFooter.defaultProps = {
  isSubmitDisabled: false,
};
