/* eslint-disable max-len */
import React from 'react';

import { Box } from '@chakra-ui/react';

export const User = (props) => (
  <Box {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 26"
      width="100%"
      height="100%"
    >
      <path
        fill="currentColor"
        d="M3.74453 15.9648C4.475 15.3246 5.35586 14.7703 6.37422 14.3191C6.8082 14.1258 7.31524 14.3234 7.50859 14.7574C7.70195 15.1914 7.5043 15.6984 7.07031 15.8918C6.21523 16.2742 5.47617 16.7297 4.87891 17.2582C4.13984 17.907 3.71875 18.8438 3.71875 19.832V21.4219C3.71875 21.8945 4.10547 22.2812 4.57812 22.2812H21.4219C21.8945 22.2812 22.2812 21.8945 22.2812 21.4219V19.832C22.2812 18.8438 21.8602 17.9027 21.1211 17.2582C20.2531 16.4934 17.7223 14.7188 13 14.7188C9.49375 14.7188 6.64062 11.8656 6.64062 8.35938C6.64062 4.85313 9.49375 2 13 2C16.5062 2 19.3594 4.85313 19.3594 8.35938C19.3594 10.409 18.384 12.2352 16.8715 13.3996C19.6387 14.0055 21.3574 15.1742 22.2555 15.9648C23.3641 16.9402 24 18.3496 24 19.832V21.4219C24 22.8441 22.8441 24 21.4219 24H4.57812C3.15586 24 2 22.8441 2 21.4219V19.832C2 18.3496 2.63594 16.9402 3.74453 15.9648ZM17.6406 8.35938C17.6406 5.79844 15.5609 3.71875 13 3.71875C10.4391 3.71875 8.35938 5.79844 8.35938 8.35938C8.35938 10.9203 10.4391 13 13 13C15.5609 13 17.6406 10.9203 17.6406 8.35938Z"
      />
    </svg>
  </Box>
);
