import React from 'react';

import { IconButton } from '@chakra-ui/react';

import { Help } from '@/assets/icons/Help';
import { Icon } from '@/components';

import { helpIconContext } from './HelpIconProvider';

export const HelpIconButton = (props) => {
  const { onOpenPreviewModal } = React.useContext(helpIconContext);
  return (
    <IconButton
      size="lg"
      mt="10px"
      colorScheme="brandSecondary"
      icon={<Icon icon={Help} fontSize="3rem" />}
      borderRadius="spot.variant1"
      onClick={onOpenPreviewModal}
      {...props}
    />
  );
};
