import { name, internet, random, address } from 'faker';
import { Factory } from 'miragejs';

export const userFactory = Factory.extend({
  id: random.uuid,
  firstName: name.firstName,
  lastName: name.lastName,
  email: internet.email,
  location: () => ({
    city: address.city(),
    zipCode: address.zipCode(),
    streetAddress: address.streetAddress(),
  }),
});
