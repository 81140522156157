import { extendTheme } from '@chakra-ui/react';

import { Badge } from './components/badge';
import { Button } from './components/button';
import { Checkbox } from './components/checkbox';
import { Form } from './components/form';
import { Input } from './components/input';
import { Radio } from './components/radio';
import { Textarea } from './components/textarea';
import { colors } from './foundations/colors';
import { fonts } from './foundations/fonts';
import { radii } from './foundations/radii';
import { shadows } from './foundations/shadows';
import { styles } from './styles';

const overrides = {
  radii,
  colors,
  fonts,
  shadows,
  styles,
  components: {
    Badge,
    Button,
    Checkbox,
    Form,
    Input,
    Textarea,
    Radio,
    Popover: {
      parts: ['popper'],
      baseStyle: (props) => ({
        popper: {
          zIndex: 10,
          maxW: props.width ? props.width : '100%',
          w: '100%',
        },
      }),
    },
  },
};

export const customTheme = extendTheme(overrides);
