import React from 'react';

import { Badge as ChakraUIBadge, Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { Icon } from '../Icon';

export const Badge = ({
  icon,
  iconProps,
  iconAfterContent,
  contentProps,
  children,
  ...props
}) => {
  const getStyles = () => {
    if (!icon) return {};
    if (iconAfterContent) return { mr: '1em' };
    if (!iconAfterContent) return { ml: '1em' };

    return {};
  };

  return (
    <ChakraUIBadge
      position="relative"
      textTransform="initial"
      borderRadius="full"
      px="0.6em"
      py="0.15em"
      {...props}
    >
      {icon && (
        <BadgeIcon
          iconAfterContent={iconAfterContent}
          icon={icon}
          {...iconProps}
        />
      )}
      <Box {...getStyles()} {...contentProps}>
        {children}
      </Box>
    </ChakraUIBadge>
  );
};

Badge.propTypes = {
  icon: PropTypes.func,
  iconProps: PropTypes.object, // eslint-disable-line
  iconAfterContent: PropTypes.bool,
  contentProps: PropTypes.object, // eslint-disable-line
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

Badge.defaultProps = {
  icon: null,
  iconProps: {},
  iconAfterContent: false,
  contentProps: {},
  children: null,
};

const BadgeIcon = ({ icon, iconAfterContent, ...props }) => {
  const getStyles = () => {
    if (iconAfterContent) {
      return { right: 0, mr: '0.4em' };
    }

    return { left: 0, ml: '0.4em' };
  };

  return (
    <Icon
      icon={icon}
      position="absolute"
      top="50%"
      transform="translate(0, -50%)"
      {...getStyles()}
      {...props}
    />
  );
};

BadgeIcon.propTypes = {
  icon: PropTypes.func,
  iconAfterContent: PropTypes.bool,
};

BadgeIcon.defaultProps = {
  icon: null,
  iconAfterContent: false,
};
