import React, { useState } from 'react';

import { Heading, IconButton, Text } from '@chakra-ui/react';
import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import PropTypes from 'prop-types';
import { FaTrash } from 'react-icons/fa';

import {
  Card,
  CardContent,
  CardRightElement,
  FormGroup,
  Uploader,
} from '@/components';

export const MEGA = 1000 * 1000;
const formatFileSize = (size) =>
  size > MEGA
    ? `${Math.round(size / MEGA)} Mo`
    : `${Math.round(size / 1000)} Ko`;

export const FieldUploader = (props) => {
  const { id, isValid, setValue, value, errorMessage } = useField(props);

  const {
    label,
    helper,
    title,
    isDisabled,
    required,
    formatValue,
    keepValue,
    asyncValidations,
    children,
    maxFileSizeLabel,
    accept,
    acceptLabel,
    onChange,
    isDisabledTrash,
    onTrashClick,
    maxFileSize,
    maxFileUploadError = `Le fichier a dépassé la taille maximale ${formatFileSize(
      maxFileSize
    )}`,
    ...otherProps
  } = props;

  const [showErrorSize, setShowErrorSize] = useState(false);
  const showError = !isValid;

  const formGroupProps = {
    htmlFor: id,
    label,
    helper,
    errorMessage,
    showError,
    isRequired: !!required,
    isDisabled,
    ...otherProps,
  };

  const handleChange = (e) => {
    setShowErrorSize(false);
    if (onChange) {
      onChange(e);
    }
    const { target } = e;
    const { files: uploadedFiles } = target || { files: null };
    if (!uploadedFiles) return;

    const uploadedFilesArray = Array.from(uploadedFiles) || [null];
    if (!(!!maxFileSize && uploadedFilesArray[0].size >= maxFileSize)) {
      setValue(uploadedFilesArray[0]);
    } else {
      setShowErrorSize(true);
    }
  };

  const handleRemove = () => {
    onTrashClick();
    setValue(null);
  };

  return (
    <FormGroup {...formGroupProps}>
      {value ? (
        <Card key={value.lastModified} minH="auto">
          <CardContent>
            <Heading as="h2" size="md" overflowWrap="anywhere">
              {value.name}
            </Heading>
            <Text>{formatFileSize(value.size)}</Text>
          </CardContent>
          <CardRightElement>
            <IconButton
              isDisabled={isDisabledTrash}
              onClick={handleRemove}
              colorScheme="brandSecondary"
              variant="ghost"
              icon={<FaTrash />}
            />
          </CardRightElement>
        </Card>
      ) : (
        <>
          <Uploader
            id={id}
            value={value ?? null}
            onChange={handleChange}
            title={title}
            aria-invalid={showError}
            aria-required={!!required}
            aria-describedby={showError ? `${id}-error` : null}
            isDisabled={isDisabled}
            inputProps={{
              ...(accept ? { accept } : {}),
            }}
          >
            {children}
          </Uploader>
          {maxFileSizeLabel}
          {acceptLabel}
          {showErrorSize && (
            <Text color="gray.400" fontSize={12}>
              {maxFileUploadError}
            </Text>
          )}
        </>
      )}
    </FormGroup>
  );
};

FieldUploader.propTypes = {
  label: PropTypes.string,
  helper: PropTypes.node,
  title: PropTypes.string,
  type: PropTypes.string,
  isDisabled: PropTypes.bool,
  isDisabledTrash: PropTypes.bool,
  children: PropTypes.node,
  maxFileSizeLabel: PropTypes.node,
  accept: PropTypes.string,
  acceptLabel: PropTypes.node,
  onTrashClick: PropTypes.func,
  ...fieldPropTypes,
};

FieldUploader.defaultProps = {
  label: '',
  helper: '',
  title: '',
  type: 'text',
  isDisabled: false,
  isDisabledTrash: false,
  children: null,
  maxFileSizeLabel: '',
  accept: null,
  acceptLabel: '',
  onTrashClick: () => {},
  ...fieldDefaultProps,
};
