import React from 'react';

import { Box, forwardRef } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const Icon = forwardRef(({ icon: IconEl, iconProps, ...props }, ref) => (
  <Box
    as="span"
    ref={ref}
    d="inline-block"
    position="relative"
    w="1em"
    flex="none"
    _before={{
      content: '"."',
      visibility: 'hidden',
    }}
    {...props}
  >
    <Box
      as={IconEl}
      w="1em"
      h="1em"
      position="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%, -50%)"
      {...iconProps}
    />
  </Box>
));

Icon.propTypes = {
  icon: PropTypes.func,
  iconProps: PropTypes.object, // eslint-disable-line
};

Icon.defaultProps = {
  icon: () => null,
  iconProps: {},
};
