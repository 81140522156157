import React from 'react';

import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
  Icon,
} from '@/components';
import {
  BOOKING_REQUEST,
  BOOKING_REQUEST_CATEGORY,
  BOOKING_REQUEST_SUBJECTS,
} from '@/constants/bookingRequest';
import { TIME_FORMAT } from '@/constants/date';
import { APPOINTMENT_CARD_ICONS } from '@/constants/meeting';
import { getAppointmentSubjectTextContent } from '@/services/utils';
import { formatAddress } from '@/utils/textUtils';

export const InvoiceRequestValidationInformation = ({
  invoiceRequest,
  ...otherProps
}) => {
  const { responseId } = useParams();
  const pricePosition = useBreakpointValue({ base: 8, sm: 1 });

  const {
    subject: type,
    startDate,
    endDate,
    appointmentAddress,
    responses,
  } = invoiceRequest;
  const subject = invoiceRequest[type];
  const address = formatAddress(appointmentAddress);

  const startDateObj = dayjs(startDate).isValid() ? dayjs(startDate) : null;
  const endDateObj = dayjs(endDate).isValid() ? dayjs(endDate) : null;
  const dateToDisplay = startDateObj
    ? startDateObj.format('DD MMMM YYYY')
    : 'N/A';
  const startTimeToDisplay = startDateObj
    ? startDateObj.format(TIME_FORMAT)
    : 'N/A';
  const endTimeToDisplay = endDateObj ? endDateObj.format(TIME_FORMAT) : 'N/A';

  const subjectName =
    BOOKING_REQUEST_SUBJECTS[BOOKING_REQUEST[type]]?.name || '';

  const iconProps =
    APPOINTMENT_CARD_ICONS[type] || APPOINTMENT_CARD_ICONS.DEFAULT;

  const appointmentSubjectContent = getAppointmentSubjectTextContent(subject);

  const invoicesRequestResponse = responses?.find(
    (res) => res?.id === responseId
  );

  const { price } = invoicesRequestResponse || {};

  return (
    <>
      <Card borderWidth="0" {...otherProps}>
        <CardContent position="relative">
          <Flex>
            <Flex
              justifyContent="center"
              alignItems="center"
              w="3rem"
              h="3rem"
              overflow="hidden"
              bg="brandSecondary.100"
              borderRadius="spot.variant1"
            >
              <Icon mt="1" color="brandSecondary.700" {...iconProps} />
            </Flex>
            <Box ml="3">
              <CardTitle size="lg">{dateToDisplay}</CardTitle>
              <CardTitle as="h3" size="md">
                {`${startTimeToDisplay} à ${endTimeToDisplay}`}
              </CardTitle>
            </Box>
          </Flex>
          <CardDescription h="auto">
            <Text fontSize="md" mt={4}>
              <Icon icon={FaMapMarkerAlt} color="brandSecondary.700" mr="2" />
              {address}
            </Text>
            <Box fontSize="md" mt={6}>
              <Text as="span">Rendez-vous :</Text>{' '}
              <Text as="span" fontWeight="bold">
                {invoiceRequest?.siret && invoiceRequest?.companyName
                  ? BOOKING_REQUEST_CATEGORY.PROFESSIONAL.name
                  : BOOKING_REQUEST_CATEGORY.PERSONAL.name}
              </Text>
              <Box fontWeight="bold">
                {`${subjectName ? ` ${subjectName}` : ''}${
                  appointmentSubjectContent[type] ? ' -' : ''
                }`}
                <Text as="span" fontWeight="bold">
                  {` ${appointmentSubjectContent[type] || ''}`}
                </Text>
              </Box>
            </Box>
          </CardDescription>
          {price && (
            <Box
              position="absolute"
              fontSize="md"
              top={pricePosition}
              right="0"
            >
              <Text fontWeight="bold">{`${price} € TTC`}</Text>
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};

InvoiceRequestValidationInformation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  invoiceRequest: PropTypes.object,
};

InvoiceRequestValidationInformation.defaultProps = {
  invoiceRequest: null,
};
