import React from 'react';

import { Box } from '@chakra-ui/react';

export const Professional = (props) => (
  <Box {...props}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3965 5.84434H16.0485L14.9721 3.56637C14.6725 2.93234 14.0702 2.53845 13.4002 2.53845H8.59987C7.92991 2.53845 7.32763 2.93234 7.02797 3.56637L5.95162 5.84434H2.6036C1.63458 5.84434 0.846191 6.67853 0.846191 7.70392V18.4481C0.846191 19.4735 1.63454 20.3077 2.6036 20.3077H19.3965C20.3655 20.3077 21.1539 19.4735 21.1539 18.4481V7.70392C21.1538 6.67853 20.3655 5.84434 19.3965 5.84434ZM8.07587 4.12083C8.17579 3.90947 8.37656 3.77819 8.59987 3.77819H13.4002C13.6235 3.77819 13.8243 3.90947 13.9242 4.12083L14.7386 5.84434H7.26151L8.07587 4.12083ZM4.16574 19.0679H2.6036C2.28058 19.0679 2.01781 18.7899 2.01781 18.4481V10.7135L4.16574 11.6963V19.0679V19.0679ZM16.6628 19.0679H5.33732V12.2324L8.42436 13.645C8.49773 13.6785 8.5769 13.6959 8.65686 13.6959H9.24265V15.1422C9.24265 15.7118 9.68062 16.1752 10.219 16.1752H11.7811C12.3195 16.1752 12.7574 15.7118 12.7574 15.1422V13.6959H13.3432C13.4232 13.6959 13.5023 13.6785 13.5757 13.645L16.6627 12.2324V19.0679H16.6628ZM10.4142 14.9356V13.6959H11.5858V14.9356H10.4142ZM19.9823 18.4481C19.9823 18.7899 19.7195 19.0679 19.3965 19.0679H17.8343V11.6963L19.9823 10.7135V18.4481ZM19.9823 9.36281C19.9807 9.36352 19.9823 9.36281 19.9807 9.36352L13.222 12.4562H8.77815C8.77661 12.4555 2.01947 9.36352 2.01789 9.36285V7.70397C2.01785 7.36217 2.28066 7.08412 2.60364 7.08412H15.683C15.6843 7.08412 15.6856 7.08425 15.6869 7.08425C15.6877 7.08425 15.6884 7.08412 15.6892 7.08412H19.3965C19.7195 7.08412 19.9823 7.36217 19.9823 7.70397V9.36281H19.9823Z"
        fill="#054952"
        stroke="#054952"
        strokeWidth="0.2"
      />
    </svg>
  </Box>
);
