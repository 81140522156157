import React from 'react';

import {
  Alert as ChakraUIAlert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  IconButton,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';

export const Alert = ({
  status,
  title,
  description,
  withCloseButton,
  onClick,
  ...otherProps
}) => {
  const getTextStyle = () => {
    switch (status) {
      case 'success':
        return { color: 'success.800' };
      case 'warning':
        return { color: 'warning.900' };
      case 'error':
        return { color: 'danger.800' };
      case 'info':
      default:
        return { color: 'blue.800' };
    }
  };

  const getButtonStyle = () => {
    switch (status) {
      case 'success':
        return { colorScheme: 'success' };
      case 'warning':
        return { colorScheme: 'warning' };
      case 'error':
        return { colorScheme: 'danger' };
      case 'info':
      default:
        return { colorScheme: 'blue' };
    }
  };

  return (
    <ChakraUIAlert status={status} {...otherProps}>
      <AlertIcon />
      <Box overflow="hidden" flex="1">
        {title && <AlertTitle {...getTextStyle()}>{title}</AlertTitle>}
        {description && (
          <AlertDescription
            textOverflow="ellipsis"
            display="block"
            {...getTextStyle()}
          >
            {description}
          </AlertDescription>
        )}
      </Box>
      {withCloseButton && (
        <IconButton
          icon={<FaTimes />}
          variant="ghost"
          onClick={onClick}
          {...getButtonStyle()}
        />
      )}
    </ChakraUIAlert>
  );
};

Alert.propTypes = {
  status: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  title: PropTypes.node,
  description: PropTypes.node,
  withCloseButton: PropTypes.bool,
  onClick: PropTypes.func,
};

Alert.defaultProps = {
  status: 'info',
  title: '',
  description: '',
  withCloseButton: false,
  onClick: () => {},
};
