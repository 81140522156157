export const inputTime = {
  'input[type=time]::-webkit-datetime-edit-hour-field:focus': {
    outline: 'none',
    backgroundColor: 'brandSecondary.300',
    color: 'white',
  },
  'input[type=time]::-webkit-datetime-edit-minute-field:focus': {
    outline: 'none',
    backgroundColor: 'brandSecondary.300',
    color: 'white',
  },
};
