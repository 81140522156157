import React from 'react';

import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
  useTheme,
  useDisclosure,
} from '@chakra-ui/react';
import { FaArrowRight, FaPlayCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { Icon, Logo, PreviewModal } from '@/components';
import { AppHeader, Page, AppFooter } from '@/layout';

import { IllustrationWomen } from '../_partials/IllustrationWomen';
import { LandingTitle } from './_partials/LandingTitle';

export const Home = () => {
  const isWide = useBreakpointValue({ base: false, md: true });
  const theme = useTheme();

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <AppHeader title="Bienvenue" />
      <Page
        px="0"
        pt={0}
        pb={0}
        mt="4.5rem"
        haveFooter={false}
        mb={0}
        position="relative"
        height="calc(100vh - 4.5rem)"
        style={{
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        <Box
          position="absolute"
          top="0"
          bottom={isWide ? '23rem' : '25rem'}
          left="0"
          right="0"
          {...(isWide
            ? {
                background: `url('/images/inversedWave.svg') ${
                  isWide ? 'bottom' : 'top'
                } center no-repeat`,
                backgroundSize: 'cover',
                maxHeight: { base: '32rem', lg: '35rem' },
              }
            : {})}
        />
        <Box height="auto">
          <Box
            position="relative"
            minH="35rem"
            overflow="hidden"
            {...(!isWide
              ? {
                  background:
                    "url('/images/inversedWave.svg') bottom center no-repeat",
                  backgroundColor: 'gray.50',
                  backgroundSize: 'contain',
                }
              : {})}
          >
            <Flex
              flexGrow="1"
              flexDirection="column"
              w="100%"
              maxWidth="45em"
              mx="auto"
            >
              <Box
                {...(isWide
                  ? {
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }
                  : {})}
                position="relative"
                px="2"
                pt={{ base: 16, md: 0 }}
                minH={{ base: 'auto', md: '350px' }}
              >
                <Center mt={{ base: 10, md: 6 }} flexDir="column">
                  <Box
                    as="img"
                    src="/images/greenWomen.svg"
                    alt=""
                    position="absolute"
                    top={{ base: -4, md: '20vw', lg: '9vw' }}
                    right={{ base: 0, md: '-13vw' }}
                    {...(!isWide
                      ? {
                          position: 'static',
                          order: 'initial',
                          alignSelf: 'center',
                        }
                      : {})}
                    marginTop={{ base: '-5.5rem', md: 0 }}
                    display={{ base: 'block', md: 'none' }}
                  />
                  <Flex
                    flexDir="column"
                    {...(!isWide
                      ? {
                          backgroundColor: 'gray.50',
                          mt: -40,
                        }
                      : {})}
                  >
                    <Heading as="h2" size="lg" textAlign="center" mt={4}>
                      Réserver un interprète pour vos rendez-vous
                    </Heading>
                    <Text textAlign="center" mt={4} px={4}>
                      Trouver facilement un professionnel pour rendre accessible
                      vos rendez-vous au quotidien
                    </Text>
                    <Flex
                      justifyContent="center"
                      flexDir={{ base: 'column', md: 'row' }}
                      w={{ base: 'auto', md: '100%' }}
                      mt={4}
                      zIndex={1}
                    >
                      <Box alignSelf="center">
                        <Button
                          leftIcon={
                            <Icon icon={FaPlayCircle} color="brand.800" />
                          }
                          bg="brand.200"
                          variant="solid"
                          color="brand.800"
                          fontSize="0.875rem"
                          fontFamily={theme.fonts.body}
                          fontWeight={500}
                          _hover={{
                            bg: 'brand.400',
                          }}
                          _focus={{
                            boxShadow: 'outline',
                          }}
                          _active={{
                            bg: 'brand.400',
                          }}
                          mx={4}
                          my={2}
                          onClick={onOpen}
                        >
                          Comment ça marche ?
                        </Button>
                      </Box>
                      <Box alignSelf="center">
                        <Button
                          rightIcon={<Icon icon={FaArrowRight} color="white" />}
                          as={Link}
                          to="/booking-request"
                          bg="brand.700"
                          variant="solid"
                          color="white"
                          fontSize="0.875rem"
                          fontWeight={500}
                          _hover={{
                            bg: 'brand.800',
                          }}
                          _focus={{
                            boxShadow: 'outline',
                          }}
                          _active={{
                            bg: 'brand.800',
                          }}
                          mx={4}
                          my={2}
                        >
                          Trouver un interprète
                        </Button>
                      </Box>
                    </Flex>
                  </Flex>
                </Center>
                <Box
                  as="img"
                  src="/images/grid.svg"
                  alt=""
                  position="absolute"
                  d={{ base: 'none', md: 'block' }}
                  top={{ base: '12vw', md: '22vw', lg: '11vw' }}
                  left="-13vw"
                />
              </Box>
            </Flex>
          </Box>
          <Flex flexDirection="column" mt={{ base: 10, md: 0 }}>
            <Box w="100%" maxWidth="45em" alignSelf="center">
              <Center flexDir="column">
                <Heading as="h2" size="md" textAlign="center">
                  Pourquoi réserver sur bamako réservations ?
                </Heading>
                <Box
                  as="img"
                  src="/images/logoMBrand.svg"
                  alignSelf="center"
                  mt="3"
                />
                <Text textAlign="center" mt={4} px={4} fontSize="15px">
                  bamako réservations est un outil au service de tous ceux qui
                  veulent trouver un interprète en langue des signes en France.
                  bamako réservations s’assure de vous mettre en relation avec
                  les agences les plus proches répondant à votre besoin.
                </Text>
              </Center>
            </Box>
            <SimpleGrid
              mt={10}
              columns={{ base: 1, md: 3 }}
              maxWidth="62em"
              mx={{ base: 'auto', md: 4, lg: 'auto' }}
            >
              <LandingTitle
                number="1"
                title={
                  <>
                    Renseignez <Box as="br" /> le lieu et l’heure
                  </>
                }
                image="/images/time.svg"
              />
              <LandingTitle
                number="2"
                title={
                  <>
                    Indiquez le type <Box as="br" />
                    de rendez-vous
                  </>
                }
                image="/images/type.svg"
                mt={-8}
                circleTopPosition="6.68em"
                titleTopPosition="6.37em"
              />
              <LandingTitle
                number="3"
                title={
                  <>
                    Obtenez plusieurs devis <Box as="br" /> d&apos;interprètes
                    professionnels
                  </>
                }
                image="/images/invoice.svg"
                mt={{ base: -14, md: 0 }}
              />
            </SimpleGrid>
            <Center>
              <Button
                rightIcon={<Icon icon={FaArrowRight} color="white" />}
                as={Link}
                to="/booking-request"
                bg="brand.700"
                variant="solid"
                color="white"
                fontSize="0.875rem"
                fontWeight={500}
                _hover={{
                  bg: 'brand.800',
                }}
                _focus={{
                  boxShadow: 'outline',
                }}
                _active={{
                  bg: 'brand.800',
                }}
                mx={4}
                my={2}
              >
                Trouver un interprète
              </Button>
            </Center>
            <Box
              background="url('/images/pattern.png') repeat"
              backgroundSize="contain"
              minH="31.25em"
              bgColor="brandSecondary.500"
              mt={20}
              mb={20}
            >
              <Box
                bgColor="brandSecondary.500"
                color="brandSecondary.50"
                borderRadius="120px"
                height="80%"
                m="auto"
                my="4em"
                width="70%"
                p={10}
                position="relative"
                px={{ base: 2, md: 32 }}
                textAlign="inherit"
              >
                <Box
                  display="inline-block"
                  position=""
                  {...(isWide
                    ? { left: -10, position: 'absolute', top: -10 }
                    : {})}
                >
                  <Box display="inline-block" position="relative">
                    <Box as="img" src="/images/greenCircle.svg" alt="" />
                    <Box
                      as="img"
                      src="/images/hands.svg"
                      alt=""
                      position="absolute"
                      top="0"
                      left="0"
                    />
                  </Box>
                </Box>
                <Heading as="h3" size="md">
                  Qu’est-ce qu’un interprète en langue des signes ?
                </Heading>
                <Box as="img" src="/images/logoMWhite.svg" mx={0} mt="4" />
                <Box mt={6}>
                  Un interprète est un professionnel de la communication. Il
                  traduit du français parlé, vers la langue des signes et
                  inversement. Ainsi, les échanges entre des personnes ne
                  partageant pas la même langue peuvent avoir lieu.
                </Box>
                <Box mt={6}>
                  L’interprète en langue des signes française (LSF) est diplômé
                  d’un Master universitaire au même titre que ses confrères et
                  consœurs dans les autres langues. Il peut être compétent dans
                  d’autres langues signées ou vocales selon son parcours. Son
                  niveau de technicité et son cadre de formation assure la
                  protection des parties.
                </Box>
                <Box mt={6}>
                  Son champ d’intervention est très varié. En effet, selon que
                  la personne sourde ou malentendante a des besoins dans le
                  cadre de sa vie personnelle, professionnelle ou citoyenne ;
                  l’interprète peut intervenir pour rendre accessible un
                  rendez-vous médical, une présentation dans un collège, une
                  réunion de travail ou encore une conférence.
                </Box>
              </Box>
            </Box>
            <Center
              flexDir={{ base: 'column', md: 'row' }}
              mx={4}
              justifyContent={{ base: 'flex-start', md: 'space-around' }}
            >
              <Box
                as="img"
                src="/images/experience.svg"
                mx={{ base: 'auto', md: 6 }}
                height={{ base: 'auto', md: '350px', lg: 'auto' }}
                objectFit="cover"
                my="3"
              />
              <Box maxW="40em">
                <Heading as="h3" size="md">
                  Une expérience personnalisée
                </Heading>
                <Box as="img" src="/images/logoMBrand.svg" mx={0} mt="4" />
                <Box mt={6} fontFamily={theme.fonts.body}>
                  <Text mt={2}>
                    bamako réservations est l’outil qui répond à vos attentes.
                  </Text>
                  <Text mt={2}>
                    Selon le lieu du rendez-vous et vos besoins, bamako
                    réservations vous propose une liste d’agences
                    professionnelles pour assurer l’interprétation français -
                    langue des signes.
                  </Text>
                  <Text mt={2}>
                    Une fois les informations enregistrées, vous pouvez
                    consulter vos demandes de réservation et vos rendez-vous à
                    venir.
                  </Text>
                  <Text mt={2}>
                    Vous avez la possibilité de prendre contact avec l’agence
                    pour les détails de la prestation.
                  </Text>
                </Box>
              </Box>
            </Center>
            <Center
              flexDir={{ base: 'column-reverse', md: 'row' }}
              mt={20}
              mx={6}
              justifyContent={{ base: 'flex-start', md: 'space-around' }}
            >
              <Box maxW="40em">
                <Heading as="h3" size="md">
                  Simplifez vos démarches
                </Heading>
                <Box as="img" src="/images/logoMBrand.svg" mx={0} mt="4" />
                <Box mt={6} fontFamily={theme.fonts.body}>
                  <Text mt={2}>
                    Vous ne connaissez pas les agences du territoire, vous
                    souhaitez gagner du temps en centralisant les informations
                    de votre demande afin qu’elles soient transmises aux agences
                    ? Grâce à un parcours simple et adapté, bamako réservations
                    recueille votre demande et la transmet directement aux
                    agences d’interprètes de Trait d’union, 1er réseau
                    coopératif national.
                  </Text>
                  <Text mt={2}>
                    bamako réservations filtre les éléments pour trouver le
                    professionnel compétent adapté à vos besoins spécifiques.
                    Vous recevez des propositions, vous confirmez auprès de
                    l’interprète qui interviendra et vous pouvez retrouver
                    l’ensemble des informations.
                  </Text>
                  <Text mt={2}>
                    Qu’il s’agisse d’une demande classique (français / Langue
                    des Signes Française (LSF)) ou atypique (anglais/espagnol –
                    LSF / Signes Internationaux), bamako réservations s’appuie
                    sur un réseau d’interprètes qualifiés qui fédère de nombreux
                    professionnels dans chaque région. Ce service, développé par
                    le réseau Trait d’union, a été pensé en collaboration avec
                    un panel d’utilisateurs au service d’une meilleure
                    accessibilité pour les personnes souhaitant recourir aux
                    services d’un interprète en langue des signes.
                  </Text>
                </Box>
              </Box>
              <Box
                as="img"
                src="/images/booking.svg"
                mx={{ base: 'auto', md: 6 }}
                height={{ base: 'auto', md: '350px', lg: 'auto' }}
                objectFit="cover"
                my="3"
              />
            </Center>
          </Flex>
        </Box>
        <Flex
          height={{ base: 'auto', md: '4rem' }}
          mt={10}
          bgColor="brandSecondary.800"
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent={{ base: 'flex-start', md: 'space-around' }}
          alignItems="center"
          {...(!isWide
            ? {
                py: '4',
                px: '2',
              }
            : {})}
        >
          <Stack direction="row" spacing="2">
            <Logo width="2rem" />
            <Heading as="h3" size="sm" color="brand.50" alignSelf="center">
              Copyright © Trait d&apos;Union - 2021-2023
            </Heading>
          </Stack>
          <Box
            {...(!isWide
              ? {
                  display: 'flex',
                  flexDirection: 'column',
                }
              : {})}
          >
            <Button
              as={Link}
              _hover={{ backgroundColor: 'brandSecondary.700' }}
              variant="ghost"
              size="sm"
              color="brand.50"
              to="/pdf/cgu.pdf"
              target="_blank"
              {...(!isWide
                ? {
                    pl: 0,
                    mt: 2,
                  }
                : {})}
            >
              Conditions générales
            </Button>
            <Button
              as={Link}
              _hover={{ backgroundColor: 'brandSecondary.700' }}
              variant="ghost"
              size="sm"
              color="brand.50"
              to="/pdf/cgu.pdf"
              target="_blank"
              {...(!isWide
                ? {
                    pl: 0,
                    mt: 2,
                  }
                : {})}
            >
              Mentions légales
            </Button>
          </Box>
        </Flex>
        <IllustrationWomen illustration="/images/greenWomen.svg" mt="-2" />
        {!isWide ? <AppFooter /> : null}
      </Page>
      <PreviewModal
        onClose={onClose}
        isOpen={isOpen}
        url="videos/placeholder.mp4"
      />
    </>
  );
};
