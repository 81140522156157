import React from 'react';

import { Box, Center, Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const EmptyState = ({ children, ...otherProps }) => (
  <Center
    flexDir={{ base: 'column', md: 'row' }}
    // alignItems={{ base: 'center', md: 'flex-start' }}
    height={{ base: 'auto', md: '50vh' }}
    m={2}
    {...otherProps}
  >
    <Box
      as="img"
      src="/images/emptyState.svg"
      alt=""
      minWidth={{ base: 0, md: '25rem' }}
      maxW={{ base: '22rem', md: 'auto' }}
    />
    <Flex
      textAlign={{ base: 'center', md: 'left' }}
      pt={{ base: 0, md: 16 }}
      flexDir="column"
      maxW={{ base: 'auto', md: '23rem' }}
      ml={{ base: 0, md: '10' }}
    >
      {children}
    </Flex>
  </Center>
);

EmptyState.propTypes = {
  children: PropTypes.node,
};

EmptyState.defaultProps = {
  children: '',
};
