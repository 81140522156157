import React from 'react';

import { Box, Center, IconButton } from '@chakra-ui/react';
import { FaArrowLeft, FaCheckCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { Icon } from '@/components';
import { Page } from '@/layout';

import { VerificationMailHeading } from './VerificationMailHeading';

export const VerificationMailSent = () => {
  const history = useHistory();
  return (
    <Page d="flex" flexGrow={1} mt={0} bg="gray.50" haveFooter={false}>
      <Box maxW="45em" mx="auto">
        <IconButton
          icon={<FaArrowLeft />}
          color="gray.400"
          variant="ghost"
          onClick={() => history.push('login')}
        />

        <Center flexDirection="column" justifyContent="flex-start" flex={1}>
          <Icon
            icon={FaCheckCircle}
            color="brandSecondary.400"
            fontSize="5em"
            mt={10}
          />
          <VerificationMailHeading
            title="Mail de vérification envoyé !"
            helper="Un courriel de vérification vous a été envoyé.
            Si vous n’avez pas encore reçu le courriel, veuillez patienter."
            textAlign="center"
            mt={6}
          />
        </Center>
      </Box>
    </Page>
  );
};
