import React from 'react';

import { Button } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { Link } from '@/components';
import { useQueryParam } from '@/services/queryParam';

export const RegisterFooter = ({ isLoading }) => {
  const redirect = useQueryParam('redirect');

  return (
    <>
      <Button
        as={Link}
        to={{
          pathname: '/login',
          search: redirect ? `?redirect=${redirect}` : null,
        }}
        colorScheme="brandSecondary"
        variant="link"
      >
        Déjà un compte ?
      </Button>
      <Button
        type="submit"
        colorScheme="brandSecondary"
        w="100%"
        isDisabled={isLoading}
        isLoading={isLoading}
      >
        Inscription
      </Button>
    </>
  );
};

RegisterFooter.propTypes = {
  isLoading: PropTypes.bool,
};

RegisterFooter.defaultProps = {
  isLoading: false,
};
