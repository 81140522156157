export const colors = {
  // Go to https://smart-swatch.netlify.com/ to easily generate a new color palette.

  lightWhite: '#F7FAFB',

  gray: {
    50: '#FBFAF5',
    100: '#F6F5F0',
    200: '#E3E2DD',
    300: '#CFCEC9',
    400: '#9C9A95',
    500: '#5A5955',
    600: '#4D4A3E',
    700: '#403B2A',
    800: '#342D1B',
    900: '#2B2310',
  },

  success: {
    50: '#E2FDE9',
    100: '#BDF1D0',
    200: '#96E7A9',
    300: '#6EDD87',
    400: '#46D366',
    500: '#2CB94D',
    600: '#1F903B',
    700: '#146629',
    800: '#073F17',
    900: '#072A17',
  },

  warning: {
    50: '#FFFADD',
    100: '#FFF7CD',
    200: '#FFED9B',
    300: '#FFE16A',
    400: '#FFD545',
    500: '#FFC107',
    600: '#DBA005',
    700: '#B78103',
    800: '#936402',
    900: '#7A4F01',
  },

  danger: {
    50: '#FFF3FA',
    100: '#FFE5E9',
    200: '#FBBCAF',
    300: '#F48E85',
    400: '#EA6465',
    500: '#DC3545',
    600: '#BD2642',
    700: '#9E1A3F',
    800: '#7F1039',
    900: '#690A36',
  },

  brand: {
    50: '#FFFBEC',
    100: '#FCF6D3',
    200: '#F9ECA8',
    300: '#EFD87A',
    400: '#E0C257',
    500: '#CCA326',
    600: '#B58F36',
    700: '#9C6D00',
    800: '#75520C',
    900: '#614207',
  },

  brandSecondary: {
    50: '#E6FAF5',
    100: '#CBE6E2',
    200: '#99C9C5',
    300: '#6AADA8',
    400: '#3A908A',
    500: '#0B736D',
    600: '#086062',
    700: '#054952',
    800: '#033542',
    900: '#022737',
  },
};
