import React from 'react';

import { Button, Heading, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FaRegCalendarPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { EmptyState as EmptyStateComponent, Icon } from '@/components';

export const EmptyState = ({ title, helper, ...otherProps }) => {
  return (
    <EmptyStateComponent {...otherProps}>
      <Heading as="h2" size="lg" mt={{ base: 4, md: 0 }} mx={2}>
        {title}
      </Heading>
      <Text mt={6} mx={2} fontSize="14px">
        {helper}
      </Text>
      <Button
        as={Link}
        to="/booking-request"
        borderRadius="full"
        colorScheme="brandSecondary"
        variant="inverse"
        w="100%"
        py="6"
        px="10"
        style={{
          textDecoration: 'none',
        }}
        mt={6}
        alignSelf={{ base: 'center', md: 'inherit' }}
      >
        <Icon mr="2" icon={FaRegCalendarPlus} />
        Faire une nouvelle demande
      </Button>
    </EmptyStateComponent>
  );
};

EmptyState.propTypes = {
  title: PropTypes.string,
  helper: PropTypes.string,
};

EmptyState.defaultProps = {
  title: '',
  helper: '',
};
