import React, { Suspense } from 'react';

import { Spinner, ChakraProvider, Flex } from '@chakra-ui/react';
import ReactDOM from 'react-dom';

import { App } from '@/App';
import '@/config/axios';
import '@/config/dayjs';
import { UserProvider } from '@/context/user-context';
import { fakeServer } from '@/mocks/server';
import { customTheme } from '@/theme';

fakeServer();

const ScreenLoader = () => (
  <Flex w="100vw" h="100vh">
    <Spinner margin="auto" />
  </Flex>
);

ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <ChakraProvider theme={customTheme}>
        <Suspense fallback={<ScreenLoader />}>
          <App />
        </Suspense>
        {/* <ReactQueryDevtools /> */}
      </ChakraProvider>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
