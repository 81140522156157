import React from 'react';

import { IconButton, Text } from '@chakra-ui/react';
import { FaList, FaRegCalendarPlus, FaRegCalendar } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';

import { Footer, FooterMenuItem, Icon } from '@/components';
import { useUserContext } from '@/context/user-context';

export const AppFooter = (props) => {
  const location = useLocation();
  const { isLogged } = useUserContext();
  const isHomePage = location?.pathname === '/';
  const activeColor = isHomePage ? 'brand' : 'brandSecondary';

  return isLogged ? (
    <Footer
      position="fixed"
      left="0"
      right="0"
      bottom="0"
      h={16}
      px="0"
      py="0"
      alignItems="center"
      {...props}
    >
      <FooterMenuItem to="/agenda">
        <FaRegCalendar />
        <Text whiteSpace="pre-wrap" fontSize="0.75rem" fontWeight="500" mt={1}>
          Agenda
        </Text>
      </FooterMenuItem>
      <IconButton
        as={Link}
        to="/booking-request"
        size="lg"
        colorScheme={activeColor}
        icon={<Icon icon={FaRegCalendarPlus} fontSize="1.35rem" />}
        borderRadius="spot.variant1"
        _hover={{ borderRadius: 'spot.variant2' }}
      />
      <FooterMenuItem to="/bookings">
        <FaList />
        <Text whiteSpace="pre-wrap" fontSize="0.75rem" fontWeight="500" mt={1}>
          Demandes
        </Text>
      </FooterMenuItem>
    </Footer>
  ) : (
    ''
  );
};
