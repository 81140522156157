import React from 'react';

import { Button } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FaPen } from 'react-icons/fa';

import {
  Card,
  CardContent,
  CardDescription,
  CardLeftElement,
  CardRightElement,
  CardTitle,
  Icon,
  StepperNumber,
} from '@/components';

export const CardStep = ({
  stepNumber,
  title,
  onClick,
  children,
  cardDescriptionProps,
  cardContentProps,
  ...otherProps
}) => (
  <Card border="0" {...otherProps}>
    <CardLeftElement>
      <StepperNumber>{stepNumber}</StepperNumber>
    </CardLeftElement>
    <CardContent {...cardContentProps}>
      <CardTitle fontSize="md" color="gray.500">
        {title}
      </CardTitle>
      <CardDescription mt="1" {...cardDescriptionProps}>
        {children}
      </CardDescription>
    </CardContent>
    <CardRightElement align="flex-end">
      <Button colorScheme="brandSecondary" variant="link" onClick={onClick}>
        Modifier
        <Icon ml="2" icon={FaPen} />
      </Button>
    </CardRightElement>
  </Card>
);

CardStep.propTypes = {
  stepNumber: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  cardDescriptionProps: PropTypes.objectOf(PropTypes.any),
  cardContentProps: PropTypes.objectOf(PropTypes.any),
};

CardStep.defaultProps = {
  stepNumber: '',
  title: '',
  onClick: () => {},
  children: null,
  cardDescriptionProps: {},
  cardContentProps: {},
};
