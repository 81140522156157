import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import { Routes } from '@/Routes';
import { ViewportHeight } from '@/layout';

export const App = () => {
  return (
    <Router>
      <ViewportHeight>
        <Routes />
      </ViewportHeight>
    </Router>
  );
};
