import React from 'react';

import { Button } from '@chakra-ui/react';
import { useForm } from '@formiz/core';
import { useLocation } from 'react-router-dom';

import { Stepper, StepperItem } from '@/components';
import { FORM_STEPS, MAIN_STEPS } from '@/constants/steps';

const stepperItemProps = {
  as: Button,
  variant: 'unstyled',
  colorScheme: 'brandSecondary',
};

export const BookingRequestStepper = () => {
  const form = useForm({ subscribe: 'form' });
  const { state } = useLocation();
  const { goToSummaryStep: isFromInvoiceRequest } = state || {};
  const { currentStep, steps } = form || {};

  const getActiveStep = () => {
    const { name } = currentStep || { name: null };

    if (!name) return null;

    if (name.startsWith(MAIN_STEPS.LOCATION.name)) {
      return MAIN_STEPS.LOCATION.name;
    }

    if (name.startsWith(MAIN_STEPS.INFORMATION.name)) {
      return MAIN_STEPS.INFORMATION.name;
    }

    if (name.startsWith(MAIN_STEPS.DATETIME.name)) {
      return MAIN_STEPS.DATETIME.name;
    }

    if (name.startsWith(MAIN_STEPS.SUMMARY.name)) {
      return MAIN_STEPS.SUMMARY.name;
    }

    return null;
  };

  const isStepDisabled = (name) => {
    const formizStep =
      steps && steps.length ? steps.find((step) => step.name === name) : null;
    if (!formizStep) return true;
    return (
      !(formizStep.isVisited || formizStep.isCurrent) && !isFromInvoiceRequest
    );
  };

  return getActiveStep() !== MAIN_STEPS.SUMMARY.name ? (
    <Stepper activeStep={getActiveStep()} mt="9px">
      <StepperItem
        name={MAIN_STEPS.LOCATION.name}
        onClick={() => form.goToStep(FORM_STEPS.LOCATION.ADDRESS.name)}
        isDisabled={isStepDisabled(FORM_STEPS.LOCATION.ADDRESS.name)}
        fontSize="md"
        {...stepperItemProps}
      >
        Adresse
      </StepperItem>
      <StepperItem
        name={MAIN_STEPS.INFORMATION.name}
        onClick={() => form.goToStep(FORM_STEPS.INFORMATION.CATEGORY.name)}
        isDisabled={isStepDisabled(FORM_STEPS.INFORMATION.CATEGORY.name)}
        fontSize="md"
        {...stepperItemProps}
      >
        Thème
      </StepperItem>
      <StepperItem
        name={MAIN_STEPS.DATETIME.name}
        onClick={() => form.goToStep(FORM_STEPS.DATETIME.TYPE.name)}
        isDisabled={isStepDisabled(FORM_STEPS.DATETIME.TYPE.name)}
        fontSize="md"
        {...stepperItemProps}
      >
        Date et horaires
      </StepperItem>
    </Stepper>
  ) : null;
};
