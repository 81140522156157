import React from 'react';

import { Heading } from '@chakra-ui/react';

import { Link } from '@/components';
import { Page, AppHeader } from '@/layout';

export const Help = () => (
  <Page>
    <AppHeader title="Aide" />
    <Heading as="h1" size="2xl">
      Besoin d&apos;aide ?
    </Heading>
    <Link to="/">Retour sur la page d&apos;accueil</Link>
  </Page>
);
