import React from 'react';

import { FormizStep } from '@formiz/core';
import PropTypes from 'prop-types';

import { Card, CardContent } from '@/components';
import { BOOKING_INVOICE_REQUEST_ACCEPT_STEPS } from '@/constants/invoiceRequest';

import { AcceptResponseHeading } from '../AcceptResponseHeading';
import { InvoiceRequestValidationAgency } from './_partials/InvoiceRequestValidationAgency';
import { InvoiceRequestValidationInformation } from './_partials/InvoiceRequestValidationInformation';

export const InvoiceRequestValidation = ({ invoiceRequest }) => (
  <FormizStep
    {...BOOKING_INVOICE_REQUEST_ACCEPT_STEPS.INVOICE_REQUEST_VALIDATION}
  >
    <Card borderWidth="0">
      <CardContent>
        <AcceptResponseHeading
          title="Validation du devis"
          helper="Vous êtes sur le point de valider un devis pour votre demande d’interprète."
        />
      </CardContent>
    </Card>
    {!!invoiceRequest && (
      <InvoiceRequestValidationInformation
        invoiceRequest={invoiceRequest}
        mt={2}
      />
    )}
    {!!invoiceRequest && (
      <InvoiceRequestValidationAgency invoiceRequest={invoiceRequest} mt={2} />
    )}
  </FormizStep>
);

InvoiceRequestValidation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  invoiceRequest: PropTypes.object,
};

InvoiceRequestValidation.defaultProps = {
  invoiceRequest: null,
};
