import React, { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import { useForm } from '@formiz/core';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { FieldInput } from '@/components';

import { FilesUploadElement } from './FilesUploadElement';

const defaultCollection = [
  {
    id: uuid(),
    name: 'document',
  },
];

export const FileUploadRepeater = ({ helperText }) => {
  const [collection, setCollection] = useState(defaultCollection);
  const [elementId, setElementId] = useState(null);
  const [documents, setDocuments] = useState([]);
  const form = useForm();
  const { setFieldsValues } = form;
  const addItem = () => {
    const id = uuid();
    setCollection((c) => [
      ...c,
      {
        id,
      },
    ]);
    setElementId(id);
  };

  useEffect(() => {
    setFieldsValues({ documents: [...documents] });
  }, [documents, setFieldsValues]);

  useEffect(() => {
    if (elementId) {
      document.getElementById(elementId).scrollIntoView();
    }
  }, [elementId]);

  const removeItem = (id) => {
    setCollection((c) => c.filter((x) => x.id !== id));
  };

  return (
    <Box>
      {collection.map(({ id }, index) => (
        <FilesUploadElement
          key={id}
          id={id}
          index={index}
          addItem={addItem}
          removeItem={removeItem}
          collection={collection}
          setCollection={setCollection}
          helperText={helperText}
          setDocuments={setDocuments}
        />
      ))}
      <FieldInput name="documents" type="hidden" h="auto" mb="0" />
    </Box>
  );
};

FileUploadRepeater.propTypes = {
  helperText: PropTypes.node,
};

FileUploadRepeater.defaultProps = {
  helperText: '',
};
