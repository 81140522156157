import React from 'react';

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
  Button,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FiAlertTriangle } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

export const ModalForInvalidAccount = ({
  isModalValidateAccount,
  setIsModalValidateAccount,
}) => {
  const history = useHistory();
  return (
    <Modal
      isOpen={isModalValidateAccount}
      size="lg"
      onClose={() => setIsModalValidateAccount(false)}
      closeOnOverlayClick={false}
    >
      <ModalOverlay>
        <ModalContent bgColor="gray.50">
          <ModalHeader>
            <Flex>
              Validez votre compte{' '}
              <FiAlertTriangle style={{ width: '40px', height: '30px' }} />
            </Flex>
          </ModalHeader>
          <ModalBody>
            Merci de consulter votre messagerie pour valider votre compte et
            continuer votre prise de rendez-vous
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="brandSecondary"
              mr={3}
              onClick={() => {
                setIsModalValidateAccount(false);
                history.push('/');
              }}
            >
              Accueil
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};

ModalForInvalidAccount.propTypes = {
  isModalValidateAccount: PropTypes.bool,
  setIsModalValidateAccount: PropTypes.func,
};

ModalForInvalidAccount.defaultProps = {
  isModalValidateAccount: false,
  setIsModalValidateAccount: () => {},
};
