import React from 'react';

import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  VStack,
  useBreakpointValue,
  useToast,
  Center,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { FaRegCalendarPlus } from 'react-icons/fa';

import { Help } from '@/assets/icons/Help';
import { Icon, Link, PreviewModal } from '@/components';
import { Page, AppHeader, AppFooter } from '@/layout';
import { useInvoiceRequests } from '@/services/invoiceRequest';

import { EmptyState } from '../_partials/EmptyState';
import { IllustrationWomen } from '../_partials/IllustrationWomen';
import { InvoiceRequestCard } from './_partials/InvoiceRequestCard';

export const Bookings = () => {
  const isWide = useBreakpointValue({ base: false, md: true });
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: invoicesRequests, isLoading, isSuccess } = useInvoiceRequests({
    onError: (err) => {
      if (err.response.status === 403) {
        return;
      }
      toast({
        title:
          'Une erreur est survenue lors de la recherche des demandes de devis',
        status: 'error',
      });
    },
  });

  return (
    <>
      <AppHeader title="Demandes" />
      <Page
        px="0"
        mb={0}
        pb={isWide ? 0 : 16}
        overflowX="hidden"
        background="url('/images/wave.svg') top center no-repeat"
        backgroundColor="gray.100"
        backgroundSize="contain"
        position="relative"
        overflowY="scroll"
        height={{ base: 'calc(100vh - 8rem)', md: 'calc(100vh - 4rem)' }}
      >
        {!isLoading && isSuccess && !invoicesRequests?.length ? (
          <EmptyState
            title="Vous n’avez pas encore de demande de devis en cours"
            helper="Demandez un devis pour votre nouveau rendez-vous, à l’aide du bouton ci-dessous."
          />
        ) : (
          <>
            <IllustrationWomen
              illustration="/images/bookingRequestWomen.svg"
              mr="-72px"
              mt="-34px"
            />

            <Box position="relative" height="auto" zIndex={1}>
              <Flex
                mt="5"
                flexGrow="1"
                flexDirection="column"
                w="100%"
                maxWidth="45em"
                mx="auto"
              >
                <Flex px="6" justifyContent="space-between" alignItems="center">
                  <Heading as="h2" size="lg">
                    Demandes de devis
                  </Heading>
                  <Flex>
                    {isWide ? (
                      <Button
                        as={Link}
                        to="/booking-request"
                        borderRadius="full"
                        colorScheme="brandSecondary"
                        variant="inverse"
                        w="100%"
                        py="6"
                        px="10"
                        style={{
                          textDecoration: 'none',
                        }}
                        mr={4}
                      >
                        <Icon mr="2" icon={FaRegCalendarPlus} />
                        Faire une nouvelle demande
                      </Button>
                    ) : null}
                    <IconButton
                      size="lg"
                      colorScheme="brandSecondary"
                      icon={<Icon icon={Help} fontSize="3rem" />}
                      borderRadius="spot.variant1"
                      onClick={onOpen}
                      zIndex={4}
                    />
                  </Flex>
                </Flex>
                <Box position="relative" px="2" pt={{ base: 16, md: 0 }}>
                  <Box
                    as="img"
                    src="/images/bookingRequestWomen.svg"
                    alt=""
                    position="absolute"
                    top={{ base: '-11vw', md: '2vw' }}
                    {...(!isWide
                      ? {
                          left: '50%',
                          transform: 'translate(-50%, 0%)',
                        }
                      : {
                          right: '-16vw',
                        })}
                    display={{ base: 'block', md: 'none' }}
                  />
                  <Box
                    d={{ base: 'none', md: 'block' }}
                    as="img"
                    src="/images/grid.svg"
                    alt=""
                    position="absolute"
                    top="8vw"
                    left="-13vw"
                  />

                  {!isWide ? (
                    <Button
                      as={Link}
                      to="/booking-request"
                      mt="8"
                      borderRadius="full"
                      colorScheme="brandSecondary"
                      variant="inverse"
                      w="100%"
                      py="6"
                      style={{
                        textDecoration: 'none',
                      }}
                    >
                      <Icon mr="2" icon={FaRegCalendarPlus} />
                      Faire une nouvelle demande
                    </Button>
                  ) : null}
                </Box>
                {isLoading && (
                  <Center>
                    <Spinner
                      thickness="4px"
                      emptyColor="gray.200"
                      color="brandSecondary.600"
                      size="xl"
                    />
                  </Center>
                )}
                {!isLoading && isSuccess && !!invoicesRequests?.length && (
                  <VStack
                    flexGrow="1"
                    p="5"
                    px="2"
                    spacing={2}
                    mx={{ base: 4, md: 0 }}
                  >
                    {invoicesRequests
                      .sort((a, b) =>
                        dayjs(a?.startDate).isAfter(dayjs(b?.startDate))
                          ? 1
                          : -1
                      )
                      .map((invoiceRequest) => (
                        <InvoiceRequestCard
                          key={invoiceRequest.id}
                          invoiceRequest={invoiceRequest}
                        />
                      ))}
                  </VStack>
                )}
              </Flex>
            </Box>
          </>
        )}

        {!isWide ? <AppFooter /> : null}
        <PreviewModal
          onClose={onClose}
          isOpen={isOpen}
          url="videos/placeholder.mp4"
        />
      </Page>
    </>
  );
};
