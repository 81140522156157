import React from 'react';

import { Box, Spinner, Text, useToast } from '@chakra-ui/react';
import { FormizStep, useForm } from '@formiz/core';
import PropTypes from 'prop-types';
import { FaInfoCircle } from 'react-icons/fa';

import { FieldInput, FieldUploader, Icon } from '@/components';
import { MEGA } from '@/components/Fields/FieldUploader';
import { BOOKING_INVOICE_REQUEST_ACCEPT_STEPS } from '@/constants/invoiceRequest';
import { useUploadFile } from '@/services/invoiceRequest';
import { useChunkyUploadFile } from '@/services/utils';

import { AcceptResponseHeading } from '../AcceptResponseHeading';
import { FileUploadRepeater } from '../FilesUploadRepeater';

export const AcceptFurtherInformation = ({ agencyEmail }) => {
  const { values, setFieldsValues } = useForm();
  const toast = useToast();

  const { explanatoryVideo } = values || {};

  const [mutateUpload] = useUploadFile({
    onSuccess: ({ uuid: videoId }, { isLastPart }) => {
      if (isLastPart) {
        toast({
          title: `La video explicative a été bien telechargée`,
          status: 'success',
        });
        setFieldsValues({ explanatoryVideoId: videoId });
      }
    },
  });

  const { handleUploadFile, isLoading } = useChunkyUploadFile({
    mutateUpload,
    onError: () => {
      toast({
        title: `Une erreur est survenue lors de l'upload de la Vidéo explicative ${
          explanatoryVideo?.name || ''
        }`,
        status: 'error',
      });
      setFieldsValues({ explanatoryVideo: null });
    },
  });

  const helperText = (
    <Text fontSize="sm">
      <Icon icon={FaInfoCircle} mr="1" />
      <Text as="span">
        Vous pouvez aussi transmettre les documents à l’agence par cette adresse
        email :
        <Text as="span" fontWeight="700" colorScheme="brandSecondary">
          {' '}
          {agencyEmail}
        </Text>
      </Text>
    </Text>
  );

  return (
    <FormizStep
      {...BOOKING_INVOICE_REQUEST_ACCEPT_STEPS.ACCEPT_FURTHER_INFORMATION}
    >
      <AcceptResponseHeading
        title="Informations complémentaires"
        helper="Les informations seront transmises à l’agence.
        Plus vous renseignez d’informations, plus le service pourra être adapté à vos besoins."
      />
      <FieldInput
        name="interlocutor"
        label="Votre interlocuteur"
        helper="Il s’agit de la personne qui va vous recevoir."
        mt={6}
      />
      <FieldInput
        name="comment"
        type="textarea"
        label="Commentaire(s)"
        helper="S’il existe un contexte particulier pour votre
        rendez-vous, vous pouvez mentionner tous les détails qui vous semblent importants et utiles."
        h="auto"
      />
      <Box position="relative">
        {isLoading && (
          <Spinner
            top="0"
            right="20"
            position="absolute"
            thickness="4px"
            emptyColor="gray.200"
            color="brandSecondary.600"
            size="md"
          />
        )}
        <FieldUploader
          name="explanatoryVideo"
          label="Vidéo explicative"
          helper="Importez une vidéo pour simplifier vos indications"
          title="Télécharger une vidéo"
          accept="video/*"
          isDisabledTrash={isLoading}
          onChange={handleUploadFile}
          maxFileSize={250 * MEGA}
          validations={[
            {
              rule: () => !isLoading,
              deps: [isLoading],
              message: 'Veuillez patienter pendant le téléchargement',
            },
          ]}
          maxFileSizeLabel={
            <Text color="gray.400" fontSize={12}>
              Taille maximale 250MB
            </Text>
          }
          h={44}
        />
      </Box>
      <FieldInput name="explanatoryVideoId" type="hidden" h="auto" mb="0" />
      <FileUploadRepeater helperText={agencyEmail ? helperText : null} />
    </FormizStep>
  );
};

AcceptFurtherInformation.propTypes = {
  agencyEmail: PropTypes.string,
};

AcceptFurtherInformation.defaultProps = {
  agencyEmail: '',
};
