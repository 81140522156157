import React, { useEffect, useState } from 'react';

import { fieldDefaultProps, fieldPropTypes, useField } from '@formiz/core';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { FormGroup, DayPicker } from '@/components';

export const FieldDayPicker = (props) => {
  const {
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    errorMessage,
  } = useField(props);

  const {
    label,
    helper,
    placeholder,
    type,
    isDisabled,
    required,
    dayPickerProps,
    dayPickerInputProps,
    formatValue,
    keepValue,
    asyncValidations,
    ...otherProps
  } = props;

  const [isTouched, setIsTouched] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted) && !isFocused;

  const formGroupProps = {
    htmlFor: id,
    label,
    helper,
    errorMessage,
    showError,
    isRequired: !!required,
    isDisabled,
    ...otherProps,
  };

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const handleChange = (selectedDay) => {
    if (!isTouched) {
      setIsTouched(true);
    }

    setValue(selectedDay);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (isTouched) return;
    setIsTouched(true);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const currentValue = value ? dayjs(value).toDate() : null;

  return (
    <FormGroup position="static" {...formGroupProps}>
      <DayPicker
        placeholder={placeholder}
        defaultValue={currentValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        dayPickerProps={dayPickerProps}
        dayPickerInputProps={{
          inputProps: {
            id,
            isDisabled,
            ...(dayPickerInputProps.inputProps || {}),
          },
          ...dayPickerInputProps,
        }}
        aria-invalid={showError}
        aria-required={!!required}
        aria-describedby={showError ? `${id}-error` : null}
      />
    </FormGroup>
  );
};

FieldDayPicker.propTypes = {
  label: PropTypes.string,
  helper: PropTypes.string,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  dayPickerProps: PropTypes.object, // eslint-disable-line
  dayPickerInputProps: PropTypes.object, // eslint-disable-line
  ...fieldPropTypes,
};

FieldDayPicker.defaultProps = {
  label: '',
  helper: '',
  placeholder: '',
  isDisabled: false,
  dayPickerProps: {},
  dayPickerInputProps: {},
  ...fieldDefaultProps,
};
