import React from 'react';

import { Box } from '@chakra-ui/react';

export const Justice = (props) => (
  <Box {...props}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75009 2.25C6.75009 1.00736 7.75743 0 9.00004 0C10.2427 0 11.25 1.00736 11.25 2.25C11.25 3.49264 10.2427 4.5 9.00004 4.5C7.75743 4.5 6.75009 3.49264 6.75009 2.25ZM9.96431 2.25C9.96431 1.71744 9.53259 1.28571 9.00004 1.28571C8.46749 1.28571 8.03578 1.71744 8.03578 2.25C8.03578 2.78256 8.46749 3.21429 9.00004 3.21429C9.53259 3.21429 9.96431 2.78256 9.96431 2.25Z"
        fill="#054952"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5716 10.2857C11.5716 9.93067 11.8594 9.64285 12.2144 9.64285H17.3572C17.7122 9.64285 18 9.93067 18 10.2857C18 12.0609 16.5609 13.5 14.7858 13.5C13.0106 13.5 11.5716 12.0609 11.5716 10.2857ZM16.3091 11.4685C16.5031 11.219 16.6018 10.9286 16.6018 10.9286H12.9677C13.233 11.6785 13.9468 12.2143 14.7858 12.2143C15.4051 12.2143 15.9563 11.9223 16.3091 11.4685Z"
        fill="#054952"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.48378 1.2545C7.83035 1.33152 8.04888 1.67492 7.97186 2.02151C7.89484 2.36809 7.55145 2.58662 7.20487 2.5096L3.075 1.59183C2.72842 1.51481 2.5099 1.17141 2.58692 0.824829C2.66393 0.478243 3.00733 0.259716 3.35391 0.336735L7.48378 1.2545Z"
        fill="#054952"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9252 2.90814C15.2718 2.98516 15.4903 3.32856 15.4133 3.67515C15.3362 4.02173 14.9929 4.24026 14.6463 4.16324L10.4236 3.22486C10.0771 3.14784 9.85854 2.80444 9.93556 2.45785C10.0126 2.11127 10.356 1.89274 10.7026 1.96976L14.9252 2.90814Z"
        fill="#054952"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.21369 2.77171L1.24367 7.94312C1.11728 8.2749 0.74586 8.4414 0.414085 8.31501C0.0823107 8.18862 -0.0841861 7.81719 0.0422039 7.48541L2.61358 0.735414C2.82387 0.18341 3.60476 0.18341 3.81504 0.735414L6.38642 7.48541C6.51281 7.81719 6.34632 8.18862 6.01454 8.31501C5.68277 8.4414 5.31135 8.2749 5.18496 7.94312L3.21369 2.77171Z"
        fill="#054952"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7839 5.34592L12.815 10.5146C12.6886 10.8463 12.3171 11.0128 11.9854 10.8865C11.6536 10.7601 11.4871 10.3886 11.6135 10.0569L14.1849 3.30686C14.3952 2.75485 15.176 2.75485 15.3863 3.30686L17.9577 10.0569C18.0841 10.3886 17.9176 10.7601 17.5858 10.8865C17.2541 11.0128 16.8826 10.8463 16.7563 10.5146L14.7839 5.34592Z"
        fill="#054952"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.35689 3.85715C8.35689 3.50211 8.6447 3.21429 8.99973 3.21429C9.35477 3.21429 9.64258 3.50211 9.64258 3.85715V14.7857C9.64258 15.1408 9.35477 15.4286 8.99973 15.4286C8.6447 15.4286 8.35689 15.1408 8.35689 14.7857V3.85715Z"
        fill="#054952"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.00026226 7.71427C0.00026226 7.35923 0.288074 7.07141 0.643107 7.07141H5.78587C6.1409 7.07141 6.42871 7.35923 6.42871 7.71427C6.42871 9.48947 4.98965 10.9286 3.21449 10.9286C1.43932 10.9286 0.00026226 9.48947 0.00026226 7.71427ZM4.73783 8.89709C4.93185 8.64756 5.03052 8.35713 5.03052 8.35713H1.39637C1.66166 9.1071 2.37551 9.64284 3.21449 9.64284C3.83383 9.64284 4.38498 9.35088 4.73783 8.89709Z"
        fill="#054952"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.78586 15.75V16.7143H12.2143V15.75H5.78586ZM13.5 15.1072V17.3572C13.5 17.7122 13.2122 18 12.8572 18H5.14302C4.78798 18 4.50017 17.7122 4.50017 17.3572V15.1072C4.50017 14.7521 4.78798 14.4643 5.14302 14.4643H12.8572C13.2122 14.4643 13.5 14.7521 13.5 15.1072Z"
        fill="#054952"
      />
    </svg>
  </Box>
);
