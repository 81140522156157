import React from 'react';

import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const IllustrationWomen = ({ illustration, ...others }) => (
  <Box
    as="img"
    src={illustration}
    alt=""
    position="absolute"
    top="8em"
    right={{
      base: '-2%',
      lg: '10%',
    }}
    zIndex={0}
    display={{ base: 'none', md: 'block' }}
    {...others}
  />
);

IllustrationWomen.propTypes = {
  illustration: PropTypes.string,
};

IllustrationWomen.defaultProps = {
  illustration: '',
};
