/*
 * We're using order feature from Formiz to keep the steps in order
 * From 100 to 199 included, it's about appointment location
 * From 200 to 299 included, it's about appointment information
 * From 300 to 399 included, it's about appointment datetime
 * From 400 it's about the booking summary step
 */

export const MAIN_STEPS = {
  LOCATION: {
    name: 'APPOINTMENT_LOCATION',
    order: 100,
  },
  INFORMATION: {
    name: 'APPOINTMENT_INFORMATION',
    order: 200,
  },
  DATETIME: {
    name: 'APPOINTMENT_DATETIME',
    order: 300,
  },
  SUMMARY: {
    name: 'SUMMARY',
    order: 400,
  },
};

const LOCATION = {
  ADDRESS: {
    name: MAIN_STEPS.LOCATION.name,
    order: MAIN_STEPS.LOCATION.order + 1,
  },
};

const INFORMATION = {
  CATEGORY: {
    name: `${MAIN_STEPS.INFORMATION.name}_CATEGORY`,
    order: MAIN_STEPS.INFORMATION.order + 1,
  },
  SUBJECT: {
    name: `${MAIN_STEPS.INFORMATION.name}_SUBJECT`,
    order: MAIN_STEPS.INFORMATION.order + 2,
  },
};

const HEALTH_SUBJECT = {
  TYPE: {
    name: `${MAIN_STEPS.INFORMATION.name}_HEALTH_SUBJECT_TYPE`,
    order: MAIN_STEPS.INFORMATION.order + 3,
  },
  SPECIALIST_TYPE: {
    name: `${MAIN_STEPS.INFORMATION.name}_HEALTH_SUBJECT_SPECIALIST_TYPE`,
    order: MAIN_STEPS.INFORMATION.order + 4,
  },
};

const MEETING_SUBJECT = {
  MEETING: {
    name: `${MAIN_STEPS.INFORMATION.name}_MEETING`,
    order: MAIN_STEPS.INFORMATION.order + 5,
  },
  FACE_TO_FACE_MEETING: {
    name: `${MAIN_STEPS.INFORMATION.name}_MEETING_FACE_TO_FACE`,
    order: MAIN_STEPS.INFORMATION.order + 6,
  },
};

const EVENT_SUBJECT = {
  TYPE: {
    name: `${MAIN_STEPS.INFORMATION.name}_EVENT_SPECIFICS`,
    order: MAIN_STEPS.INFORMATION.order + 7,
  },
};

const JUSTICE_SUBJECT = {
  TYPE: {
    name: `${MAIN_STEPS.INFORMATION.name}_JUSTICE_TYPE`,
    order: MAIN_STEPS.INFORMATION.order + 8,
  },
  MEETING: {
    name: `${MAIN_STEPS.INFORMATION.name}_JUSTICE_MEETING`,
    order: MAIN_STEPS.INFORMATION.order + 9,
  },
  INFORMATION: {
    name: `${MAIN_STEPS.INFORMATION.name}_JUSTICE_INFORMATION`,
    order: MAIN_STEPS.INFORMATION.order + 10,
  },
};

const DATETIME = {
  TYPE: {
    name: `${MAIN_STEPS.DATETIME.name}_TYPE`,
    order: MAIN_STEPS.DATETIME.order + 1,
  },
  INFORMATION: {
    name: `${MAIN_STEPS.DATETIME.name}_INFORMATION`,
    order: MAIN_STEPS.DATETIME.order + 2,
  },
  RECURRING_DATE_INFORMATION: {
    name: `${MAIN_STEPS.DATETIME.name}_RECURRING_DATE_INFORMATION`,
    order: MAIN_STEPS.DATETIME.order + 3,
  },
  FREE_DATE_INFORMATION: {
    name: `${MAIN_STEPS.DATETIME.name}_FREE_DATE_INFORMATION`,
    order: MAIN_STEPS.DATETIME.order + 4,
  },
  MULTI_DATES_INFORMATION: {
    name: `${MAIN_STEPS.DATETIME.name}_MULTI_DATES_INFORMATION`,
    order: MAIN_STEPS.DATETIME.order + 5,
  },
};
export const FORM_STEPS = {
  LOCATION,
  INFORMATION,
  HEALTH_SUBJECT,
  MEETING_SUBJECT,
  EVENT_SUBJECT,
  JUSTICE_SUBJECT,
  DATETIME,
  SUMMARY: {
    name: MAIN_STEPS.SUMMARY.name,
    order: MAIN_STEPS.SUMMARY.order + 1,
  },
};
