/* eslint-disable consistent-return */
/* eslint-disable default-case */

export const Button = {
  baseStyle: {
    borderRadius: '20px',
  },
  variants: {
    solid: ({ colorScheme }) => {
      switch (colorScheme) {
        case 'brand':
          return {
            bg: `${colorScheme}.700`,
            _active: {
              bg: `${colorScheme}.900`,
            },
            _hover: {
              bg: `${colorScheme}.800`,
            },
          };
        case 'brandSecondary':
          return {
            bg: `${colorScheme}.600`,
            _active: {
              bg: `${colorScheme}.800`,
            },
            _hover: {
              bg: `${colorScheme}.700`,
            },
          };
      }
    },
    outline: ({ colorScheme }) => {
      switch (colorScheme) {
        case 'brand':
          return {
            color: `${colorScheme}.700`,
            _active: {
              bg: `${colorScheme}.100`,
            },
            _hover: {
              bg: `${colorScheme}.50`,
            },
          };
        case 'brandSecondary':
          return {
            color: `${colorScheme}.600`,
            _active: {
              bg: `${colorScheme}.100`,
            },
            _hover: {
              bg: `${colorScheme}.50`,
            },
          };
      }
    },
    ghost: ({ colorScheme }) => {
      switch (colorScheme) {
        case 'brand':
          return {
            color: `${colorScheme}.700`,
          };
      }
    },
    link: ({ colorScheme }) => {
      switch (colorScheme) {
        case 'brand':
          return {
            color: `${colorScheme}.700`,
            _active: {
              color: `${colorScheme}.900`,
            },
          };
        case 'brandSecondary':
          return {
            color: `${colorScheme}.600`,
            _active: {
              color: `${colorScheme}.800`,
            },
          };
      }
    },
    inverse: ({ colorScheme }) => {
      switch (colorScheme) {
        case 'brand':
          return {
            color: `${colorScheme}.700`,
            bg: `${colorScheme}.50`,
            _hover: {
              bg: `${colorScheme}.100`,
            },
            _active: {
              bg: `${colorScheme}.50`,
            },
          };
        case 'brandSecondary':
          return {
            color: `${colorScheme}.700`,
            bg: `${colorScheme}.100`,
            _hover: {
              bg: `${colorScheme}.200`,
            },
            _active: {
              bg: `${colorScheme}.100`,
            },
          };
      }
    },
  },
};
