import React from 'react';

import { Button, Center, Flex } from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { Footer, Icon } from '@/components';
import { AppHeader, Page } from '@/layout';

import { ChangePasswordHeading } from './ChangePasswordHeading';

export const ChangePasswordSent = () => (
  <Page d="flex" flexGrow={1} bg="gray.100">
    <AppHeader title="Récupération mot de passe" />

    <Center flexDirection="column" justifyContent="flex-start" flex={1}>
      <Icon
        icon={FaCheckCircle}
        color="brandSecondary.400"
        fontSize="5em"
        mt={10}
      />
      <ChangePasswordHeading
        title=" Mot de passe modifié !"
        helper="Votre mot de passe a été modifié avec succès"
        textAlign="center"
        mt={6}
      />
    </Center>

    <Footer h={16}>
      <Flex
        justifyContent="flex-end"
        flexGrow={1}
        marginX="auto"
        maxWidth="45em"
      >
        <Button as={Link} to="/login" colorScheme="brandSecondary">
          Connexion
        </Button>
      </Flex>
    </Footer>
  </Page>
);
