import React from 'react';

import { FormizStep } from '@formiz/core';

import { FORM_STEPS } from '@/constants/steps';

import { AppointmentCategory } from './_partials/AppointmentCategory';
import { AppointmentSubject } from './_partials/AppointmentSubject';
import { AppointmentSubjectDetail } from './_partials/AppointmentSubjectDetail';

export const AppointmentInformation = () => {
  return (
    <>
      <FormizStep
        name={FORM_STEPS.INFORMATION.CATEGORY.name}
        order={FORM_STEPS.INFORMATION.CATEGORY.order}
      >
        <AppointmentCategory />
      </FormizStep>

      <FormizStep
        name={FORM_STEPS.INFORMATION.SUBJECT.name}
        order={FORM_STEPS.INFORMATION.SUBJECT.order}
      >
        <AppointmentSubject />
      </FormizStep>
      <AppointmentSubjectDetail />
    </>
  );
};
