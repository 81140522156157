import React from 'react';

import { Box, Flex, forwardRef, Heading, Spacer } from '@chakra-ui/react';
import { useForm } from '@formiz/core';
import * as PropTypes from 'prop-types';

import { FieldDayPicker, FieldInput, DeleteButton } from '@/components';
import {
  isStartTimeBeforeEndTime,
  isTimeBeforeNow,
} from '@/utils/formCustomValidations';

export const AppointmentMultiDateRepeaterForm = forwardRef(
  ({ index, deleteButtonProps }, ref) => {
    const { values } = useForm({
      subscribe: {
        fields: [
          `appointment.datetime.dates[${index}].date`,
          `appointment.datetime.dates[${index}].startTime`,
          `appointment.datetime.dates[${index}].endTime`,
        ],
      },
    });
    const date = values?.appointment?.datetime?.dates
      ? values?.appointment?.datetime.dates[index]?.date
      : '';
    const startTime = values?.appointment?.datetime?.dates
      ? values?.appointment?.datetime?.dates[index]?.startTime
      : '';
    return (
      <Box
        mt="4"
        backgroundColor="gray.50"
        border="1px dashed"
        borderColor="gray.200"
        p="3"
        pb="5"
        ref={ref}
      >
        <Flex direction="row">
          <Heading as="h4" size="lg" pt={2}>
            Date n° {index + 1}
          </Heading>
          <Spacer />
          <DeleteButton {...deleteButtonProps} />
        </Flex>
        <>
          <FieldDayPicker
            name={`appointment.datetime.dates[${index}].date`}
            label={`Date ${index + 1}`}
            placeholder=".. /.. / ...."
            required="La date de rendez-vous est requise"
            mb={1}
          />
          <Flex align="center" direction={{ base: 'column', md: 'row' }} pt="4">
            <FieldInput
              name={`appointment.datetime.dates[${index}].startTime`}
              label="Heure de début du rendez-vous"
              type="time"
              alignSelf={{ base: undefined, md: 'flex-start' }}
              mr={{ base: 0, md: 2 }}
              required="L'heure de début est requise"
              validations={[
                {
                  rule: (val) =>
                    !date ||
                    !startTime ||
                    (date &&
                      isTimeBeforeNow({
                        date,
                        time: val,
                      })),
                  message: "L'heure de début est passée",
                  deps: [date],
                },
              ]}
            />
            <FieldInput
              label="Heure de fin du rendez-vous"
              type="time"
              name={`appointment.datetime.dates[${index}].endTime`}
              required="L'heure de fin est requise"
              alignSelf={{ base: undefined, md: 'flex-start' }}
              mt={{ base: 4, md: 0 }}
              validations={[
                {
                  rule: (val) =>
                    !startTime ||
                    isStartTimeBeforeEndTime({
                      startTime,
                      endTime: val,
                    }),
                  message: "L'heure de fin est avant l'heure de début",
                  deps: [startTime],
                },
              ]}
            />
          </Flex>
        </>
      </Box>
    );
  }
);

AppointmentMultiDateRepeaterForm.propTypes = {
  index: PropTypes.number.isRequired,
  deleteButtonProps: PropTypes.shape({
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
  }),
};

AppointmentMultiDateRepeaterForm.defaultProps = {
  deleteButtonProps: {
    onClick: () => {},
    isDisabled: false,
  },
};
