import React from 'react';

import { Badge, Flex, Heading, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FaBuilding } from 'react-icons/fa';

import {
  Card,
  CardCheckbox,
  CardContent,
  CardDescription,
  CardRightElement,
  CardTitle,
  Icon,
} from '@/components';

export const CardCheckboxItem = ({
  id,
  name,
  streetAddress,
  zipCode,
  city,
  lowestPrice,
  highestPrice,
  isDisabled,
  closePhrase,
}) => (
  <Card
    borderWidth="0"
    mb={2}
    opacity={isDisabled ? '0.5' : undefined}
    cursor={isDisabled ? 'not-allowed' : undefined}
    pointerEvents={isDisabled ? 'all' : undefined}
  >
    <CardCheckbox value={id} isDisabled={isDisabled} />
    <CardContent>
      <CardTitle>
        <Flex
          justifyContent="center"
          alignItems="center"
          w="2.5rem"
          h="2.5rem"
          overflow="hidden"
          bg="brandSecondary.100"
          borderRadius="spot.variant1"
          mr={2}
        >
          <Icon
            color="brandSecondary.700"
            icon={FaBuilding}
            fontSize="1.25rem"
          />
        </Flex>
        {/* <Icon icon={FaBuilding} color="gray.400" fontSize="2rem" mr={2} /> */}
        {name}
        {isDisabled && (
          <Badge colorScheme="red" mx={4}>
            Fermé
          </Badge>
        )}
      </CardTitle>
      <CardDescription h={8}>
        {isDisabled && <Text>{closePhrase}</Text>}
        <Text fontSize="sm">{streetAddress}</Text>
        <Text fontSize="sm">
          {`${zipCode || ''}${(city && ` - ${city}`) || ''}`}
        </Text>
      </CardDescription>
      <CardRightElement>
        <Heading as="h3" size="lg" fontSize="2xl" mt="2">
          {`${lowestPrice?.toFixed(2)} - ${highestPrice?.toFixed(2)} € TTC`}
        </Heading>
      </CardRightElement>
    </CardContent>
  </Card>
);

CardCheckboxItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  streetAddress: PropTypes.string,
  zipCode: PropTypes.string,
  city: PropTypes.string,
  lowestPrice: PropTypes.number,
  highestPrice: PropTypes.number,
  isDisabled: PropTypes.bool,
  closePhrase: PropTypes.string,
};

CardCheckboxItem.defaultProps = {
  id: null,
  name: '',
  streetAddress: '',
  zipCode: '',
  city: '',
  lowestPrice: 0,
  highestPrice: 0,
  isDisabled: false,
  closePhrase: '',
};
