import React from 'react';

import { Button, Flex } from '@chakra-ui/react';
import { useForm } from '@formiz/core';
import PropTypes from 'prop-types';

import { Footer } from '@/components';

export const BookingRequestFooter = ({
  isDisabledNextButton,
  ...otherProps
}) => {
  const form = useForm({ subscribe: 'form' });
  const { isFirstStep, prevStep } = useForm({ subscribe: 'form' });

  return (
    <Footer h={16} {...otherProps}>
      <Flex
        justifyContent="space-between"
        flexGrow={1}
        marginX="auto"
        maxWidth="45em"
      >
        <Button
          onClick={prevStep}
          width={{ base: '100%', md: 'auto' }}
          colorScheme="gray"
          isDisabled={isFirstStep}
        >
          Précédent
        </Button>
        <Button
          type="submit"
          width={{ base: '100%', md: 'auto' }}
          colorScheme="brandSecondary"
          isDisabled={
            (form.isStepSubmitted && !form.currentStep.isValid) ||
            isDisabledNextButton
          }
        >
          Suivant
        </Button>
      </Flex>
    </Footer>
  );
};

BookingRequestFooter.propTypes = {
  isDisabledNextButton: PropTypes.bool,
};

BookingRequestFooter.defaultProps = {
  isDisabledNextButton: false,
};
