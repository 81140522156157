import React from 'react';

import { Box } from '@chakra-ui/react';

export const Health = (props) => (
  <Box {...props}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.923 2.53848C16.923 2.07116 16.5441 1.69232 16.0768 1.69232C15.6095 1.69232 15.2307 2.07116 15.2307 2.53848V4.23078C15.2307 4.6981 15.6095 5.07694 16.0768 5.07694C16.5441 5.07694 16.923 4.6981 16.923 4.23078H17.7691V7.6154C17.7691 10.4193 15.4961 12.6923 12.6922 12.6923C9.8883 12.6923 7.61527 10.4193 7.61527 7.6154V4.23078H8.46143C8.46143 4.6981 8.84025 5.07694 9.30758 5.07694C9.77491 5.07694 10.1537 4.6981 10.1537 4.23078V2.53848C10.1537 2.07116 9.77491 1.69232 9.30758 1.69232C8.84025 1.69232 8.46143 2.07116 8.46143 2.53848H6.76912C6.30179 2.53848 5.92296 2.91732 5.92296 3.38463V7.6154C5.92296 11.0674 8.50686 13.9159 11.846 14.3323V14.3846C11.846 16.7212 9.95184 18.6154 7.61527 18.6154C5.2787 18.6154 3.3845 16.7212 3.3845 14.3846V13.394C4.37044 13.0456 5.07681 12.1053 5.07681 11C5.07681 9.59806 3.94034 8.46155 2.53835 8.46155C1.13636 8.46155 -0.000112534 9.59806 -0.000112534 11C-0.000112534 12.1053 0.706257 13.0456 1.69219 13.394V14.3846C1.69219 17.6559 4.34404 20.3077 7.61527 20.3077C10.8865 20.3077 13.5383 17.6559 13.5383 14.3846V14.3323C16.8775 13.9159 19.4614 11.0674 19.4614 7.6154V3.38463C19.4614 2.91732 19.0826 2.53848 18.6153 2.53848H16.923ZM2.53835 11.8462C2.07102 11.8462 1.69219 11.4673 1.69219 11C1.69219 10.5327 2.07102 10.1539 2.53835 10.1539C3.00568 10.1539 3.3845 10.5327 3.3845 11C3.3845 11.4673 3.00568 11.8462 2.53835 11.8462Z"
        fill="#054952"
      />
    </svg>
  </Box>
);
