import React from 'react';

import { Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FaInfoCircle, FaMapMarkerAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
  Icon,
} from '@/components';
import { formatAddress } from '@/utils/textUtils';

export const InvoiceRequestValidationAgency = ({
  invoiceRequest,
  ...otherProps
}) => {
  const { responseId } = useParams();
  const { responses } = invoiceRequest;

  const invoicesRequestResponse = responses?.find(
    (res) => res?.id.toString() === responseId
  );

  const { agency } = invoicesRequestResponse || {};

  const { address: agencyAddress, name, email } = agency || {};
  const address = formatAddress(agencyAddress);

  return (
    <>
      <Card borderWidth="0" {...otherProps}>
        <CardContent position="relative">
          <CardTitle>{!!name && `Agence de ${name}`}</CardTitle>
          <CardDescription textAlign="left" pb={2} h="auto">
            <Text isTruncated>
              <Icon icon={FaMapMarkerAlt} color="brandSecondary.700" mr={2} />
              {address}
            </Text>
            <Text mt={6} color="gray.400">
              <Icon icon={FaInfoCircle} color="inherit" mr={2} />
              Cette agence vous a envoyé un devis sur votre adresse email{' '}
              {email ? ': ' : ''}
              <Text as="span" color="gray.600">
                {` ${email || ''}`}
              </Text>
            </Text>
            <Text mt={6}>
              Soyez sûr{' '}
              <Text as="span" fontWeight="bold">
                d’avoir lu le devis avant de l’accepter.
              </Text>{' '}
              En acceptant le devis,{' '}
              <Text as="span" fontWeight="bold">
                vous vous engagez
              </Text>{' '}
              selon les conditions de vente et d’annulation présent sur celui
              ci.
            </Text>
            <Text mt={6}>
              Si vous validez ce devis,{' '}
              <Text as="span" fontWeight="bold">
                vous refuserez automatiquement les autres{' '}
              </Text>{' '}
              devis.
            </Text>
          </CardDescription>
        </CardContent>
      </Card>
    </>
  );
};

InvoiceRequestValidationAgency.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  invoiceRequest: PropTypes.object,
};

InvoiceRequestValidationAgency.defaultProps = {
  invoiceRequest: null,
};
