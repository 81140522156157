import React from 'react';

import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { Formiz, useForm } from '@formiz/core';
import { isEmail } from '@formiz/validations';
import { FaArrowLeft } from 'react-icons/fa';
import { Link, Redirect, useHistory } from 'react-router-dom';

import { FieldInput, Footer, Logo } from '@/components';
import { useUserContext } from '@/context/user-context';
import { Page } from '@/layout';
import { useInitResetPasswordRequest } from '@/services/login';
import { useQueryParam } from '@/services/queryParam';

import { VerificationMailSent } from './_partials/VerificationMailSent';

export const ForgotPassword = () => {
  const { isLogged } = useUserContext();
  const form = useForm({ subscribe: false });
  const toast = useToast();
  const history = useHistory();
  const redirect = useQueryParam('redirect');

  const [mutate, { isSuccess, isLoading }] = useInitResetPasswordRequest({
    onError: () => {
      toast({
        title:
          "Une erreur est survenue lors de l'envoi du mail de récupération",
        status: 'error',
      });
    },
    onSuccess: () => {
      toast({
        title: 'Le mail de récupération a été envoyé avec succès',
        status: 'success',
      });
    },
  });

  const handleValidSubmit = (values) => {
    const passwordData = {
      email: values?.login,
    };
    mutate(passwordData);
  };

  if (isLogged) {
    return <Redirect to="/" />;
  }

  if (isSuccess) {
    return <VerificationMailSent />;
  }

  return (
    <Page px={6} mt={0} backgroundColor="gray.50">
      <Box maxW="45em" mx="auto">
        <IconButton
          icon={<FaArrowLeft />}
          color="gray.400"
          variant="ghost"
          onClick={() => history.push('login')}
        />
        <Formiz connect={form} onValidSubmit={handleValidSubmit}>
          <Box as="form" noValidate onSubmit={form.submit}>
            <Flex direction="column" align="center" m="auto" maxW="400px">
              <Logo as={Link} mb={{ base: 4, sm: 6 }} width="4rem" to="/" />
              <Heading as="h1" size="lg" mb={6}>
                Mot de passe oublié
              </Heading>
              <Box w="100%">
                <FieldInput
                  name="login"
                  type="email"
                  required="L'adresse email est requise"
                  placeholder="Votre adresse mail"
                  label="Email"
                  validations={[
                    {
                      rule: isEmail(),
                      message: "L'adresse email n'est pas valide",
                    },
                  ]}
                />

                <Stack spacing={4} d={{ base: 'none', md: 'flex' }} mt={6}>
                  <Button
                    as={Link}
                    to={{
                      pathname: '/login',
                      search: redirect ? `?redirect=${redirect}` : null,
                    }}
                    colorScheme="brandSecondary"
                    variant="link"
                    py={1}
                  >
                    Retour à la connexion
                  </Button>
                  <Button
                    type="submit"
                    colorScheme="brandSecondary"
                    isDisabled={isLoading}
                  >
                    Réinitialiser le mot de passe
                  </Button>
                </Stack>
              </Box>
            </Flex>

            <Footer as={Stack} spacing={4} d={{ base: 'flex', md: 'none' }}>
              <Button
                as={Link}
                to={{
                  pathname: '/login',
                  search: redirect ? `?redirect=${redirect}` : null,
                }}
                colorScheme="brandSecondary"
                variant="link"
                py={1}
              >
                Retour à la connexion
              </Button>
              <Button
                type="submit"
                colorScheme="brandSecondary"
                isDisabled={isLoading}
              >
                Réinitialiser le mot de passe
              </Button>
            </Footer>
          </Box>
        </Formiz>
      </Box>
    </Page>
  );
};
