import React from 'react';

import { Circle, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const StepperNumber = ({ colorScheme, children, ...otherProps }) => (
  <Circle
    boxSize="6"
    bg={`${colorScheme}.700`}
    color="white"
    fontWeight="bold"
    {...otherProps}
  >
    <Text fontSize="md">{children}</Text>
  </Circle>
);

StepperNumber.propTypes = {
  colorScheme: PropTypes.string,
  children: PropTypes.node,
};

StepperNumber.defaultProps = {
  colorScheme: 'brandSecondary',
  children: null,
};
