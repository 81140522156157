import { colors } from '@/theme/foundations/colors';

/* eslint-disable consistent-return */
/* eslint-disable default-case */

export const Input = {
  variants: {
    outline: () => ({
      field: {
        _invalid: {
          borderColor: 'danger.500',
          boxShadow: `0 0 0 1px ${colors.danger[500]}`,
        },
      },
    }),
  },
};
