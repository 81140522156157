import React from 'react';

import { FormizStep, useForm } from '@formiz/core';

import { BOOKING_REQUEST_DATE_TIME } from '@/constants/bookingRequest';
import { FORM_STEPS } from '@/constants/steps';

import { AppointmentDateTimeInformation } from './_partials/AppointmentDateTimeInformation';
import { AppointmentDateTimeType } from './_partials/AppointmentDateTimeType';
import { AppointmentFreeDateInformation } from './_partials/AppointmentFreeDateInformation';
import { AppointmentMultiDateInformation } from './_partials/AppointmentMultiDateInformation';
import { AppointmentRecurringDateInformation } from './_partials/AppointmentRecurringDateInformation';

export const AppointmentDateTime = () => {
  const {
    values: { appointment },
  } = useForm({
    subscribe: {
      fields: ['appointment', 'appointment.datetime.appointmentDateTimeType'],
    },
  });

  const isFixedOrRecurring =
    appointment?.datetime?.appointmentDateTimeType ===
      BOOKING_REQUEST_DATE_TIME.FIXED_DATE.id ||
    appointment?.datetime?.appointmentDateTimeType ===
      BOOKING_REQUEST_DATE_TIME.RECURRING_DATE.id;
  return (
    <>
      <FormizStep
        name={FORM_STEPS.DATETIME.TYPE.name}
        order={FORM_STEPS.DATETIME.TYPE.order}
      >
        <AppointmentDateTimeType />
      </FormizStep>
      {isFixedOrRecurring && (
        <FormizStep
          name={FORM_STEPS.DATETIME.INFORMATION.name}
          order={FORM_STEPS.DATETIME.INFORMATION.order}
        >
          <AppointmentDateTimeInformation />
        </FormizStep>
      )}
      {appointment?.datetime?.appointmentDateTimeType ===
        BOOKING_REQUEST_DATE_TIME.RECURRING_DATE.id && (
        <FormizStep
          name={FORM_STEPS.DATETIME.RECURRING_DATE_INFORMATION.name}
          order={FORM_STEPS.DATETIME.RECURRING_DATE_INFORMATION.order}
        >
          <AppointmentRecurringDateInformation />
        </FormizStep>
      )}
      {appointment?.datetime?.appointmentDateTimeType ===
        BOOKING_REQUEST_DATE_TIME.MULTI_DATES.id && (
        <FormizStep
          name={FORM_STEPS.DATETIME.MULTI_DATES_INFORMATION.name}
          order={FORM_STEPS.DATETIME.MULTI_DATES_INFORMATION.order}
        >
          <AppointmentMultiDateInformation />
        </FormizStep>
      )}
      {appointment?.datetime?.appointmentDateTimeType ===
        BOOKING_REQUEST_DATE_TIME.FREE_DATE.id && (
        <FormizStep
          name={FORM_STEPS.DATETIME.FREE_DATE_INFORMATION.name}
          order={FORM_STEPS.DATETIME.FREE_DATE_INFORMATION.order}
        >
          <AppointmentFreeDateInformation />
        </FormizStep>
      )}
    </>
  );
};
