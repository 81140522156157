import React from 'react';

import { Box } from '@chakra-ui/react';
import * as PropType from 'prop-types';

export const Page = ({ haveFooter, ...rest }) => (
  <Box
    mt="4rem"
    mb={haveFooter ? { base: 16, sm: 10 } : 0}
    pt={4}
    px={10}
    h="100%"
    flexGrow={1}
    {...rest}
  />
);

Page.propTypes = {
  haveFooter: PropType.bool,
};
Page.defaultProps = {
  haveFooter: true,
};
