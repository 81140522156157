import React from 'react';

import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { Formiz, useForm } from '@formiz/core';
import { FaArrowLeft, FaTimes } from 'react-icons/fa';
import { useQueryCache } from 'react-query';
import { Link, Redirect, useHistory, useParams } from 'react-router-dom';

import {
  Badge,
  Header,
  HeaderLeftAction,
  HeaderRightAction,
  HeaderTitle,
} from '@/components';
import { BOOKING_INVOICE_REQUEST_ACCEPT_STEPS } from '@/constants/invoiceRequest';
import { Page } from '@/layout';
import {
  useAcceptInvoiceRequestResponse,
  useInvoiceRequest,
} from '@/services/invoiceRequest';

import { AcceptFurtherInformation } from './_partials/AcceptFurtherInformation';
import { BookingInvoiceRequestAcceptFooter } from './_partials/BookingInvoiceRequestAcceptFooter';
import { InvoiceRequestValidation } from './_partials/InvoiceRequestValidation';

export const BookingInvoiceRequestAccept = () => {
  const history = useHistory();
  const form = useForm();
  const toast = useToast();
  const queryCache = useQueryCache();

  const { bookingId, responseId } = useParams();

  const [
    acceptMutate,
    { isLoading: isAcceptingLoading },
  ] = useAcceptInvoiceRequestResponse({
    onError: () => {
      toast({
        title:
          "Une erreur est survenue lors de l'acceptation de la proposition de devis",
        status: 'error',
      });
    },
    onSuccess: () => {
      queryCache.invalidateQueries(`invoiceRequests`);
      history.push('/agenda');
      toast({
        title: 'La proposition de devis a été acceptée avec succès',
        status: 'success',
      });
    },
  });

  const { data: invoicesRequest, isLoading, isSuccess } = useInvoiceRequest({
    params: {
      id: bookingId,
    },
    onError: () => {
      toast({
        title:
          "Cette demande de devis n'existe pas ou un problème de connexion est survenu",
        status: 'error',
      });
    },
  });

  const invoicesRequestResponse = invoicesRequest?.responses?.find(
    (res) => res?.id === parseInt(responseId, 10)
  );

  const isValidationStep =
    form?.currentStep?.name ===
    BOOKING_INVOICE_REQUEST_ACCEPT_STEPS.INVOICE_REQUEST_VALIDATION.name;

  const handleSubmit = ({
    explanatoryVideoId,
    documents,
    interlocutor,
    comment,
  }) => {
    acceptMutate({
      invoiceRequestId: bookingId,
      responseId,
      explanatoryVideo: explanatoryVideoId ? [explanatoryVideoId] : [],
      document: documents,
      interlocutor: interlocutor || '',
      comment: comment || '',
    });
  };

  const handleGoBack = () => {
    if (form?.isFirstStep) {
      history.goBack();
      return;
    }
    form.prevStep();
  };

  if (!isLoading && !isSuccess) {
    return <Redirect to="/bookings" />;
  }

  return (
    <Page
      px={!isValidationStep ? 6 : 0}
      mb={{ base: isValidationStep ? 24 : 16, md: 16 }}
      pb={2}
      overflowX="hidden"
      background="url('/images/wave.svg') top center no-repeat"
      backgroundColor="gray.100"
      backgroundSize="contain"
      position="relative"
    >
      <Flex
        mt="5"
        flexGrow="1"
        flexDirection="column"
        w="100%"
        maxWidth="45em"
        mx="auto"
      >
        <Box {...(!isValidationStep ? {} : { px: 2, position: 'relative' })}>
          {isValidationStep && (
            <>
              <Box
                as="img"
                src="/images/greenWomen.svg"
                alt=""
                position="absolute"
                top={{ base: 0, md: '3vw' }}
                right={{ base: 0, md: '-13vw' }}
              />
              <Box
                d={{ base: 'none', md: 'block' }}
                as="img"
                src="/images/grid.svg"
                alt=""
                position="absolute"
                top="8vw"
                left="-13vw"
              />
            </>
          )}
          <Formiz connect={form} onValidSubmit={handleSubmit}>
            <Box
              as="form"
              noValidate
              onSubmit={form.submitStep}
              marginX="auto"
              mt={isValidationStep ? 10 : 0}
            >
              <Header position="fixed" top="0" left="0" right="0">
                <Box maxWidth="45em" position="relative" width="100%" h="100%">
                  <HeaderLeftAction>
                    <IconButton
                      onClick={handleGoBack}
                      colorScheme="gray"
                      color="gray.400"
                      variant="ghost"
                      icon={<FaArrowLeft />}
                    />
                  </HeaderLeftAction>
                  <HeaderTitle>Accepter un devis</HeaderTitle>
                  <HeaderRightAction>
                    <IconButton
                      onClick={() => history.push('/')}
                      colorScheme="gray"
                      color="gray.400"
                      variant="ghost"
                      icon={<FaTimes />}
                    />
                  </HeaderRightAction>
                </Box>
              </Header>

              {isLoading ? (
                <Center flex={1}>
                  <Spinner
                    thickness="4px"
                    emptyColor="gray.200"
                    color="brandSecondary.600"
                    size="xl"
                  />
                </Center>
              ) : (
                <>
                  {invoicesRequestResponse ? (
                    <>
                      <AcceptFurtherInformation
                        agencyEmail={
                          invoicesRequestResponse?.agency?.email || ''
                        }
                      />
                      <InvoiceRequestValidation
                        invoiceRequest={{
                          ...invoicesRequest,
                          endDate: invoicesRequestResponse?.endDate,
                          startDate: invoicesRequestResponse?.startDate,
                        }}
                        invoicesRequestResponse={invoicesRequestResponse}
                      />
                      <BookingInvoiceRequestAcceptFooter
                        price={invoicesRequestResponse?.price}
                        isLoading={isAcceptingLoading}
                      />
                    </>
                  ) : (
                    <Center h={40} flexDir="column">
                      <Badge
                        textAlign="center"
                        size="lg"
                        mt={10}
                        py={4}
                        colorScheme="warning"
                      >
                        Vous ne pouvez pas accepter ce devis
                      </Badge>
                      <Button
                        as={Link}
                        variant="link"
                        to={`/bookings/${bookingId}`}
                        colorScheme="brandSecondary"
                        mt={4}
                      >
                        Retournez vers la demande de devis
                      </Button>
                    </Center>
                  )}
                </>
              )}
            </Box>
          </Formiz>
        </Box>
      </Flex>
    </Page>
  );
};
