import React from 'react';

import { Box } from '@chakra-ui/react';

export const HeaderMenu = (props) => (
  <Box
    display="block"
    zIndex="2"
    position={['inherit', 'inherit', 'absolute']}
    w={['100%', '100%', 'auto']}
    h="100%"
    right="0"
    mt={[16, 16, 0]}
    {...props}
  />
);
