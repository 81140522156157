import React from 'react';

import { useForm } from '@formiz/core';
import PropTypes from 'prop-types';

import {
  Card,
  CardCheckbox,
  CardContent,
  CardDescription,
  CardTitle,
} from '@/components';

export const CardCheckboxAll = ({ agencies, ...otherProps }) => {
  const openAgencies = agencies.filter((agency) => !!agency.isOpen);
  const form = useForm();
  const selectedAgenciesIds = form.values?.agencies;
  const isChecked = selectedAgenciesIds?.length === openAgencies?.length;
  const isIndeterminate = !isChecked && !!selectedAgenciesIds?.length;

  const handleChange = () => {
    if (isChecked) {
      form.setFieldsValues({
        agencies: [],
      });
    } else {
      form.setFieldsValues({
        agencies: (openAgencies || []).map((agency) => agency.id?.toString()),
      });
    }
  };

  return (
    <Card borderWidth="0" minH={8} mb={2} {...otherProps}>
      <CardCheckbox
        colorScheme="brandSecondary"
        isChecked={isChecked}
        isIndeterminate={isIndeterminate}
        onChange={handleChange}
      />
      <CardContent>
        <CardTitle>Tout sélectionner</CardTitle>
        <CardDescription h="auto">
          {`${(selectedAgenciesIds || []).length} agence`}
          {(selectedAgenciesIds || []).length > 1 ? 's' : ''}
        </CardDescription>
      </CardContent>
    </Card>
  );
};

CardCheckboxAll.propTypes = {
  agencies: PropTypes.arrayOf(PropTypes.object),
};

CardCheckboxAll.defaultProps = {
  agencies: null,
};
