import React from 'react';

import { Box, Button, Flex, Heading, useToast } from '@chakra-ui/react';
import { Formiz, useForm } from '@formiz/core';
import { isMinLength } from '@formiz/validations';
import { Redirect, useParams } from 'react-router-dom';

import { FieldInput, Footer } from '@/components';
import { useUserContext } from '@/context/user-context';
import { AppHeader, Page } from '@/layout';
import { useChangeResetPasswordRequest } from '@/services/login';

import { ChangePasswordSent } from './_partials/ChangePasswordSent';

export const ChangePassword = () => {
  const { isLogged } = useUserContext();
  const { token } = useParams();
  const form = useForm();
  const toast = useToast();

  const [mutate, { isSuccess, isLoading }] = useChangeResetPasswordRequest({
    onError: () => {
      toast({
        title:
          'Une erreur est survenue lors de la modification du mot de passe',
        status: 'error',
      });
    },
    onSuccess: () => {
      toast({
        title: 'Le mot de passe a été modifié avec succès',
        status: 'success',
      });
    },
  });

  const handleValidSubmit = (values) => {
    const passwordData = {
      token,
      password: values?.password,
    };
    mutate(passwordData);
  };

  if (isLogged) {
    return <Redirect to="/" />;
  }

  if (isSuccess) {
    return <ChangePasswordSent />;
  }

  return (
    <Page px={6} pb={4} pt={16}>
      <AppHeader title="Récupération du mot de passe" />
      <Formiz connect={form} onValidSubmit={handleValidSubmit}>
        <Box as="form" noValidate onSubmit={form.submit}>
          <Flex direction="column" align="center" m="auto" maxW="400px">
            <Heading as="h1" size="2xl" mb={10}>
              Mot de passe oublié
            </Heading>
            <Box w="100%">
              <FieldInput
                name="password"
                type="password"
                required="Votre nouveau mot de passe est requis"
                label="Nouveau mot de passe"
                validations={[
                  {
                    rule: isMinLength(8),
                    message: 'Le mot de passe doit avoir au moins 8 caractères',
                  },
                ]}
              />
              <FieldInput
                name="confirmPassword"
                type="password"
                required="La confirmation du mot de passe est requise"
                label="Confirmation du mot de passe"
                validations={[
                  {
                    rule: (value) => form.values.password === value,
                    message: 'Le mot de passe ne correspond pas',
                    deps: [form.values.password],
                  },
                ]}
              />
              <Box spacing={4} d={{ base: 'none', md: 'flex' }} mt={6}>
                <Button
                  type="submit"
                  colorScheme="brandSecondary"
                  isDisabled={isLoading}
                >
                  Envoyer
                </Button>
              </Box>
            </Box>
          </Flex>

          <Footer as={Box} spacing={4} d={{ base: 'flex', md: 'none' }}>
            <Button
              type="submit"
              colorScheme="brandSecondary"
              isDisabled={isLoading}
            >
              Envoyer
            </Button>
          </Footer>
        </Box>
      </Formiz>
    </Page>
  );
};
