import React, { useEffect, useContext, useRef } from 'react';

import { Checkbox } from '@chakra-ui/react';

import { CardContext } from '.';

export const CardCheckbox = (props) => {
  const { setHasFormElement, setIsActive } = useContext(CardContext);
  const checkboxRef = useRef(null);
  const checkboxChecked = checkboxRef?.current?.checked;

  useEffect(() => {
    setHasFormElement(true);
    return () => setHasFormElement(false);
  }, [setHasFormElement]);

  useEffect(() => {
    const checkbox = checkboxRef?.current;
    if (!checkbox) return;

    setIsActive(checkbox.checked);
  }, [checkboxChecked, setIsActive]);

  return (
    <Checkbox
      as="span"
      ref={checkboxRef}
      position="absolute"
      top="3"
      right="5"
      {...props}
    />
  );
};
