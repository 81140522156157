import React from 'react';

import { Box, Flex, Tooltip } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FaInfoCircle } from 'react-icons/fa';

import { CardTitle, Icon } from '@/components';

export const TooltipDateShower = ({ dates, ...otherProps }) => {
  const leftDatesLength = dates?.length - 1;
  const tooltipDates = [...dates];
  tooltipDates.shift();
  if (leftDatesLength < 1) {
    return null;
  }
  return (
    <Tooltip
      hasArrow
      bg="brandSecondary.300"
      label={
        <Box>
          {tooltipDates?.map((ele, ind) => (
            <Box key={ind} ml="3" mb={2}>
              <CardTitle as="h3" fontSize="1.2rem">
                {ele?.dateToDisplay}
              </CardTitle>
              <CardTitle as="h3" fontSize="1rem">
                {`${ele?.startTimeToDisplay} à ${ele?.endTimeToDisplay}`}
              </CardTitle>
            </Box>
          ))}
        </Box>
      }
      placement="bottom-start"
    >
      <Flex {...otherProps}>
        <CardTitle
          as="h3"
          size="sm"
          mr={2}
          ml={{ base: 0, md: 2 }}
          mb={{ base: 0, md: -1 }}
        >
          {` Et ${leftDatesLength} autre${leftDatesLength > 1 ? 's' : ''} date${
            leftDatesLength > 1 ? 's' : ''
          }`}
        </CardTitle>
        <Icon
          icon={FaInfoCircle}
          color="brandSecondary.300"
          mt={{ base: 0, md: 2 }}
        />
      </Flex>
    </Tooltip>
  );
};

TooltipDateShower.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.object),
};

TooltipDateShower.defaultProps = {
  dates: [],
};
