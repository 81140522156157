import React, { useState, createContext } from 'react';

import { Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const CardContext = createContext(null);

export const Card = ({ autoHeight, ...props }) => {
  const [hasFormElement, setHasFormElement] = useState(null);
  const [hasRightElement, setHasRightElement] = useState(false);
  const [isActive, setIsActive] = useState(false);

  return (
    <CardContext.Provider
      value={{
        hasFormElement,
        setHasFormElement,
        hasRightElement,
        setHasRightElement,
        setIsActive,
      }}
    >
      <Flex
        {...(hasFormElement
          ? {
              as: 'label',
              cursor: 'pointer',
              _focusWithin: {
                boxShadow: 'outline',
              },
            }
          : {})}
        position="relative"
        flexDirection="row"
        bg={isActive ? 'brandSecondary.50' : 'white'}
        boxShadow={isActive ? 'outline' : 'md'}
        borderRadius="lg"
        overflow="hidden"
        border="2px"
        borderColor={isActive ? 'brandSecondary.600' : 'gray.300'}
        alignItems="stretch"
        p={5}
        py={3}
        minH={autoHeight ? 'auto' : 32}
        {...props}
      />
    </CardContext.Provider>
  );
};

Card.propTypes = {
  autoHeight: PropTypes.bool,
};

Card.defaultProps = {
  autoHeight: false,
};
