import React from 'react';

import { useMediaQuery } from '@chakra-ui/media-query';
import { Spacer, Flex } from '@chakra-ui/react';
import { FormizStep, useForm } from '@formiz/core';
import { isNotEmptyString } from '@formiz/validations';

import { FieldInput, FieldSelect } from '@/components';
import {
  BOOKING_REQUEST_CATEGORY,
  BOOKING_REQUEST_SUBJECTS,
  MEETING_REQUEST_CATEGORY,
} from '@/constants/bookingRequest';
import {
  MEETING_APPOINTMENT,
  MEETING_PARTICIPANTS_CAPACITY,
} from '@/constants/meeting';
import { FORM_STEPS } from '@/constants/steps';
import { AppointmentHeading } from '@/pages/BookingRequest/_partials/AppointmentHeading';
import { HelpIconButton } from '@/pages/BookingRequest/_partials/HelpIconButton';

import { MeetingStepRadioGroup } from './_partial/MeetingRadioGroup';

export const Meeting = () => {
  const [isLessThan360] = useMediaQuery('(max-width: 360px)');
  const { values } = useForm();
  const { appointment } = values || { meeting: null };

  const { MEETING, FACE_TO_FACE_MEETING } = MEETING_APPOINTMENT;

  const MEETING_APPOINTMENT_TYPE = {
    [BOOKING_REQUEST_CATEGORY.PERSONAL.id]: {
      [MEETING]: {
        helper: 'ex : Vie associative, Rentrée scolaire, Copropriété, …',
      },
      [FACE_TO_FACE_MEETING]: {
        helper: 'ex : Banque, Commerce, Démarches administratives, …',
      },
    },
    [BOOKING_REQUEST_CATEGORY.PROFESSIONAL.id]: {
      [MEETING]: {
        helper: 'ex : Réunion de service, D’équipe, Présentation client, …',
      },
    },
  };

  const meetingCapacityOptions = Object.values(
    MEETING_PARTICIPANTS_CAPACITY
  ).map((value) => ({
    value,
    label: value,
  }));

  const shouldDisplayInputsType =
    BOOKING_REQUEST_CATEGORY.PERSONAL.id === appointment?.category ||
    (BOOKING_REQUEST_CATEGORY.PROFESSIONAL.id === appointment?.category &&
      MEETING_REQUEST_CATEGORY.MEETING.id === appointment?.meeting?.category);

  const shouldDisplayUseNameInformation =
    BOOKING_REQUEST_CATEGORY.PROFESSIONAL.id === appointment?.category &&
    MEETING_REQUEST_CATEGORY.MEETING_FACE_TO_FACE.id ===
      appointment?.meeting?.category;

  const shouldDisplayInputParticipantsCapacity =
    MEETING_REQUEST_CATEGORY.MEETING.id === appointment?.meeting?.category;

  return (
    <>
      <FormizStep
        name={FORM_STEPS.MEETING_SUBJECT.MEETING.name}
        order={FORM_STEPS.MEETING_SUBJECT.MEETING.order}
      >
        <MeetingStepRadioGroup />
      </FormizStep>

      <FormizStep
        name={FORM_STEPS.MEETING_SUBJECT.FACE_TO_FACE_MEETING.name}
        order={FORM_STEPS.MEETING_SUBJECT.FACE_TO_FACE_MEETING.order}
      >
        <Flex>
          <AppointmentHeading
            title={
              appointment?.meeting?.category ===
              MEETING_REQUEST_CATEGORY.MEETING_FACE_TO_FACE.id
                ? 'Rendez-vous face à face'
                : 'Réunion'
            }
            helper="De quoi s’agit-il ?"
          />
          <Spacer />
          <HelpIconButton />
        </Flex>
        {shouldDisplayInputsType && (
          <>
            <FieldInput
              name={`appointment.${BOOKING_REQUEST_SUBJECTS.MEETING.id}.type`}
              label={
                appointment?.meeting?.category ===
                  BOOKING_REQUEST_CATEGORY.PERSONAL.id ||
                appointment?.meeting?.category ===
                  MEETING_REQUEST_CATEGORY.MEETING.id
                  ? 'Motif'
                  : 'Type de rendez-vous'
              }
              helper={
                MEETING_APPOINTMENT_TYPE[appointment?.category][
                  appointment?.meeting?.category
                ]?.helper
              }
              required="Vous devez fournir le type de réunion"
              h={isLessThan360 ? '7.5rem' : null}
              validations={[
                {
                  rule: isNotEmptyString(),
                  message: 'Vous devez fournir le type de réunion',
                },
              ]}
              mt={4}
            />
            {shouldDisplayInputParticipantsCapacity && (
              <FieldSelect
                name={`appointment.${BOOKING_REQUEST_SUBJECTS.MEETING.id}.participants`}
                label="Nombre de participants"
                helper="Combien y’a-t-il de personnes ?"
                required="Vous devez choisir le nombre de participants"
                options={meetingCapacityOptions}
                mt={4}
              />
            )}
          </>
        )}
        {shouldDisplayUseNameInformation && (
          <>
            <FieldInput
              name={`appointment.${BOOKING_REQUEST_SUBJECTS.MEETING.id}.interlocutorFullName`}
              label="Nom, prénom et rôle de la personne que vous rencontrez"
              helper="ex : Delphine Michèle, responsable RH"
              required="Vous devez fournir un nom et un prénom"
              validations={[
                {
                  rule: isNotEmptyString(),
                  message: 'Vous devez fournir un nom et un prénom',
                },
              ]}
              mt={4}
            />
          </>
        )}
      </FormizStep>
    </>
  );
};
