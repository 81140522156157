import React, { useContext } from 'react';

import { Heading } from '@chakra-ui/react';

import { CardContext } from '.';

export const CardTitle = (props) => {
  const { hasFormElement, hasRightElement } = useContext(CardContext);

  return (
    <Heading
      as="h2"
      display="flex"
      alignItems="center"
      size="md"
      overflowWrap="break-word"
      {...(!!hasFormElement && !hasRightElement ? { mr: 6 } : {})}
      {...props}
    />
  );
};
