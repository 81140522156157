import React from 'react';

import { Box, Button, Flex, Heading, Stack, useToast } from '@chakra-ui/react';
import { Formiz, useForm } from '@formiz/core';
import { isMinLength, isPattern } from '@formiz/validations';

import { FieldInput, Footer } from '@/components';
import { Page, AppHeader } from '@/layout';
import { useChangeConnectedUserPassword } from '@/services/login';
import {
  HAS_DIGIT,
  HAS_LOWCASE_CHARACTER,
  HAS_UPPERCASE_CHARACTER,
} from '@/utils/formCustomValidations';

export const Password = () => {
  const form = useForm();
  const toast = useToast();

  const [updatePassword, { isLoading }] = useChangeConnectedUserPassword({
    onError: (error) => {
      const { status } = error?.response;
      if (status === 401) {
        toast({
          title: 'Identifiant incorrect, veuillez ré-essayer',
          status: 'error',
        });
      } else {
        toast({
          title:
            'Une erreur est survenue lors de la modification du mot de passe',
          status: 'error',
        });
      }
    },
    onSuccess: () => {
      toast({
        title: 'Le mot de passe a été modifié avec succès',
        status: 'success',
      });
    },
  });

  const handleValidSubmit = (values) => {
    const passwordData = {
      oldPassword: values?.oldPassword,
      newPassword: values?.password,
    };

    updatePassword(passwordData);
  };

  return (
    <Page px={6} pb={4} pt={16}>
      <AppHeader title="Profile" />
      <Formiz connect={form} onValidSubmit={handleValidSubmit}>
        <Box as="form" noValidate onSubmit={form.submit}>
          <Flex direction="column" align="center" m="auto" maxW="400px">
            <Heading as="h2" size="lg" mb={10}>
              Changer le mot de passe
            </Heading>
            <Box w="100%">
              <FieldInput
                name="oldPassword"
                type="password"
                required="Votre ancien mot de passe est requis"
                label="L'ancien mot de passe"
                validations={[
                  {
                    rule: isMinLength(8),
                    message: 'Le mot de passe doit avoir au moins 8 caractères',
                  },
                ]}
              />
              <FieldInput
                name="password"
                type="password"
                required="Le mot de passe est requis"
                label="Nouveau mot de passe"
                h={{ base: '6.9rem', sm: '5.5rem' }}
                validations={[
                  {
                    rule: isMinLength(8),
                    message: 'Le mot de passe doit avoir au moins 8 caractères',
                  },
                  {
                    rule: (value) =>
                      isPattern(HAS_DIGIT)(value) ||
                      (isPattern(HAS_LOWCASE_CHARACTER)(value) &&
                        isPattern(HAS_UPPERCASE_CHARACTER)(value)),
                    message:
                      'Le mot de passe doit contenir soit un mélange de majuscules' +
                      ' et de minuscule, soit au moins un chiffre',
                    deps: [form.values.password],
                  },
                ]}
              />
              <FieldInput
                name="confirmPassword"
                type="password"
                required="La confirmation du mot de passe est requise"
                label="Confirmation du mot de passe"
                validations={[
                  {
                    rule: (value) => form.values.password === value,
                    message: 'Le mot de passe ne correspond pas',
                    deps: [form.values.password],
                  },
                ]}
              />

              <Stack spacing={4} d={{ base: 'none', md: 'flex' }} mt={6}>
                <Button
                  type="submit"
                  colorScheme="brandSecondary"
                  isDisabled={isLoading}
                >
                  Envoyer
                </Button>
              </Stack>
            </Box>
          </Flex>

          <Footer as={Stack} spacing={4} d={{ base: 'flex', md: 'none' }}>
            <Button
              type="submit"
              colorScheme="brandSecondary"
              isDisabled={isLoading}
            >
              Envoyer
            </Button>
          </Footer>
        </Box>
      </Formiz>
    </Page>
  );
};
