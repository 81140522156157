import React from 'react';

import { useTheme } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import CreatableReactSelect from 'react-select/creatable';

export const Select = ({
  colorScheme,
  isCreatable,
  isError,
  noOptionsMessage,
  loadingMessage,
  formatCreateLabel,
  placeholder,
  ...otherProps
}) => {
  const chakraUITheme = useTheme();
  const Element = isCreatable ? CreatableReactSelect : ReactSelect;
  const colorToUse = chakraUITheme.colors[colorScheme]
    ? colorScheme
    : 'brandSecondary';

  const selectStyle = {
    control: (provided, { isFocused }) => {
      if (isFocused) {
        const borderColor = chakraUITheme.colors[colorToUse]['600'];

        return {
          ...provided,
          ...(borderColor
            ? {
                borderColor,
                boxShadow: `0 0 0 1px ${borderColor}`,
                '&:hover': { borderColor },
              }
            : {}),
        };
      }

      return provided;
    },
    option: (provided, { isFocused, isSelected }) => {
      let backgroundColor = null;
      let color = null;
      if (isFocused) {
        backgroundColor = chakraUITheme.colors[colorToUse]['100'];
        color = 'black';
      } else if (isSelected) {
        backgroundColor = chakraUITheme.colors[colorToUse]['500'];
        color = 'white';
      }

      return {
        ...provided,
        ...(backgroundColor && color
          ? {
              backgroundColor,
              color,
              ':active': {
                backgroundColor: chakraUITheme.colors[colorToUse]['500'],
                color: 'white',
              },
            }
          : {}),
      };
    },
    menu: (provided) => ({ ...provided, zIndex: 10 }),
  };

  const selectErrorStyle = {
    control: (provided, state) => {
      if (isError) {
        const borderColor = chakraUITheme.colors.danger[500];

        return {
          ...provided,
          borderColor,
          boxShadow: `0 0 0 1px ${borderColor}`,
          '&:hover': { borderColor },
        };
      }

      return { ...provided, ...selectStyle.control(provided, state) };
    },
  };

  return (
    <Element
      styles={{ ...selectStyle, ...selectErrorStyle }}
      {...(noOptionsMessage
        ? { noOptionsMessage: () => noOptionsMessage }
        : {})}
      {...(loadingMessage ? { loadingMessage: () => loadingMessage } : {})}
      {...(formatCreateLabel ? { formatCreateLabel } : {})}
      placeholder={placeholder}
      {...otherProps}
    />
  );
};

Select.propTypes = {
  colorScheme: PropTypes.string,
  isCreatable: PropTypes.bool,
  isError: PropTypes.bool,
  noOptionsMessage: PropTypes.string,
  loadingMessage: PropTypes.string,
  formatCreateLabel: PropTypes.func,
  placeholder: PropTypes.string,
};

Select.defaultProps = {
  colorScheme: 'brandSecondary',
  isCreatable: false,
  isError: false,
  noOptionsMessage: null,
  loadingMessage: null,
  formatCreateLabel: null,
  placeholder: '',
};
