import { FaCalendarWeek } from 'react-icons/fa';

import { BOOKING_REQUEST_SUBJECTS } from './bookingRequest';

export const MEETING_APPOINTMENT = {
  MEETING: 'meeting',
  FACE_TO_FACE_MEETING: 'face_to_face_meeting',
};

export const MEETING_APPOINTMENT_LABEL = {
  [MEETING_APPOINTMENT.MEETING]: 'Réunion',
  [MEETING_APPOINTMENT.FACE_TO_FACE_MEETING]: 'Rendez-vous face à face',
};

export const MEETING_PARTICIPANTS_CAPACITY = {
  S: '3-5',
  M: '6-15',
  L: '16-50',
  XL: '50+',
};

export const APPOINTMENT_CARD_ICONS = {
  [BOOKING_REQUEST_SUBJECTS.HEALTH.id]: {
    icon: BOOKING_REQUEST_SUBJECTS.HEALTH.icon,
    fontSize: '2rem',
  },
  // FIXME : When we will have all the icons, we can replace these following:
  [BOOKING_REQUEST_SUBJECTS.MEETING.id]: {
    icon: BOOKING_REQUEST_SUBJECTS.MEETING.icon,
    fontSize: '2rem',
  },
  [BOOKING_REQUEST_SUBJECTS.EVENT.id]: {
    icon: BOOKING_REQUEST_SUBJECTS.EVENT.icon,
    fontSize: '2rem',
  },
  [BOOKING_REQUEST_SUBJECTS.JUSTICE.id]: {
    icon: BOOKING_REQUEST_SUBJECTS.JUSTICE.icon,
    fontSize: '2rem',
  },
  DEFAULT: {
    icon: FaCalendarWeek,
    fontSize: '1.7rem',
  },
};
